import React from 'react';
import { Typography, Row } from 'antd';
import { Link } from 'react-router-dom';

// COMPONENTS
import { ActivityIndicator } from '../../components/ActivityIndicator';
import { Alert } from '../../components/Alert';
import { Tile } from '../../components/Tile';
import { TileRow } from '../../components/TileRow';

// THEME
import { GUTTER } from '../../theme/constants';

// HOOKS
import { useAuthRequest } from '../../hooks/useAuthRequest';

// TYPES
import { ApiGetTaskAttachments } from '../../types/api';
import colors from '../../theme/colors';

interface Props {
    task_id: number;
}

const { Text } = Typography;

export const TaskAttachments: React.FC<Props> = ({ task_id }) => {
    const { data, error }: { data: ApiGetTaskAttachments[]; error: any } = useAuthRequest(`/task_attachment?task_id=${task_id}`, 0);

    if (error) {
        return <Alert type="error" message="Es konnten keine Dateianhänge geladen werden." />;
    }

    if (!data) {
        return <ActivityIndicator />;
    }

    return (
        <>
            <TileRow>
                <Tile>
                    {data.map((val: ApiGetTaskAttachments) => {
                        return (
                            <Row key={val.task_attachment_id} gutter={GUTTER}>
                                <Link to={{ pathname: `https://vara.s3.eu-central-1.amazonaws.com/${val.task_attachment_filepath}` }} target="_blank">
                                    <Text underline style={{ color: colors.primaryBlue }}>
                                        {val.task_attachment_filepath}
                                    </Text>
                                </Link>
                            </Row>
                        );
                    })}
                </Tile>
            </TileRow>
        </>
    );
};
