import React from 'react';

interface Props {
    size?: number;
    strokeWidth?: number;
    color?: string;
}

export const CircleArrowRight: React.FC<Props> = ({ size = 24, strokeWidth = 1.5, color }) => (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
        <path
            d="M11.262 9.524L13.739 12l-2.477 2.476"
            fillRule="evenodd"
            clipRule="evenodd"
            stroke={color}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M12 21.004A9.004 9.004 0 0 1 2.996 12a9.143 9.143 0 0 1 9.013-9.003A9.004 9.004 0 1 1 12 21.005"
            fillRule="evenodd"
            clipRule="evenodd"
            stroke={color}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
export const CircleArrowDown: React.FC<Props> = ({ size = 24, strokeWidth = 1.5, color }) => (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
        <path
            d="M14.476 11.262L12 13.739l-2.476-2.477"
            fillRule="evenodd"
            clipRule="evenodd"
            stroke={color}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M2.996 12A9.003 9.003 0 0 1 12 2.997a9.144 9.144 0 0 1 9.004 9.012A9.005 9.005 0 0 1 2.996 12"
            fillRule="evenodd"
            clipRule="evenodd"
            stroke={color}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
export const CircleArrowLeft: React.FC<Props> = ({ size = 24, strokeWidth = 1.5, color }) => (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
        <path
            d="M12.738 14.476L10.261 12l2.477-2.476"
            fillRule="evenodd"
            clipRule="evenodd"
            stroke={color}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M12 2.996A9.004 9.004 0 0 1 21.004 12a9.144 9.144 0 0 1-9.013 9.003A9.004 9.004 0 1 1 12 2.995"
            fillRule="evenodd"
            clipRule="evenodd"
            stroke={color}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
export const CircleArrowUp: React.FC<Props> = ({ size = 24, strokeWidth = 1.5, color }) => (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
        <path
            d="M9.524 12.738L12 10.261l2.476 2.477"
            fillRule="evenodd"
            clipRule="evenodd"
            stroke={color}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M21.004 12A9.003 9.003 0 0 1 12 21.003a9.144 9.144 0 0 1-9.004-9.012A9.005 9.005 0 0 1 21.004 12"
            fillRule="evenodd"
            clipRule="evenodd"
            stroke={color}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
