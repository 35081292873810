type quarterType = 1 | 2 | 3 | 4;

export const periodSplitter = (periodString: string): quarterType => {
    let periodQuarter: quarterType;
    periodQuarter = 1;
    switch (periodString) {
        case 'q1':
            periodQuarter = 1;
            break;
        case 'q2':
            periodQuarter = 2;
            break;
        case 'q3':
            periodQuarter = 3;
            break;
        case 'q4':
            periodQuarter = 4;
            break;
        case 'fy':
            periodQuarter = 4;
            break;
        default:
            periodQuarter = 1;
            break;
    }

    return periodQuarter;
};
