import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { jsonToCSV, CSVDownloader } from 'react-papaparse';
// @ts-ignore
import { CsvToHtmlTable } from 'react-csv-to-table';

// COMPONENTS
import { ActivityIndicator } from '../components/ActivityIndicator';
import { Alert, Button, Divider, Typography } from 'antd';
import { TileRow } from '../components/TileRow';
import { Tile } from '../components/Tile';
import colors from '../theme/colors';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const Download: React.FC<{ number: string; data: any; filename: string }> = ({ number, data, filename }) => {
    // We neeed to unwrap data twice as every row is indexed
    const preparedData = Object.values(data).map((v: any) => Object.values(v));

    const csv2table = jsonToCSV(preparedData, { quotes: true, delimiter: '    ' });
    // .replace(/(,)(?=(?:[^"]|"[^"]*")*$)/g, ' ')
    // .replace(/"/g, '');

    return (
        <>
            <TileRow>
                <Tile span={24}>
                    <CSVDownloader data={preparedData} filename={filename} bom={true}>
                        <Button type="primary" style={{ marginBottom: 6 }}>
                            Tabelle im CSV Format herunterladen
                        </Button>
                    </CSVDownloader>
                    <CsvToHtmlTable data={csv2table} csvDelimiter="    " />
                </Tile>
                <Divider style={{ borderTopColor: colors.primaryBlue }} />
            </TileRow>
        </>
    );
};

export const TextractTables: React.FC = () => {
    let query = useQuery();

    const table_key = query.get('table_key') || '';
    const table_number = query.get('table_number') || 1;

    console.log(table_key);

    const [tableResponse, setJsonRespone] = useState<[]>([]);
    const [error, setError] = useState('');

    let filename = table_key.replace('.json', '');
    filename = filename.replace('resultstables/', '');

    React.useEffect(() => {
        if (!table_key) {
            setError('Bitte gib eine gültigen S3 Key für eine Tabelle an');
        } else {
            fetch(`${process.env.REACT_APP_S3_BUCKET_URL}/${table_key}`, {
                method: 'GET',
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                },
            })
                .then((resp) => resp.json())
                .then((data) => {
                    setJsonRespone(data);
                });
        }
    }, [table_key]);

    if (error) return <Alert message={error} />;
    if (!table_key) return <Alert message="Bitte gib eine gültigen S3 Key für eine Tabelle an" />;
    if (!table_number) return <Alert message="Bitte gib an, welche Tabelle ausgegeben werden soll" />;

    if (!tableResponse) {
        return <ActivityIndicator />;
    }

    if (tableResponse.length) {
        return (
            <>
                <TileRow>
                    <Tile span={24}>
                        <Typography.Title>Tabellen herunterladen</Typography.Title>
                    </Tile>
                </TileRow>
                {tableResponse.map((table: any, index: number) => (
                    <Download key={`${filename}_${index}`} number={`${index + 1}`} data={table} filename={`${filename}__${index + 1}`} />
                ))}
            </>
        );
    }

    return <ActivityIndicator />;
};
