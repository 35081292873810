import React, { useState } from 'react';
import { Upload, message, Alert, Select, Row, Col } from 'antd';
import { user } from '../../utils/user';
import { InboxOutlined } from '@ant-design/icons';
import colors from '../../theme/colors';

// COMPONENTS
import { ActivityIndicator } from '../../components/ActivityIndicator';

// HOOKS
import { useAuthRequest } from '../../hooks/useAuthRequest';

// TYPES
import { CustomerType, BrokerType } from '../../types/api';
import { GUTTER } from '../../theme/constants';

// DECONSTRUCT
const { Dragger } = Upload;
const { Option } = Select;

export const UploadForm: React.FC = () => {
    const { data: customerData, error: customerError }: { data: CustomerType[]; error: any } = useAuthRequest('/customer');
    const { data: brokerData, error: brokerError }: { data: BrokerType[]; error: any } = useAuthRequest('/broker');

    const [customerId, setCustomerId] = useState<null | string>(null);
    const [brokerId, setBrokerId] = useState<null | string>(null);
    const [periodYear, setPeriodYear] = useState<number>(2021);
    const [periodQuarter, setPeriodQuarter] = useState<1 | 2 | 3 | 4>(1);
    const [periodIsPre, setPeriodIsPre] = useState<'pre' | 'post'>('pre');

    if (customerError || brokerError) {
        return <Alert type="error" message="Es konnte keine Liste von Kunden/Brokern geladen werden." />;
    }

    if (!customerData || !brokerData) {
        return <ActivityIndicator />;
    }

    const token = user().token || '';

    if (token === '') return null;

    const params = {
        customer_id: customerId,
        broker_id: brokerId,
        document_period_year: periodYear,
        document_period_quarter: periodQuarter,
        document_period_type: periodIsPre,
    };

    const periodYearsArray = Array.from({ length: 21 }, (_, i) => i + 2019);

    interface OptionType {
        key: string;
    }

    return (
        <>
            <Row style={{ marginBottom: GUTTER }}>
                <Col span={8} style={{ paddingRight: GUTTER }}>
                    <Select
                        showSearch
                        style={{ width: '100%' }}
                        placeholder="Kunde"
                        onChange={(value, option) => {
                            //@ts-ignore
                            setCustomerId(option.key);
                        }}
                    >
                        {customerData.map((customer) => (
                            <Option value={customer.customer_name} key={customer.customer_id}>
                                {customer.customer_name}
                            </Option>
                        ))}
                    </Select>
                </Col>
                <Col span={8} style={{ paddingRight: GUTTER }}>
                    <Select
                        showSearch
                        style={{ width: '100%' }}
                        placeholder="Broker"
                        onChange={(value, option) => {
                            //@ts-ignore
                            setBrokerId(option.key);
                        }}
                    >
                        {brokerData.map((broker) => (
                            <Option value={broker.broker_name} key={broker.broker_id}>
                                {broker.broker_name}
                            </Option>
                        ))}
                    </Select>
                </Col>
                <Col span={8} style={{ paddingLeft: GUTTER }}>
                    <Row>
                        <Col span={8} style={{ paddingRight: GUTTER }}>
                            <Select
                                showSearch
                                style={{ width: '100%' }}
                                defaultValue="pre"
                                onChange={(value: string) => setPeriodIsPre(value === 'pre' ? 'pre' : 'post')}
                            >
                                <Option value="pre" key="pre">
                                    PRE
                                </Option>
                                <Option value="post" key="post">
                                    POST
                                </Option>
                            </Select>
                        </Col>

                        <Col span={8} style={{ paddingRight: GUTTER }}>
                            <Select
                                showSearch
                                style={{ width: '100%' }}
                                defaultValue={1}
                                onChange={(value: 1 | 2 | 3 | 4) => setPeriodQuarter(value)}
                            >
                                <Option value={1} key="Q1">
                                    Q1
                                </Option>
                                <Option value={2} key="Q2">
                                    Q2
                                </Option>
                                <Option value={3} key="Q3">
                                    Q3
                                </Option>
                                <Option value={4} key="FY">
                                    FY
                                </Option>
                            </Select>
                        </Col>

                        <Col span={8}>
                            <Select showSearch style={{ width: '100%' }} defaultValue={2021} onChange={(value: number) => setPeriodYear(value)}>
                                {periodYearsArray.map((year) => (
                                    <Option value={year} key={`${year}`}>
                                        {year}
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Dragger
                name="pdf"
                multiple={true}
                action={`${process.env.REACT_APP_API_ENDPOINT}/upload`}
                accept={'application/pdf'}
                headers={{ Authorization: token }}
                data={params}
                onChange={(info: any) => {
                    const { status } = info.file;
                    if (status !== 'uploading') {
                        console.log(info.file, info.fileList);
                    }
                    if (status === 'done') {
                        message.success(`${info.file.name} - Die Datei wurde erfolgreich hochgeladen.`);
                    } else if (status === 'error') {
                        message.error(`${info.file.name} - Fehler beim Upload der Datei.`);
                    }
                }}
                disabled={customerId === null || brokerId === null ? true : false}
            >
                <p className="ant-upload-drag-icon">
                    <InboxOutlined style={{ color: colors.primaryBlue }} />
                </p>
                <p className="ant-upload-text">PDF zum Upload in dieses Feld ziehen.</p>
                <p className="ant-upload-hint">Es können mehrere oder auch nur einzelne PDFs hochgeladen werden.</p>
            </Dragger>
        </>
    );
};
