import React from 'react';
// import logo from "./assets/vara-logo.png";
import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { SWRConfig } from 'swr';

// UTILS
import { swrFetcher } from './utils/swrFetcher';

// Components
import { ProtectedRoute } from './components/ProtectedRoute';

// Screens
import { Overview } from './screens/Overview';
import { Document } from './screens/Document';
import { Documents } from './screens/Documents';
import { Signin } from './screens/Signin';
import { UserManagement } from './screens/UserManagement';
import { Todos } from './screens/Todos';
import { Todo } from './screens/Todo';
import { Calendar } from './screens/Calendar';
import { Textract } from './screens/Textract';
import { TextractTables } from './screens/TextractTables';
import { CustomerAndBroker } from './screens/CustomerAndBroker';
import { Tasks } from './screens/Tasks';
import { Task } from './screens/Task';

// AUTH Provider
import { UserProvider } from './context/UserProvider';

const App: React.FC = () => {
    return (
        <UserProvider>
            <SWRConfig value={{ revalidateOnFocus: true, fetcher: swrFetcher }}>
                <Router>
                    <Switch>
                        <ProtectedRoute path="/" exact comp={Overview} />
                        <ProtectedRoute path="/documents/" comp={Documents} />
                        <ProtectedRoute path="/document/:document_id" comp={Document} />
                        <ProtectedRoute path="/todos" exact comp={Todos} />
                        <ProtectedRoute path="/usermanagement" exact comp={UserManagement} />
                        <ProtectedRoute fullscreen path="/textract/" exact comp={Textract} />
                        <ProtectedRoute path="/textract-table" exact comp={TextractTables} />
                        <ProtectedRoute path="/calendar" exact comp={Calendar} />
                        <ProtectedRoute path="/todo/:todo_id" exact comp={Todo} />
                        <ProtectedRoute path="/customer-and-broker" exact comp={CustomerAndBroker} />
                        <ProtectedRoute path="/tasks" exact comp={Tasks} />
                        <ProtectedRoute path="/task/:task_id" exact comp={Task} />

                        <Route path="/signin">
                            <Signin />
                        </Route>
                    </Switch>
                </Router>
            </SWRConfig>
        </UserProvider>
    );
};

export default App;
