import React from 'react';

interface Props {
    size?: number;
    strokeWidth?: number;
    color?: string;
}

export const Edit: React.FC<Props> = ({ size = 24, strokeWidth = 1.5, color }) => (
    // pen-edit2
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
        <path
            clipRule="evenodd"
            d="M8.522 20.003H3.997V15.48c0-.464.184-.909.512-1.237L15.24 3.51a1.746 1.746 0 0 1 2.474 0l2.776 2.775a1.75 1.75 0 0 1-.001 2.474L9.759 19.49a1.749 1.749 0 0 1-1.236.512z"
            stroke={color}
            strokeWidth={strokeWidth}
        />
        <path d="M13 5.998L18.003 11m-9.004 4.001L12 12" stroke={color} strokeWidth={strokeWidth} />
    </svg>
);

export const Zoom: React.FC<Props> = ({ size = 24, strokeWidth = 1.5, color }) => (
    // pen-edit2
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
        <path
            d="M14.193 5.582a5.97 5.97 0 1 1-8.444 8.444 5.97 5.97 0 1 1 8.444-8.444m-.043 8.478L20 19.99M8 10h4m-2 2V8"
            stroke={color}
            strokeWidth={strokeWidth}
        />
    </svg>
);

export const SimpleLoupe: React.FC<Props> = ({ size = 24, strokeWidth = 1.5, color }) => (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
        <path
            d="M14.193 5.582a5.97 5.97 0 1 1-8.444 8.444 5.97 5.97 0 1 1 8.444-8.444m-.043 8.478L20 19.99"
            stroke={color}
            strokeWidth={strokeWidth}
        />
    </svg>
);

export const Mailbox: React.FC<Props> = ({ size = 24, strokeWidth = 1.5, color }) => (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
        <path d="M17 12H7m10-4H7m14 4v8a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1v-8m14 4H7m10-4H7m10-4H7m10-4H7" stroke={color} strokeWidth={strokeWidth} />
    </svg>
);

export const CustomerBroker: React.FC<Props> = ({ size = 24, strokeWidth = 1.5, color }) => (
    // <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
    //     <path
    //         stroke={color}
    //         strokeWidth={strokeWidth}
    //         d="M8 4h12M3 4h1M3 8h1m-1 4h1m4-4h12M8 12h2m7 9a5 5 0 0 1-5-5c0-2.704 2.3-5.003 5.004-5A5 5 0 0 1 17 21m0-7v4m2-2h-4"
    //     />
    // </svg>
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
        <path stroke={color} strokeWidth={strokeWidth} d="M16 3H5a2 2 0 0 0-2 2v10" />
        <path
            stroke={color}
            strokeWidth={strokeWidth}
            clipRule="evenodd"
            d="M19.444 21H8.556C7.696 21 7 20.304 7 19.444V8.556C7 7.696 7.696 7 8.556 7h10.889C20.304 7 21 7.696 21 8.556v10.889c0 .859-.696 1.555-1.556 1.555z"
        />
        <path
            stroke={color}
            strokeWidth={strokeWidth}
            d="M17.89 10.89h-4.67m0 3.11h4.67m-4.67 3.11h4.67m-7.78-.193a.194.194 0 1 0 0 .388.194.194 0 0 0 0-.388m0-3.111a.194.194 0 1 0 0 .388.194.194 0 0 0 0-.388m0-3.112a.194.194 0 1 0 0 .388.194.194 0 0 0 0-.388"
        />
    </svg>
);
