import { mutate } from 'swr';
import { user } from './user';
import { g } from './globals';
import { objectToUrlParams } from './objectToUrlParams';

interface ParamsObject {
    [key: string]: number | string;
}

export const invalidateSWR = (path: string, params?: ParamsObject) => {
    const token = user().token || '';
    if (!path) {
        throw new Error('Path is required');
    }

    let encodedParams = '';
    if (params) {
        encodedParams = objectToUrlParams(params);
    }

    const url = params ? `${g.API}${path}?${encodedParams}` : `${g.API}${path}`;
    console.debug({
        api: path,
        params,
        request: {
            type: 'invalidateSWR',
            url,
        },
    });
    return mutate([url, token]);
};

export const invalidateWithParamsSWR = (pathWithParams: string) => {
    const token = user().token || '';

    const url = `${g.API}${pathWithParams}`;
    console.debug({
        invalidate: pathWithParams,
        request: {
            type: 'invalidateWithParamsSWR',
            url,
        },
    });
    return mutate([url, token]);
};
