import React from 'react';
import { useParams } from 'react-router-dom';
import { Input, Button, Switch, DatePicker, Select, Typography, Divider, Col } from 'antd';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/de_DE';
import { formatDistance } from 'date-fns';
import { de } from 'date-fns/locale';

// COMPONENTS
import { ActivityIndicator } from '../components/ActivityIndicator';
import { Alert } from '../components/Alert';

// SERVICES
import { apiPost, apiPut } from '../services/api';

// HOOKS
import { useAuthRequest } from '../hooks/useAuthRequest';

// TYPES
import { GUTTER } from '../theme/constants';
import { TodoType, UserType } from '../types/api';
import { invalidateSWR } from '../utils/invalidateSWR';
import { TileRow } from '../components/TileRow';
import { Tile } from '../components/Tile';

const { Search } = Input;

function disabledDate(current: any) {
    // Can not select days before today and today
    return current && current < moment().subtract(1, 'days').endOf('day');
}

const currentHour = moment().format('HH');
// @ts-ignore
const presetTime = currentHour < 8 ? '12:00' : currentHour <= 14 ? '18:00' : '10:00';
// @ts-ignore
// const presetDay = currentHour <= 14 ? moment().format('YYYY-MM-DD HH:mm') : moment().add(1, 'days').format('YYYY-MM-DD HH:mm');

export const Todo: React.FC = () => {
    // @ts-ignore
    let { todo_id } = useParams();
    const { data, error }: { data: TodoType; error: any } = useAuthRequest('/todo/new', 0, todo_id ? { todo_id } : {});
    const { data: usersData, error: usersError } = useAuthRequest('/user');

    if (!todo_id) {
        return <Alert type="error" message="Es wurde keine Todo ID angegeben." />;
    }

    if (error || usersError) {
        return <Alert type="error" message="Es wurde kein Todo zur Bearbeitung gefunden." />;
    }

    if (!data || !usersData) {
        return <ActivityIndicator />;
    }

    // TODO: Should be a utils function - used at least 2x (Todo / Taskshistory)
    const getUsernameById = (id: string) => usersData.filter((user: UserType) => user.user_id === id)[0]?.user_name || 'unbekannt';

    const updateTodo = (todo: TodoType) => {
        //@ts-ignore
        apiPut('/todo/new', todo)
            .then((res) => {
                console.log(res);
                if (todo.todo_closed_at === null && todo.todo__document_id) {
                    apiPost('/documentstatus', { document_id: todo.todo__document_id, documentstatus_step: 50 })
                        .then((res) => {
                            console.log(res);
                            invalidateSWR('/documentstatus', { document_id: `${todo.todo__document_id}` });
                        })
                        .catch((err) => console.error(err));
                }
                invalidateSWR('/todo/new', { todo_id });
            })
            .catch((err) => {
                console.error(err);
            });
    };

    return (
        <>
            {data.todo_closed_at ? (
                <>
                    <TileRow>
                        <Typography.Paragraph>
                            <Typography.Text strong>Todo bereits abgeschlossen: </Typography.Text>
                            <Typography.Text>
                                {formatDistance(new Date(data.todo_closed_at), new Date(Date.now()), { addSuffix: true, locale: de })}
                            </Typography.Text>
                        </Typography.Paragraph>
                        <Divider />
                        <Typography.Paragraph>
                            {data.todo__document_id && (
                                <Alert
                                    message={`Mit Reaktivierung des Todos bekommt das Dokument den Status "Todo"`}
                                    type="info"
                                    style={{ marginBottom: GUTTER }}
                                />
                            )}
                            <Button onClick={() => updateTodo({ ...data, todo_closed_at: null })} type="primary" danger style={{ width: '100%' }}>
                                Todo reaktivieren
                            </Button>
                        </Typography.Paragraph>
                    </TileRow>
                    <Tile>
                        <Divider />
                    </Tile>
                </>
            ) : (
                <>
                    <TileRow>
                        {data.todo__document_id && (
                            <Alert
                                message={`Mit Erledigung des Todos bekommt das Dokument den Status "Fertig"`}
                                type="info"
                                style={{ marginBottom: GUTTER }}
                            />
                        )}
                        <Button
                            onClick={() => updateTodo({ ...data, todo_closed_at: `${new Date(Date.now())}}` })}
                            type="primary"
                            style={{ width: '100%' }}
                        >
                            Todo als erledigt markieren
                        </Button>
                    </TileRow>
                    <Tile>
                        <Divider />
                    </Tile>
                </>
            )}

            {/* TODO BEARBEITEN */}
            {!data.todo_closed_at && (
                <>
                    {/* Title */}
                    <TileRow>
                        <Col span={24}>
                            <Typography.Title level={2}>Todo bearbeiten</Typography.Title>
                        </Col>
                        <Col span={4}>
                            <Typography.Text>Titel:</Typography.Text>
                        </Col>
                        <Col span={20}>
                            <Search
                                placeholder={data.todo_title}
                                enterButton="Titel updaten"
                                // size="large"
                                onSearch={(val) => updateTodo({ ...data, todo_title: `${val}` })}
                            />
                        </Col>
                    </TileRow>
                    {/* Als wichtig markieren */}
                    <TileRow>
                        <Col span={4}>
                            <Typography.Text>Hohe Priorität:</Typography.Text>
                        </Col>
                        <Col span={20}>
                            <Switch checked={data.todo_has_high_sevirity} onChange={(val) => updateTodo({ ...data, todo_has_high_sevirity: val })} />
                        </Col>
                    </TileRow>
                    <TileRow>
                        <Col span={4}>
                            <Typography.Text>Ablaufdatum:</Typography.Text>
                        </Col>
                        <Col span={20}>
                            {/* Fälligkeitsdatum */}
                            <DatePicker
                                locale={locale}
                                format="YYYY-MM-DD HH:mm"
                                defaultValue={data.todo_due_date ? moment(data.todo_due_date, 'YYYY-MM-DD HH:mm') : undefined}
                                disabledDate={disabledDate}
                                showTime={{ defaultValue: moment(presetTime, 'HH:mm') }}
                                showNow={false}
                                onChange={(date, dateString) => updateTodo({ ...data, todo_due_date: dateString })}
                            />
                        </Col>
                    </TileRow>
                    <TileRow>
                        <Col span={4}>
                            <Typography.Text>Dokument ID:</Typography.Text>
                        </Col>
                        <Col span={20}>
                            {/* Zugehöriges Dokument */}
                            {data.todo__document_id && <Typography.Text>{data.todo__document_id}</Typography.Text>}
                        </Col>
                    </TileRow>
                    {/* Benutzer verknüpfen */}
                    <TileRow>
                        <Col span={4}>
                            <Typography.Text>Benutzer:</Typography.Text>
                        </Col>
                        <Col span={20} style={{ marginBottom: 20 }}>
                            <Select
                                onChange={(value) => updateTodo({ ...data, todo__user_id: `${value}` })}
                                placeholder={data.todo__user_id ? getUsernameById(data.todo__user_id) : 'Wähle einen User aus'}
                            >
                                <Select.Option value="" key="">
                                    -- Keinem Benutzer zuweisen --
                                </Select.Option>
                                {usersData.map((user: any) => (
                                    <Select.Option value={user.user_id} key={user.user_id}>
                                        {user.user_name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Col>
                    </TileRow>
                </>
            )}
        </>
    );
};
