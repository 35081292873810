import { Button } from 'antd';
import React from 'react';
import ReactLoading from 'react-loading';
import { Link } from 'react-router-dom';
import colors from '../theme/colors';
import { GUTTER } from '../theme/constants';

import Delayed from './Delayed';

export const ActivityIndicator: React.FC = () => {
    return (
        <div
            style={{
                zIndex: 1000,
                position: 'fixed',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                backgroundColor: 'rgba(255,255,255,.8)',
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <ReactLoading type="spin" color={colors.primaryBlue} height={64} width={64} />
            <Delayed waitBeforeShow={500}>
                <Link to="/signin">
                    <Button type="primary" style={{ marginTop: GUTTER * 2 }}>
                        Neu einloggen
                    </Button>
                </Link>
            </Delayed>
        </div>
    );
};
