import React from 'react';
import { GUTTER } from '../theme/constants';

interface Props {
    size: number;
    horizontal?: boolean;
}

export const Spacer: React.FC<Props> = ({ size = 1, horizontal = false }) => {
    if (horizontal) {
        return <div style={{ height: '100%', width: size * GUTTER }}></div>;
    }
    return <div style={{ width: '100%', height: size * GUTTER }}></div>;
};
