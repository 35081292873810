import React from 'react';
import { Popconfirm, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { formatDistance } from 'date-fns';
import { de } from 'date-fns/locale';
import './Todo.module.less';

// COMPONENTS
import { MyIcons } from '../../theme/MyIcons';

// UTILS
import { user } from '../../utils/user';
import colors from '../../theme/colors';

// TYPES
import { TodoType } from '../../types/api';

interface Props {
    todo: TodoType;
    markComplete: any;
    markOpen: any;
}

export const Todo: React.FC<Props> = ({ todo, markComplete, markOpen }) => {
    const {
        todo_id,
        todo_title,
        todo_has_high_sevirity,
        todo_due_date,
        // todo_created_at,
        // todo_order_position,
        todo_closed_at,
        // todo__user_id,
        todo__document_id,
    } = todo;

    const checked = !!todo_closed_at;

    // @ts-ignore
    // const userID = user().info.user || '';
    // @ts-ignore
    const userscope = user().info?.scope || [];
    let classname = 'todo';
    if (checked) {
        classname = 'todo todo--checked';
    }
    if (!checked && todo_has_high_sevirity) {
        classname = 'todo todo--important';
    }
    if (checked && todo_has_high_sevirity) {
        classname = 'todo todo--important-checked';
    }
    return (
        <>
            {checked ? (
                <div className={classname}>
                    <Popconfirm
                        placement="top"
                        title="Soll der Tag reaktiviert werden?"
                        onConfirm={() => (checked ? markOpen(todo_id, todo__document_id) : markComplete(todo_id, todo__document_id))}
                        okText="Ja"
                        cancelText="Nein"
                    >
                        <div className="checkmark">
                            <MyIcons name={checked ? 'CheckboxChecked' : 'CheckboxUnchecked'} text={todo_title} />
                        </div>
                    </Popconfirm>
                    <div></div>
                </div>
            ) : (
                <div className={classname}>
                    <div
                        className="todo__checkmark"
                        onClick={() => (checked ? markOpen(todo_id, todo__document_id) : markComplete(todo_id, todo__document_id))}
                    >
                        <MyIcons name={checked ? 'CheckboxChecked' : 'CheckboxUnchecked'} text={todo_title} />
                    </div>
                    <div className="todo__icons">
                        {todo_due_date && (
                            <Typography.Text
                                style={{
                                    lineHeight: '24px',
                                    color: colors.ambientWhite,
                                    marginRight: `${24 / 2}px`,
                                }}
                            >
                                {formatDistance(new Date(todo_due_date), new Date(Date.now()), { addSuffix: true, locale: de })}
                            </Typography.Text>
                        )}
                        {todo__document_id && (
                            <Link to={`/document/${todo__document_id}`}>
                                <div className="todo__documentIcon" style={{ marginRight: `${24 / 2}px` }}>
                                    <MyIcons name="FileText" />
                                </div>
                            </Link>
                        )}
                        {userscope.includes('hocr:admin') && (
                            <>
                                <Link to={`/todo/${todo_id}`}>
                                    <div className="todo__editicon">
                                        <MyIcons name="Edit" />
                                    </div>
                                </Link>
                            </>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};
