import React from 'react';

interface Props {
    size?: number;
    strokeWidth?: number;
    color?: string;
}

export const FileText: React.FC<Props> = ({ size = 24, strokeWidth = 1.5, color }) => (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
        <path
            clipRule="evenodd"
            stroke={color}
            strokeWidth={strokeWidth}
            d="M18.414 6.414l-2.828-2.828A2 2 0 0 0 14.172 3H7a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V7.828a2 2 0 0 0-.586-1.414z"
        />
        <path clipRule="evenodd" stroke={color} strokeWidth={strokeWidth} d="M19 8h-4a1 1 0 0 1-1-1V3m-6 8h6m-6 3h6m-6 3h4.33" />
    </svg>
);
