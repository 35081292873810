import React from 'react';

// CONSTANTS
import { TaskSize as TaskSizeConstant } from '../constants/tasks';

// THEME
import colors from '../theme/colors';
import { MyIcons } from '../theme/MyIcons';

// TYPES
import { TaskSize as TaskSizeType } from '../types/api';

interface Props {
    task_size: TaskSizeType;
}

export const TaskSize: React.FC<Props> = ({ task_size }) => {
    let color = colors.primaryBlue;
    switch (task_size) {
        case 's':
            color = colors.secondaryBlue;
            break;
        case 'm':
            color = colors.primaryBlue;
            break;
        case 'l':
            color = colors.materialUIDeepPurple700;
            break;
    }

    return (
        <div style={{ width: 24, height: 24, position: 'relative', display: 'flex' }}>
            <div style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}>
                <MyIcons name="Shirt" color={color} />
            </div>
            <div
                style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                    textAlign: 'center',
                    fontSize: 9,
                    paddingTop: 4,
                    color,
                    textTransform: 'uppercase',
                }}
            >
                {task_size}
            </div>
        </div>
    );
};
