import React from 'react';
import { Typography } from 'antd';

// CONST
import { TaskStatus as TaskStatusDict } from '../constants/tasks';

// THEME
import colors from '../theme/colors';

// TYPES
import { TaskStatus as TaskStatusEnum } from '../types/task';

interface Props {
    task_status: TaskStatusEnum;
    inline?: boolean;
}

export const TaskStatus: React.FC<Props> = ({ task_status, inline = false }) => {
    let bgColor = colors.secondaryBlue;
    let textColor = colors.primaryBlue;

    switch (task_status) {
        case 'inprogress':
            bgColor = colors.primaryBlue;
            textColor = colors.ambientWhite;
            break;
        case 'inquiry':
            bgColor = colors.materialUIPurple700;
            textColor = colors.ambientWhite;
            break;
        case 'done':
            bgColor = colors.primaryGreen;
            textColor = colors.ambientWhite;
            break;
    }

    return (
        <div style={{ backgroundColor: bgColor, paddingLeft: 8, paddingRight: 8, borderRadius: 3, display: `${inline ? 'inline' : 'flex'}` }}>
            <Typography.Text style={{ color: textColor, textTransform: 'uppercase', letterSpacing: 1.1 }} strong>
                {TaskStatusDict[task_status]}
            </Typography.Text>
        </div>
    );
};
