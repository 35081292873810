import React from 'react';
import ReactDOM from 'react-dom';
// import 'antd/dist/antd.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ConfigProvider } from 'antd';
import deDE from 'antd/es/locale/de_DE';
import moment from 'moment';
import 'moment/locale/de';
moment.locale('de');

const WrappedApp: React.FC = () => {
    return (
        <ConfigProvider locale={deDE}>
            <App />
        </ConfigProvider>
    );
};

ReactDOM.render(<WrappedApp />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
