import React, { useState, useEffect } from 'react';
import { socket } from '../services/socket';
import { Toast } from './Toast';

// Types
import { ToastType } from '../types/toast';

interface Props {
    channel: string;
    notification?: boolean;
}

interface SocketMessage {
    language: string;
    title?: string;
    message: string;
    toastType?: ToastType;
}

export const SocketMessage: React.FC<Props> = ({ channel, notification = false }) => {
    const [response, setResponse] = useState<SocketMessage>({ language: 'DE', title: '', message: '' });

    useEffect(() => {
        socket.on(channel, (data: any) => {
            // console.log(data);
            setResponse(data);
        });

        // TODO: When unmounting this component, it should be ok to disconnect from Socket
        // but currently it will not be able to reconnect again...
        // return () => {
        //     socket.close();
        // };
    }, [channel]);

    if (response && response.message !== '') {
        if (notification) {
            return <Toast channel={channel} messageTitle={response.title} messageBody={response.message} toastType={response.toastType} />;
        }
        // console.log(`Socket Nachricht (${channel}): ${response.message}`);
        return <span style={{ padding: 5 }}>{response.message}</span>;
    }

    return null;
};
