import React, { useState, useEffect } from 'react';
import { Typography, Modal, Row, Col, Button, Switch, notification } from 'antd';

// COMPONENTS
import { Alert } from '../../components/Alert';
import { Spacer } from '../../components/Spacer';

// TYPES
import { Dispatcher } from '../../types/dispatcher';
import { UserType, UserScope } from '../../types/user';

// SERVICES
import { apiPatch, apiDelete } from '../../services/api';

interface Props {
    visible: boolean;
    setVisible: Dispatcher<boolean>;
    user: UserType;
}

const openNotificationWithIcon = (title: string, message: string) => {
    notification.success({
        message: title,
        description: message,
    });
};

export const EditUser: React.FC<Props> = ({ visible, setVisible, user }) => {
    const [userName, setUserName] = useState<string>(user.user_name);
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    const [isKAM, setIsKAM] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        setUserName(user.user_name);
        setIsAdmin(user.user_scope.includes('hocr:admin'));
        setIsKAM(user.user_scope.includes('hocr:keyaccount'));
    }, [user.user_scope, user.user_name]);

    useEffect(() => {
        setUserName(user.user_name);
        setIsAdmin(user.user_scope.includes('hocr:admin'));
        setIsKAM(user.user_scope.includes('hocr:keyaccount'));
    }, [user.user_name, user.user_scope]);

    const onUsernameChange = async (name: string) => {
        if (name.length >= 5) {
            setError(null);
            apiPatch('/user', {
                user_id: user.user_id,
                setName: name,
            })
                .then((res) => {
                    console.log(res);
                })
                .catch((err) => setError(err));
            setUserName(name);
        } else {
            setError('Benutzername muss mindestens 5 Zeichen lang sein.');
        }
    };
    const onPasswordChange = async (pass: string) => {
        if (pass.length >= 5) {
            if (pass === 'Neues Passwort') {
                setError('Bitte gib ein neues Passwort ein.');
            } else {
                setError(null);
                apiPatch('/user', {
                    user_id: user.user_id,
                    setPassword: pass,
                })
                    .then((res) => {
                        console.log(res);
                    })
                    .catch((err) => setError(err));
            }
        } else {
            setError('Das Passwort muss mindestens 5 Zeichen lang sein.');
        }
    };

    const onScopeChange = async (scopeType: UserScope) => {
        console.log('SCOPE', scopeType);
        let scopeVal = false;
        if (scopeType === 'hocr:admin') {
            setIsAdmin(!isAdmin);
            scopeVal = !isAdmin;
            console.log('isAdmin: ', !isAdmin, scopeVal ? 'add' : 'remove');
        } else if (scopeType === 'hocr:keyaccount') {
            setIsKAM(!isKAM);
            scopeVal = !isKAM;
            console.log('isKAM: ', !isKAM, scopeVal ? 'add' : 'remove');
        }

        apiPatch('/user', {
            user_id: user.user_id,
            scope: scopeType,
            scopeVal: scopeVal ? 'add' : 'remove',
        })
            .then((res) => {
                console.log('PATCH REQUEST......');
                openNotificationWithIcon(
                    'Benutzerdaten angepasst',
                    'Die Daten wurden erfolgreich an die Datenbank übermittelt. Die Änderungen ins sofort wirksam.',
                );
                console.log(res);
            })
            .catch((err) => setError(err));
    };

    const onDelete = async () => {
        apiDelete('/user', {
            user_id: user.user_id,
        })
            .then((res) => {
                console.log(res);
            })
            .catch((err) => setError(err));
    };

    return (
        <Modal
            visible={visible}
            footer={null}
            onCancel={() => {
                setVisible(false);
            }}
            afterClose={() => {
                console.log('CLOSE', user.user_name);
                setUserName(user.user_name);
            }}
            destroyOnClose
            // forceRender
        >
            <div>
                <Row>
                    <Col span={24}>
                        <Typography.Title level={2}>Benutzer bearbeiten</Typography.Title>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Typography.Paragraph>
                            <Alert type="info" message="Änderung des Benutzernamens ist gleichbedeutend mit der Änderung des Loginname! " closable />
                        </Typography.Paragraph>
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Typography.Text strong>Name:</Typography.Text>
                    </Col>
                    <Col span={16}>
                        <Typography.Text editable={{ onChange: onUsernameChange }}>{userName}</Typography.Text>
                    </Col>
                </Row>
                <Spacer size={1} />
                <Row>
                    <Col span={8}>
                        <Typography.Text strong>Neues Passwort:</Typography.Text>
                    </Col>
                    <Col span={16}>
                        <Typography.Text editable={{ onChange: onPasswordChange }}></Typography.Text>
                    </Col>
                </Row>
                <Spacer size={1} />
                <Row>
                    <Col span={8}>
                        <Typography.Text strong>Key Account Manager</Typography.Text>
                    </Col>
                    <Col span={16}>
                        <Switch checked={isKAM} onChange={() => onScopeChange('hocr:keyaccount')} />
                    </Col>
                </Row>
                <Spacer size={1} />
                <Row>
                    <Col span={8}>
                        <Typography.Text strong>Administrator</Typography.Text>
                    </Col>
                    <Col span={16}>
                        <Switch checked={isAdmin} onChange={() => onScopeChange('hocr:admin')} />
                    </Col>
                </Row>
                <Spacer size={4} />
                <Row>
                    <Col span={24}>
                        <Button onClick={onDelete} danger>
                            Benutzer löschen
                        </Button>
                    </Col>
                </Row>
            </div>

            {error && <Alert type="error" message={error} />}
        </Modal>
    );
};
