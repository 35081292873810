import React, { useState } from 'react';
import { Divider, Typography, Row, Button } from 'antd';
import { formatDistance } from 'date-fns';
import { de } from 'date-fns/locale';

// COMPONENTS
import { ActivityIndicator } from '../../components/ActivityIndicator';
import { Alert } from '../../components/Alert';
import { Tile } from '../../components/Tile';
import { TileRow } from '../../components/TileRow';
import { Spacer } from '../../components/Spacer';
import { TaskSeverity } from '../../components/TaskSeverity';
import { TaskStatus } from '../../components/TaskStatus';
import { TaskSize } from '../../components/TaskSize';

// THEME
import { GUTTER } from '../../theme/constants';

// HOOKS
import { useAuthRequest } from '../../hooks/useAuthRequest';

// TYPES
import { ApiGetTaskhistory, UserType, TaskSize as TaskSizeType, TaskSeverity as TaskSeverityType } from '../../types/api';
import { TaskStatus as TaskStatusEnum } from '../../types/task';

interface Props {
    task_id: string;
}

const { Text } = Typography;

export const Taskhistory: React.FC<Props> = ({ task_id }) => {
    const { data, error }: { data: ApiGetTaskhistory[]; error: any } = useAuthRequest(`/taskhistory?taskhistory__task_id=${task_id}`, 0);
    const { data: usersData, error: usersError } = useAuthRequest('/user');

    if (error) {
        return <Alert type="error" message="Es konnten keine Taskhistorie geladen werden." />;
    }

    if (usersError) {
        return <Alert type="error" message="Es konnten keine User geladen werden (Zuordnung von Task Historie)." />;
    }

    if (!data || !usersData) {
        return <ActivityIndicator />;
    }

    if (!data.length) {
        return null;
    }

    // TODO: Should be a utils function - used at least 2x (Todo / Taskshistory)
    const getUsernameById = (id: string) => usersData.filter((user: UserType) => user.user_id === id)[0]?.user_name || 'unbekannt';

    return (
        <>
            <TileRow>
                <Tile span={24}>
                    {data.map((val: ApiGetTaskhistory) => {
                        let KEY;
                        let OLD_VALUE;
                        let NEW_VALUE;

                        // Format KEY
                        switch (val.taskhistory_fieldname) {
                            case 'task_assignee__user_id':
                                KEY = 'Zuweisung geändert: ';
                                OLD_VALUE = (
                                    <Text disabled strong>
                                        {getUsernameById(val.taskhistory_old)}
                                    </Text>
                                );
                                NEW_VALUE = (
                                    <Text type="success" strong>
                                        {getUsernameById(val.taskhistory_new)}
                                    </Text>
                                );
                                break;
                            case 'task_content':
                                KEY = 'Beschreibung angepasst: ';
                                OLD_VALUE = (
                                    <Text disabled strong>
                                        {val.taskhistory_old.substring(0, 1000)}
                                    </Text>
                                );
                                NEW_VALUE = (
                                    <Text type="success" strong>
                                        {val.taskhistory_new.substring(0, 1000)}
                                    </Text>
                                );
                                break;
                            case 'task_size':
                                KEY = 'Größe: ';
                                OLD_VALUE = <TaskSize task_size={val.taskhistory_old as TaskSizeType} />;
                                NEW_VALUE = <TaskSize task_size={val.taskhistory_new as TaskSizeType} />;
                                break;
                            case 'task_severity':
                                KEY = 'Priorität: ';
                                OLD_VALUE = <TaskSeverity task_severity={val.taskhistory_old as TaskSeverityType} />;
                                NEW_VALUE = <TaskSeverity task_severity={val.taskhistory_new as TaskSeverityType} />;
                                break;
                            case 'task_status':
                                KEY = 'Task Status angepasst: ';
                                OLD_VALUE = <TaskStatus task_status={val.taskhistory_old as TaskStatusEnum} inline />;
                                NEW_VALUE = <TaskStatus task_status={val.taskhistory_new as TaskStatusEnum} inline />;
                                break;
                            default:
                                KEY = val.taskhistory_fieldname;
                                OLD_VALUE = <Text strong>{val.taskhistory_old}</Text>;
                                NEW_VALUE = (
                                    <Text type="success" strong>
                                        {val.taskhistory_new}
                                    </Text>
                                );
                        }

                        return (
                            <Row key={val.taskhistory_id} gutter={GUTTER}>
                                <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                                    <div style={{ flex: 1 }}>{KEY}</div>
                                    <div
                                        style={{
                                            flex: 1,
                                            display: 'flex',
                                            flexDirection: 'row',
                                            padding: GUTTER,
                                            paddingTop: GUTTER / 2,
                                            paddingBottom: GUTTER / 2,
                                        }}
                                    >
                                        <div style={{ flex: 1, opacity: 0.8 }}>{OLD_VALUE}</div>
                                        <div style={{ flex: 1 }}>{NEW_VALUE}</div>
                                    </div>
                                    <div style={{ flex: 1 }}>
                                        <Text type="secondary" style={{ fontSize: 10 }}>
                                            von {val.user_name}{' '}
                                            {formatDistance(new Date(val.taskhistory_timestamp), new Date(Date.now()), {
                                                addSuffix: true,
                                                locale: de,
                                            })}
                                        </Text>
                                    </div>
                                </div>
                                <Spacer size={1} />
                                <Divider style={{ marginTop: GUTTER / 2, marginBottom: GUTTER }} />
                            </Row>
                        );
                    })}
                </Tile>
            </TileRow>
        </>
    );
};
