import React from 'react';

// HOOKS
import { useAuthRequest } from '../hooks/useAuthRequest';

// TYPES
import { customerBrokerNames } from '../types/api';

interface Props {
    document_id: string;
    document_page?: number;
}

export const DocumentTitle: React.FC<Props> = ({ document_id, document_page }) => {
    const { data, error }: { data: customerBrokerNames[]; error: any } = useAuthRequest(
        `/document/customer-broker-names?document_id=${document_id}`,
        0,
    );

    if (error) {
        console.log('Fehler bei der dynamischen Erzeugung des Seitentitels: ', error);
    }

    if (data) {
        const customer = data[0].customer_name || '';
        const broker = data[0].broker_name || '';
        const page = document_page || '';
        if (document_page) {
            document.title = `📝 ${customer} | ${broker} | ${page}`;
        } else {
            document.title = `${customer} | ${broker}`;
        }
    }

    return null;
};
