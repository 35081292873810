import React from 'react';

interface Props {
    size?: number;
    strokeWidth?: number;
    color?: string;
}

export const TimerFast: React.FC<Props> = ({ size = 24, strokeWidth = 1.5, color }) => (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
        <path
            d="M12 5.99756C16.1439 5.99756 19.5031 9.35682 19.5031 13.5007C19.5031 17.6445 16.1439 21.0038 12 21.0038C7.85614 21.0038 4.49687 17.6445 4.49687 13.5007C4.49687 9.35682 7.85614 5.99756 12 5.99756"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path d="M12 8.99902V13.0007" stroke={color} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M9.99916 2.9963H14.0008" stroke={color} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M20.0033 6.99798L19.0029 5.99756" stroke={color} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);
