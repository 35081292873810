import React from 'react';

interface Props {
    size?: number;
    strokeWidth?: number;
    color?: string;
}

export const DocumentHasTag: React.FC<Props> = ({ size = 24, strokeWidth = 1.5, color }) => (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
        <path
            clipRule="evenodd"
            stroke={color}
            strokeWidth={strokeWidth}
            d="M18.414 6.414l-2.828-2.828A2 2 0 0 0 14.172 3H7a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V7.828a2 2 0 0 0-.586-1.414z"
        />
        <path stroke={color} strokeWidth={strokeWidth} d="M19 8h-4a1 1 0 0 1-1-1V3M9.7 18l1.15-6.91m2.3 6.91l1.15-6.91m1.5 1.76h-7m6.4 3.4h-7" />
    </svg>
);

export const DocumentHasTodo: React.FC<Props> = ({ size = 24, strokeWidth = 1.5, color }) => (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
        <path
            clipRule="evenodd"
            stroke={color}
            strokeWidth={strokeWidth}
            d="M18.414 6.414l-2.828-2.828A2 2 0 0 0 14.172 3H7a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V7.828a2 2 0 0 0-.586-1.414z"
        />
        <path
            stroke={color}
            strokeWidth={strokeWidth}
            d="M19 8h-4a1 1 0 0 1-1-1V3m2 8.12h-4.75M16 14h-4.75M16 16.88h-4.75M8.125 11a.125.125 0 1 0 0 .25.125.125 0 0 0 0-.25m0 2.875a.125.125 0 1 0 0 .25.125.125 0 0 0 0-.25m0 2.875a.125.125 0 1 0 0 .25.125.125 0 0 0 0-.25"
        />
    </svg>
);
