import React from 'react';
import { UserDispatchContext } from '../context/UserDispatchContext';

export const useUserDispatch = () => {
    const userDispatchContext = React.useContext(UserDispatchContext);
    if (userDispatchContext === undefined) {
        throw new Error('useUserDispatch must be used within a UserProvider');
    }
    return userDispatchContext;
};
