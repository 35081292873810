import { useUserState } from './useUserState';

export default function useAuthStatus() {
    const authObject = useUserState();

    const checkAuthToken = () => {
        if (authObject?.isLoading === true) {
            return undefined;
        }
        if (authObject?.user?.token) {
            return true;
        }
        return false;
    };

    const isAuthenticated = checkAuthToken();

    return isAuthenticated;
}
