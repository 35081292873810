import { api } from '../services/api';
import { useHistory } from 'react-router-dom';
import { useUserDispatch } from './useUserDispatch';
import { UserActionTypes } from '../types/userAuth';

interface signIn {
    name: string;
    password: string;
}

export default function useAuthentication() {
    const dispatch = useUserDispatch();
    let history = useHistory();

    async function handleSignin({ name, password }: signIn) {
        dispatch({ type: UserActionTypes.LOADING });
        const {
            data: { token },
        } = await api.post('/auth/signin', {
            name,
            password,
        });

        if (token) {
            localStorage.setItem('token', token);
            api.defaults.headers.Authorization = `${token}`;
            dispatch({
                type: UserActionTypes.SUCCESS,
                payload: { token },
            });

            history.push('/');
        }
    }

    async function handleSingOut() {
        dispatch({ type: UserActionTypes.LOADING });
        localStorage.removeItem('token');
        api.defaults.headers.Authorization = '';
        dispatch({
            type: UserActionTypes.SUCCESS,
            payload: {},
        });
        history.push('/signin');
    }

    return { handleSignin, handleSingOut };
}
