import * as React from 'react';
import { Typography } from 'antd';

// THEME
import colors from '../theme/colors';
import { GUTTER } from '../theme/constants';

// TYPES
import { DocumentJoinTodoType, documentsType } from '../types/api';

// COMPONENTS
import { Alert } from '../components/Alert';
import { Image } from './Gallery/Image';
import { Icons } from './Gallery/Icons';
import { UploadDate } from './Gallery/UploadDate';
import { DocumentStep } from '../screens/Overview/DocumentStep';
import { Spacer } from './Spacer';

interface Props {
    document: DocumentJoinTodoType | documentsType;
    bigThumbs: boolean;
    buildArray?: boolean; //TODO: documentsTagsARRAY_AGG and documentsTodosARRAY_AGG will use tags and todos as comma separated list
}

export const Gallery: React.FC<Props> = ({ document, bigThumbs, buildArray = false }) => {
    const { document_id, document_originalname, documentstatus_step, document_timestamp, tags, todos } = document;
    console.log('document', document);
    let tagArr: string[], todoArr: string[];

    if (tags === null) {
        return <Alert type="error" message="Es konnten keine Dokumente gefunden werden." />;
    }

    if (!Array.isArray(tags)) {
        tagArr = tags.split(',');
    } else {
        tagArr = tags;
    }

    if (!Array.isArray(todos)) {
        todoArr = todos.split(',');
    } else {
        todoArr = todos;
    }

    return (
        <div
            style={{
                padding: GUTTER,
                backgroundColor: colors.background,
                borderRadius: 4,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <div
                style={{
                    flex: 1,
                    display: 'flex',
                }}
            >
                <Image document_id={document_id} document_originalname={document_originalname} bigThumbs={bigThumbs} />
            </div>

            <Spacer size={1} />

            {/* Bildtitel */}
            <div style={{ flex: 0 }}>
                <Typography.Paragraph
                    style={{
                        fontSize: '11px',
                        lineHeight: '12px',
                        paddingTop: '10px',
                        textAlign: 'center',
                    }}
                >
                    {document.document_originalname}
                </Typography.Paragraph>
            </div>

            <Spacer size={1} />

            {/* STEP */}
            <div style={{ flex: 0 }}>
                <DocumentStep step={documentstatus_step} />
            </div>

            <Spacer size={1} />

            {/* UPLOADDATUM */}
            <div style={{ flex: 0 }}>
                <UploadDate timestamp={document_timestamp} />
            </div>

            <Spacer size={1} />

            {/* ICONS */}
            <div style={{ flex: 0 }}>
                <Icons document_id={document_id} tags={tagArr} todos={todoArr} />
            </div>
        </div>
    );
};
