export const GUTTER = 12;
export const TILE_WRAPPER = {
    display: 'flex',
    flexWrap: 'wrap',
};
export const TILE = {
    // display: 'flex',
    // flexDirection: 'column',
    // padding: GUTTER,
};
