import React, { useState, useEffect } from 'react';
import axios, { AxiosResponse, AxiosError } from 'axios';
import { Button } from 'antd';
import { CheckCircleOutlined, WarningOutlined, ReloadOutlined } from '@ant-design/icons';
import colors from '../theme/colors';
import { useLocation } from 'react-router-dom';

interface Props {
    interval?: number;
    showText?: boolean;
    center?: boolean;
}

export const ServerHealth: React.FC<Props> = ({ interval = 30000, showText = false, center = false }) => {
    const [serverHealth, setServerHealth] = useState<boolean | null>(null);
    const [serverMessage, setServerMessage] = useState<string>('Serverstatus überprüfen');
    const [healthColor, setServerHealthColor] = useState<string>(colors.unknown);
    const [loading, setLoading] = useState<boolean>(false);
    const [axiosTimer, setAxiosTimer] = useState<string>('');
    let location = useLocation();

    useEffect(() => {
        setServerHealth(null);
        setServerMessage('Serverstatus überprüfen');
        setServerHealthColor(colors.unknown);
        setLoading(false);
        setAxiosTimer('');
    }, [location]);

    const checkServerHealth = () => {
        setLoading(true);
        let startTime = Date.now();
        axios
            .get(`${process.env.REACT_APP_API_ENDPOINT}/ping`, { timeout: 5000 })
            .then((response: AxiosResponse) => {
                axiosTimerFunc(startTime);
                setServerHealth(true);
                setServerMessage('Der Server ist verfügbar.');
                setServerHealthColor(colors.healthy);
                setLoading(false);
            })
            .catch((error: AxiosError) => {
                axiosTimerFunc(startTime);
                setServerHealth(false);
                setServerMessage(error.message);
                setServerHealthColor(colors.unhealthy);
                setLoading(false);
            });
    };

    const axiosTimerFunc = (startTime: number) => {
        let now = Date.now();
        let seconds = Math.floor((now - startTime) / 1000);
        let milliseconds = Math.floor((now - startTime) % 1000);
        setAxiosTimer(`${seconds},${milliseconds} Sekunden`);
    };

    return (
        <>
            <Button
                type="primary"
                icon={serverHealth !== null ? serverHealth ? <CheckCircleOutlined /> : <WarningOutlined /> : <ReloadOutlined />}
                onClick={() => checkServerHealth()}
                loading={loading}
                style={{
                    backgroundColor: loading ? colors.loading : healthColor,
                    borderColor: loading ? colors.loading : healthColor,
                    color: 'black',
                }}
            >
                {loading ? 'Serverstatus wird angefragt' : axiosTimer ? `${serverMessage} (${axiosTimer})` : `${serverMessage}`}
            </Button>
        </>
    );
};
