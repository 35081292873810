const colors = {
    primaryBlue: '#044596',
    primaryBlueLight: '#055bc6',
    secondaryBlue: '#00a8e0',
    primaryGreyLight: '#e6e6e6',
    primaryDarkGrey: '#3f3f3f',
    primaryGreen: '#b1c869',
    primaryGreenLight: '#b1c869',
    primaryOrange: '#f9b233',
    ambientWhite: '#ffffff',
    ambientBlack: '#0f0f0f',
    ambientGreyLight: '#efefef',
    ambientBeige: '#e8e5e0',
    ambientGrey: '#d9dadc',
    shadow1: '#044596',
    darkBlue: '#001554',

    background: '#eeeef1',

    // MATERIAL UI
    materialUIGrey100: '#f5f5f5',
    materialUIRed900: '#b71c1c',
    materialUILightBlue900: '#01579b',
    materialUIGreen900: '#1b5e20',
    materialUIYellow500: '#ffeb3b',
    materialUITeal500: '#009688',
    materialUIAmber500: '#ffb300',

    materialUIPurple700: '#7b1fa2', // 0
    materialUIDeepPurple700: '#512da8', // 1
    materialUIIndigo700: '#303f9f', // 2
    materialUIBlue700: '#1976d2', // 3
    materialUILightBlue700: '#0288d1', // 4
    materialUICyan700: '#0097a7', // 5
    materialUITeal700: '#00796b', // 6
    materialUIGreen700: '#388e3c', // ---
    materialUILightGreen700: '#689f38', // 10

    // Status colors (legacy)
    healthy: '#b7ec8f',
    unhealthy: '#ffa39e',
    unknown: '#DBDBDB',
    dead: '#ff0000',
    success: '#00ff00',
    warning: '#ffee00',
    error: '#ff0000',
    info: '#1890ff',
    loading: '#ffe58f',
};

module.exports = colors;
