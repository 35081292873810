const defaultRgbArr = [4, 69, 150];

// Will take a RGB color and provide black or white as color contrast suggestion
// http://www.w3.org/TR/AERT#color-contrast
export const colorContrast = (rgb = defaultRgbArr): 'black' | 'white' => {
    const brightness = Math.round((rgb[0] * 299 + rgb[1] * 587 + rgb[2] * 114) / 1000);
    const textColour = brightness > 125 ? 'black' : 'white';

    return textColour;
};

export const hexToRgb = (hex = '#0090ed') => {
    const replaced = hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => '#' + r + r + g + g + b + b);

    const matched = replaced.substring(1).match(/.{2}/g);

    const arr = matched?.map((x) => parseInt(x, 16));

    return arr || defaultRgbArr;
};
