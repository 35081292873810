import useSWR from 'swr';
import { user } from '../utils/user';
import { swrAuthorizedFetcher } from '../utils/swrFetcher';
import { g } from '../utils/globals';
import { objectToUrlParams } from '../utils/objectToUrlParams';
import { isEqual } from 'lodash';

interface ParamsObject {
    [key: string]: number | string;
}

export const useAuthRequest = (path: string, refreshInterval: number = 0, params?: ParamsObject | {}) => {
    const token = user().token || '';
    if (!path) {
        throw new Error('Path is required');
    }

    let encodedParams = '';
    if (params && !isEqual(params, {})) {
        encodedParams = objectToUrlParams(params);
    }

    const url = params ? `${g.API}${path}?${encodedParams}` : `${g.API}${path}`;
    // const url = params ? g.API + path + '?' + encodedParams : g.API + path;
    const { data, error }: any = useSWR([url, token], (url, token) => swrAuthorizedFetcher(url, token), { refreshInterval });

    console.debug({
        api: path,
        ...(refreshInterval !== 0 && { refreshInterval }),
        ...(params && { params }),
        ...(data && { data }),
        ...(error && { error }),
        request: {
            type: 'useAuthRequest',
            url,
            ...(encodedParams !== '' && { encodedParams }),
        },
    });

    return { data, error };
};
