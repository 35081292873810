import React, { useState } from 'react';
import { Modal, Form, Input, Button, Select, Typography } from 'antd';

// COMPONENTS
import { ActivityIndicator } from './ActivityIndicator';
import { Alert } from './Alert';

// SERVICES
import { apiPost } from '../services/api';

// HOOKS
import { useAuthRequest } from '../hooks/useAuthRequest';
import { useWindowSize } from '../hooks/useWindowSize';

// TYPES
import { Dispatcher } from '../types/dispatcher';
import { GUTTER } from '../theme/constants';
import { TaskDocuments } from '../types/api';

interface Props {
    visible: boolean;
    setVisibility: Dispatcher<boolean>;
    document_id?: string;
}

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 24 },
};
const tailLayout = {
    wrapperCol: { offset: 8, span: 8 },
};

export const AddTask: React.FC<Props> = ({ visible, setVisibility, document_id = null }) => {
    const size = useWindowSize();
    let windowWidth = 0;
    if (size && size.width) {
        windowWidth = size.width;
    }
    const [error, setError] = useState<any>(undefined);
    const [formData, setFormData] = useState({
        document_id: '',
    });

    const [form] = Form.useForm();
    const { data: dataTasksDocuments, error: errorTasksDocuments }: { data: TaskDocuments[]; error: any } = useAuthRequest('/task/documents');

    const onFinish = async (values: any) => {
        const params = {
            document_id: formData.document_id === '--' ? 'null' : formData.document_id,
            task_title: values.task_title,
            task_content: values.task_content,
        };
        apiPost('/task', params)
            .then((res) => {
                console.log(res);
                form.resetFields();
                setVisibility(false);
            })
            .catch((err) => console.error(err));
    };

    const onFinishFailed = (errorInfo: any) => {
        form.resetFields();
        setError(errorInfo);
    };

    return (
        <Modal
            visible={visible}
            footer={null}
            onCancel={() => {
                setVisibility(false);
                form.resetFields();
            }}
            width={windowWidth <= 1200 ? '85%' : 1000}
        >
            <Typography.Title level={2}>Neuen Task hinzufügen</Typography.Title>
            {error && (
                <Alert
                    title="Es ist ein Fehler aufgetreten"
                    message={error.errorFields.map((err: any) => err.errors[0])}
                    style={{ marginBottom: GUTTER * 2 }}
                />
            )}
            <Form {...layout} name="basic" onFinish={onFinish} onFinishFailed={onFinishFailed} form={form} onFieldsChange={() => setError(false)}>
                {/* Titel */}
                <Form.Item label="Titel" name="task_title" rules={[{ required: true, message: 'Bitte geben Sie einen Todo Titel an!' }]}>
                    <Input />
                </Form.Item>

                {/* Titel */}
                <Form.Item label="Beschreibung" name="task_content" rules={[{ required: true, message: 'Bitte geben Sie einen Beschreibung an!' }]}>
                    <Input.TextArea rows={4} placeholder="Task Beschreibung" />
                </Form.Item>

                {/* Document ID */}
                {document_id === null ? (
                    <Form.Item label="Dokument zuweisen">
                        {errorTasksDocuments && <Alert type="error" message="Es konnte keine Liste von Dokumenten vom Server geladen werden." />}
                        {!errorTasksDocuments && !dataTasksDocuments && <ActivityIndicator />}
                        {!errorTasksDocuments && dataTasksDocuments && (
                            <Select
                                defaultValue="--"
                                onChange={(value: string) => setFormData({ ...formData, document_id: value })}
                                showSearch
                                // onSearch={(value: string) => console.log(value)}
                                filterOption={(input: any, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            >
                                <Select.Option value="--">-- Dokument zuweisen --</Select.Option>
                                {dataTasksDocuments.map((document: TaskDocuments) => (
                                    <Select.Option
                                        title={`${document.broker_name} - ${document.customer_name} - ${document.document_originalname}`}
                                        value={document.document_id}
                                        key={document.document_id}
                                    >
                                        {`${document.broker_name} - ${document.customer_name} - ${document.document_originalname}`}
                                    </Select.Option>
                                ))}
                            </Select>
                        )}
                    </Form.Item>
                ) : (
                    <Form.Item label="Dokument">
                        {setFormData({ ...formData, document_id })}
                        <Typography.Text>{document_id}</Typography.Text>
                    </Form.Item>
                )}

                {/* TASK ERSTELLEN */}
                <Form.Item {...tailLayout} style={{ textAlign: 'center' }}>
                    <Button type="primary" htmlType="submit">
                        Task erstellen
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};
