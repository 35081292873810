import React from 'react';
import axios from 'axios';
// import { Link } from 'react-router-dom';
import colors from '../../theme/colors';

// SERVICES
import { user } from '../../utils/user';

// TYPES
import { TextractStatusType } from '../../types/textract';

interface StatusWrapperProps {
    text: string;
    bgcolor?: string;
    textcolor?: string;
}

const StatusWrapper: React.FC<StatusWrapperProps> = ({ text, bgcolor = colors.ambientGreyLight, textcolor = colors.ambientBlack }) => (
    <div
        style={{
            backgroundColor: bgcolor,
            borderStyle: 'solid',
            borderWidth: 2,
            borderTopWidth: 0,
            borderColor: colors.primaryBlue,
            padding: 0,
        }}
    >
        <div
            style={{
                paddingLeft: 8,
                paddingRight: 8,
                paddingTop: 4,
                paddingBottom: 4,
                color: textcolor,
                textAlign: 'center',
            }}
        >
            {text}
        </div>
    </div>
);

interface Props {
    document_id: string;
    table_s3_key: string;
    page_number: number;
    status: TextractStatusType;
    table_count: number;
}

export const TextractStatusBar: React.FC<Props> = ({ document_id, table_s3_key, page_number, status, table_count }) => {
    // @ts-ignore
    const user_id = user().info?.user || 'unbekannt';
    // @ts-ignore
    const user_name = user().info?.name || 'unbekannt';
    // @ts-ignore
    const user_shortid = user().info?.shortid || 'unbekannt';

    if (status && status === 'finished') {
        if (table_count < 1) {
            return <StatusWrapper text="Texte erkannt" bgcolor={colors.materialUIGreen700} textcolor={colors.ambientWhite} />;
        } else {
            // return (
            //     <Link
            //         key={`table_${table_s3_key}_${page_number}_${table_count}`}
            //         to={{
            //             pathname: '/textract-table',
            //             search: `?table_key=${table_s3_key}&table_number=${table_count}`,
            //         }}
            //     >
            //         <StatusWrapper
            //             text={table_count > 1 ? `${table_count} Tabellen erkannt` : 'Eine Tabelle erkannt'}
            //             bgcolor={colors.materialUIGreen700}
            //             textcolor={colors.ambientWhite}
            //         />
            //     </Link>
            // );
            return (
                <StatusWrapper
                    text={table_count > 1 ? `Texterkennung abgeschlossen` : 'Texte erkannt'} //TODO: momentan immer 1
                    bgcolor={colors.materialUIGreen700}
                    textcolor={colors.ambientWhite}
                />
            );
        }
    } else if (status && status === 'pending') {
        return <StatusWrapper text="Texterkennung läuft..." />;
    }

    return (
        <div
            style={{ cursor: 'pointer' }}
            onClick={() => {
                axios
                    .post(`${process.env.REACT_APP_QUEUE_ENDPOINT}/starttextract`, {
                        document_id,
                        user_id,
                        user_name,
                        user_shortid,
                        page_number,
                    })
                    .then((res) => console.log(res))
                    .catch((err) => console.error(err));
            }}
        >
            <StatusWrapper text="Texterkennung starten" bgcolor={colors.primaryBlue} textcolor={colors.ambientWhite} />
        </div>
    );
};
