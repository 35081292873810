import * as React from 'react';
import { useLocation } from 'react-router-dom';

// COMPONENTS
import { ActivityIndicator } from '../components/ActivityIndicator';
import { Alert } from '../components/Alert';
import { DocumentTitle } from '../components/DocumentTitle';
import { TextractOverlay } from './Textract/TextractOverlay';

// HOOKS
import { useAuthRequest } from '../hooks/useAuthRequest';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export const Textract: React.FC = () => {
    let query = useQuery();

    const document_id = query.get('document_id') || null;
    const document_page = parseInt(`${query.get('page') || 0}`, 10);

    const params = {
        document_id: document_id || '',
        document_page: document_page + 1,
    };

    const { data, error } = useAuthRequest('/textract', 0, params);
    if (error) {
        return <Alert type="error" message="Das angeforderte Dokument konnte leider nicht geladen werden." />;
    }

    if (!data && !error) {
        return <ActivityIndicator />;
    }

    if (![document_id, document_page].every(Boolean)) {
        if (document_page !== 0) {
            return <Alert message="Erforderliche Angaben fehlen: Dokument und/oder Seitenzahl." />;
        }
    }

    if (document_id && (document_page || document_page === 0)) {
        return (
            <div>
                <DocumentTitle document_id={document_id} document_page={document_page} />
                <TextractOverlay document_id={document_id} document_page_number={document_page} />
            </div>
        );
    }

    return null;
};
