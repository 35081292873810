import React, { useEffect, useReducer } from 'react';
import * as jwt from 'jsonwebtoken';
import { userReducer } from '../reducer/userReducer';
import { UserStateContext } from './UserStateContext';
import { UserDispatchContext } from './UserDispatchContext';
import { UserActionTypes } from '../types/userAuth';
import { api } from '../services/api';

export const UserProvider: React.FC = ({ children }) => {
    const [state, dispatch] = useReducer(userReducer, {
        isLoading: true,
    });

    // Check, if User already exists in localStorage. Use Information to automatically login.
    useEffect(() => {
        const token = localStorage.getItem('token');
        // Problem with non authenticated user trying to access protected sites ends up in endless loader.
        // dispatch({ type: UserActionTypes.LOADING });

        if (token) {
            const decodeToken: any = jwt.decode(token);
            if (decodeToken?.exp > Date.now() / 1000) {
                api.defaults.headers.Authorization = `${token}`;
                dispatch({
                    type: UserActionTypes.SUCCESS,
                    payload: {
                        token,
                    },
                });
            } else {
                // TODO: This may need additional action to log user out
                dispatch({ type: UserActionTypes.FAIL });
                localStorage.removeItem('token');
            }
        } else {
            // non logged in users get a failed status
            dispatch({ type: UserActionTypes.FAIL });
            localStorage.removeItem('token');
        }
    }, []);

    return (
        <UserStateContext.Provider value={state}>
            <UserDispatchContext.Provider value={dispatch}>{children}</UserDispatchContext.Provider>
        </UserStateContext.Provider>
    );
};
