import React from 'react';

interface Props {
    size?: number;
    strokeWidth?: number;
    color?: string;
}

export const WarningCheckmark: React.FC<Props> = ({ size = 24, strokeWidth = 1.5, color }) => (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
        <path d="M12 11.6688V8.39844" stroke={color} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round" />
        <path
            d="M12 15.2796C11.9572 15.2796 11.9162 15.2967 11.8861 15.3272C11.856 15.3576 11.8394 15.3989 11.8399 15.4417C11.8415 15.5294 11.9123 15.6001 12 15.6017C12.0895 15.6017 12.1621 15.5292 12.1621 15.4397C12.1621 15.3502 12.0895 15.2776 12 15.2776"
            stroke={color}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M21.0037 8.2666L17.6363 11.628L16.0017 9.99932"
            stroke={color}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M20.7727 14.0008C19.8797 17.9186 16.4986 20.7704 12.4863 20.9901C8.47401 21.2098 4.80182 18.7442 3.4864 14.9473C2.17098 11.1504 3.5308 6.94142 6.81909 4.63187C10.1074 2.32231 14.528 2.4713 17.6534 4.99702"
            stroke={color}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
