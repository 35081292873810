import React from 'react';
import { hexToRgb, colorContrast } from '../utils/colorCalculations';

interface Props {
    bgcolor: string;
    text: string;
}

export const ColorButton: React.FC<Props> = ({ bgcolor, text }) => {
    return (
        <span
            style={{
                backgroundColor: `${bgcolor}`,
                padding: '4px 6px',
                borderRadius: '4px',
            }}
        >
            <span style={{ color: colorContrast(hexToRgb(bgcolor)) }}>{text}</span>
        </span>
    );
};
