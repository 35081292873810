import React, { useEffect } from 'react';
import { notification } from 'antd';
import colors from '../theme/colors';

// Types
import { ToastType } from '../types/toast';

interface Props {
    channel: string;
    messageTitle?: string;
    messageBody: string;
    toastType?: ToastType;
}

export const Toast: React.FC<Props> = ({ channel, messageTitle = 'Neue Servermitteilung', messageBody, toastType = 'info' }) => {
    let bgcolor = colors.primaryBlue;
    switch (toastType) {
        case 'warning':
            bgcolor = colors.materialUIAmber500;
            break;
        case 'error':
            bgcolor = colors.error;
            break;
    }
    useEffect(() => {
        console.log('TOAST', messageTitle);
        notification.open({
            key: channel,
            message: messageTitle,
            description: messageBody,
            style: { backgroundColor: bgcolor },
        });
    }, [channel, messageTitle, messageBody, bgcolor]);

    return <></>;
};
