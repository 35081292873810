import React, { useState } from 'react';
import { Route, Redirect, Link } from 'react-router-dom';
import { Layout, Typography, Popover, Divider, Button } from 'antd';

// https://aleksandrhovhannisyan.github.io/blog/dev/how-to-set-up-react-typescript-ant-design-less-css-modules-and-eslint/#3-create-react-app-css-modules-and-typescript
import styles from '../App.module.less';

// HOOKS
import useAuthStatus from '../hooks/useAuthStatus';

// COMPONENTS
import { MainMenue } from '../components/MainMenue';
import { ServerHealth } from '../components/ServerHealth';
import { SignOutButton } from '../components/SignOutButton';
import { UserShortIdButton } from '../components/UserShortIdButton';
import { SocketMessage } from '../components/SocketMessage';

//SERVICES
import { socket } from '../services/socket';
import { GUTTER } from '../theme/constants';

// UTILS
import { user } from '../utils/user';
import colors from '../theme/colors';
import { ActivityIndicator } from './ActivityIndicator';

// DECONSTRUCT
const { Header, Content, Footer } = Layout;

export const ProtectedRoute = ({ fullscreen = false, comp: Component, ...rest }: any) => {
    const isAuthenticated = useAuthStatus();
    const [showPopover, setShowPopover] = useState(false);

    // @ts-ignore
    const userscope = user().info?.scope || [];

    return (
        <>
            {userscope.includes('hocr:admin') && <SocketMessage channel="SOCKET_MESSAGE_TOASTER" notification />}
            {!fullscreen && (
                <Header style={{ display: 'flex' }}>
                    <div style={{ flex: 1 }}>
                        <MainMenue />
                    </div>
                    <div style={{ display: 'flex', flex: 0, justifyContent: 'flex-end', alignItems: 'center', marginRight: GUTTER }}>
                        <UserShortIdButton />
                    </div>
                    <div style={{ display: 'flex', flex: 0, justifyContent: 'flex-end', alignItems: 'center' }}>
                        <SignOutButton />
                    </div>
                </Header>
            )}
            <Content className={styles.app}>
                <div
                    style={{
                        minHeight: 280,
                    }}
                >
                    <Route
                        {...rest}
                        render={(props) => {
                            if (isAuthenticated !== undefined) {
                                if (isAuthenticated) {
                                    return <Component {...props} />;
                                } else {
                                    return (
                                        <Redirect
                                            to={{
                                                pathname: '/signin',
                                                state: {
                                                    from: props.location,
                                                },
                                            }}
                                        />
                                    );
                                }
                            } else {
                                // We do not know about auth status. Might end up in endless loader.
                                return <ActivityIndicator />;
                            }
                        }}
                    />
                </div>
            </Content>
            {!fullscreen && (
                <Footer style={{ textAlign: 'center', backgroundColor: colors.background }}>
                    <div>
                        <Typography.Paragraph>
                            <Popover
                                content={
                                    <>
                                        <Typography.Paragraph>
                                            <Typography.Text>Umgebung/Server: {process.env.REACT_APP_ENV}</Typography.Text>
                                        </Typography.Paragraph>
                                        <Typography.Paragraph>
                                            <Typography.Text>SOCKET.IO: {process.env.REACT_APP_SOCKET_ENDPOINT}</Typography.Text>
                                        </Typography.Paragraph>
                                        <Typography.Paragraph>
                                            <Typography.Text>API: {process.env.REACT_APP_API_ENDPOINT}</Typography.Text>
                                        </Typography.Paragraph>
                                        <Divider />
                                        <ServerHealth showText center />
                                        <Divider />
                                        {/* Paste this into browser console to debug: */}
                                        {/* localStorage.debug = '*'; */}
                                        <Button
                                            onClick={() => {
                                                socket.emit('SOCKET_SYSTEMINFO_CPU_REQUEST', { language: 'DE', message: '' });
                                                socket.emit('SOCKET_SYSTEMINFO_MEM_REQUEST', { language: 'DE', message: '' });
                                                socket.emit('SOCKET_SYSTEMINFO_MEMCACHE_REQUEST', { language: 'DE', message: '' });
                                            }}
                                            style={{
                                                marginBottom: GUTTER,
                                            }}
                                        >
                                            Momentane Serverauslastung anzeigen
                                        </Button>

                                        <Typography.Paragraph>
                                            <SocketMessage channel="SOCKET_SYSTEMINFO_CPU_RESPONSE" />
                                        </Typography.Paragraph>
                                        <Typography.Paragraph>
                                            <SocketMessage channel="SOCKET_SYSTEMINFO_MEM_RESPONSE" />
                                        </Typography.Paragraph>
                                        <Typography.Paragraph>
                                            <SocketMessage channel="SOCKET_SYSTEMINFO_MEMCACHE_RESPONSE" />
                                        </Typography.Paragraph>

                                        <Divider />
                                        <Button
                                            onClick={() => {
                                                socket.emit('SOCKET_TEST_MESSAGE_TOASTER');
                                            }}
                                            style={{
                                                marginBottom: GUTTER,
                                            }}
                                        >
                                            Socket Testnachricht als Toast notification anfordern
                                        </Button>
                                        <Divider />
                                        <Typography.Paragraph>
                                            <Typography.Text>Echtzeit Verbindung (SOCKET)</Typography.Text>
                                            <SocketMessage channel="WELCOME" />
                                            <SocketMessage channel="SOCKET_USE_HOCR_VALUE" />
                                        </Typography.Paragraph>
                                        <Divider />
                                        <Button type="primary" onClick={() => setShowPopover(false)}>
                                            Schließen
                                        </Button>
                                    </>
                                }
                                title={`VARA HOCR TOOL ©${new Date().getUTCFullYear()}`}
                                trigger="click"
                                visible={showPopover}
                                onVisibleChange={() => setShowPopover(!showPopover)}
                            >
                                <Typography.Text>
                                    VARA HOCR TOOL ©{new Date().getUTCFullYear()} | v{process.env.REACT_APP_VERSION}
                                </Typography.Text>
                            </Popover>
                        </Typography.Paragraph>
                        <Typography.Paragraph>
                            <Typography.Text>
                                EXCEL ADDIN:
                                <Link to={{ pathname: `https://${process.env.REACT_APP_EXCEL_ADDIN_URL}` }} target="_blank">
                                    <Button type="link">{process.env.REACT_APP_EXCEL_ADDIN_URL}</Button>
                                </Link>
                            </Typography.Text>
                        </Typography.Paragraph>
                    </div>
                </Footer>
            )}
        </>
    );
};
