import React from 'react';
import { Calendar as CalendarPackage, momentLocalizer } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import moment from 'moment';
import colors from '../theme/colors';
import { Tile } from '../components/Tile';
import { TileRow } from '../components/TileRow';

// UTILS
import { user } from '../utils/user';

// HOOKS
import { useAuthRequest } from '../hooks/useAuthRequest';

// NODE MODULES
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss';

import events from './CalendarEvents';

// SERVICES
import { apiPost } from '../services/api';
import { ActivityIndicator } from '../components/ActivityIndicator';
import { Alert } from '../components/Alert';

// @ts-ignore
const DragAndDropCalendar = withDragAndDrop(CalendarPackage);

const localizer = momentLocalizer(moment);

const minTime = new Date();
minTime.setHours(5, 0, 0);
const maxTime = new Date();
maxTime.setHours(20, 0, 0);

// Render differenct colors
// @ts-ignore
const eventStyleGetter = (event, start, end, isSelected) => {
    // console.log('eventStyleGetter', event);
    let backgroundColor = colors.primaryBlue;
    let fontColor = colors.ambientBlack;
    // console.log('USER', event.user_id, backgroundColor)
    const style = {
        backgroundColor: backgroundColor,
        borderRadius: '4px',
        opacity: 0.9,
        color: fontColor,
        border: '0px',
        display: 'block',
    };
    return {
        style: style,
    };
};

const onSelectSlot = async (slot: any, user_id: string, user_name: string) => {
    const { action, start, end } = slot;
    console.log(user_id, slot.start, slot.end, slot);

    if (action === 'select') {
        const params = {
            calendar__user_id: user_id,
            calendar_type: 'calendar_human_resource',
            calendar_title: user_name,
            calendar_start: start,
            calendar_end: end,
        };
        apiPost('/calendar', params)
            .then((res) => {
                console.log(res);
            })
            .catch((err) => console.error(err));
    }
};

export const Calendar: React.FC = () => {
    // @ts-ignore
    const user_id = user().info?.user;
    // @ts-ignore
    const user_name = user().info?.name;

    const { data: calendarData, error: calendarError } = useAuthRequest('/calendar');

    if (calendarError) {
        return <Alert type="error" message="Es konnten keine Kalendareinträge aus der Datenbank geladen werden." />;
    }

    if (!calendarData) {
        return <ActivityIndicator />;
    }

    return (
        <>
            <TileRow>
                <Tile>
                    <DragAndDropCalendar
                        selectable="ignoreEvents"
                        localizer={localizer}
                        events={events}
                        onDragStart={(start) => console.log('onDragStart', start)}
                        onSelectEvent={(event) => console.log('onSelectEvent', event)}
                        eventPropGetter={eventStyleGetter}
                        onEventResize={(event) => console.log('onEventResize', event)}
                        // onSelectSlot={slot => console.log('onSelectSlot', slot)}
                        onSelectSlot={(slot) => onSelectSlot(slot, user_id, user_name)}
                        onEventDrop={(drop) => console.log('onEventDrop', drop)}
                        onDropFromOutside={(drag) => console.log('onDropFromOutside', drag)}
                        // defaultView={Views.MONTH}
                        // startAccessor="start"
                        // endAccessor="end"
                        style={{ height: 500 }}
                        min={minTime}
                        max={maxTime}
                        messages={{
                            month: 'Monat',
                            week: 'Woche',
                            day: 'Tag',
                            today: 'Heute',
                            agenda: 'Agenda',
                            next: 'weiter',
                            previous: 'zurück',
                            allDay: 'Ganztag',
                            date: 'Datum',
                            time: 'Uhrzeit',
                        }}
                    />
                </Tile>
            </TileRow>
        </>
    );
};
