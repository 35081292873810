import React from 'react';
import { UserStateContext } from '../context/UserStateContext';

export const useUserState = () => {
    const userStateContext = React.useContext(UserStateContext);
    if (userStateContext === undefined) {
        throw new Error('useUserState must be used within a UserProvider');
    }
    return userStateContext;
};
