import React, { useState, useEffect } from 'react';
import { Row, Col, Collapse, Alert, Button } from 'antd';

// COMPONENTS
import { UploadForm } from './Overview/UploadForm';
import { ActivityIndicator } from '../components/ActivityIndicator';
import { Tile } from '../components/Tile';
import { TileRow } from '../components/TileRow';
import { Gallery } from '../components/Gallery';

// THEME
import colors from '../theme/colors';
import { GUTTER } from '../theme/constants';
import { MyIcons } from '../theme/MyIcons';

// HOOKS
import { useAuthRequest } from '../hooks/useAuthRequest';

// UTILS
import { invalidateSWR } from '../utils/invalidateSWR';

// SERVICES
import { socket } from '../services/socket';

// TYPES
import { DocumentJoinTodoType } from '../types/api';

export const Overview: React.FC = () => {
    const { data, error }: { data: DocumentJoinTodoType[]; error: any } = useAuthRequest('/overview', 0);

    const [isUploadOpen, setIsUploadOpen] = useState(true);

    const [bigThumbs, setBigThumbs] = useState(false);

    // LISTEN ON "FINISHED" MESSAGE FROM SERVER TO RELOAD PAGE
    useEffect(() => {
        socket.on('SOCKET_MESSAGE_UPDATE_DOCUMENTS_SCREEN', (data: any) => {
            invalidateSWR('/overview');
        });
    }, []);

    if (error) {
        return <Alert type="error" message="Es konnte keine Liste von Dokumenten geladen werden." />;
    }

    if (!data) {
        return <ActivityIndicator />;
    }

    return (
        <>
            <TileRow>
                <Tile>
                    <Collapse defaultActiveKey={['1']} bordered={false} onChange={() => setIsUploadOpen(!isUploadOpen)}>
                        <Collapse.Panel
                            header={
                                <MyIcons
                                    name={isUploadOpen ? 'CircleArrowDown' : 'CircleArrowRight'}
                                    color={colors.primaryBlue}
                                    text="Dokumente hochladen"
                                    size={24}
                                    style={{
                                        fontSize: '20px',
                                        fontWeight: 500,
                                    }}
                                />
                            }
                            key={1}
                            showArrow={false}
                        >
                            <UploadForm />
                        </Collapse.Panel>
                    </Collapse>
                </Tile>
            </TileRow>

            {data.length && (
                <>
                    <TileRow>
                        <Tile>
                            <div style={{ textAlign: 'right' }}>
                                <Button onClick={() => setBigThumbs(!bigThumbs)}>
                                    {bigThumbs ? 'Vorschaubilder verkleinern' : 'Vorschaubilder vergrößern'}
                                </Button>
                            </div>
                        </Tile>
                    </TileRow>
                    <TileRow>
                        <Tile>
                            <>
                                <Row gutter={GUTTER}>
                                    {data.map((document: DocumentJoinTodoType) => (
                                        <Col
                                            key={`${document.document_id}`}
                                            span={4}
                                            xs={24}
                                            sm={bigThumbs ? 24 : 8}
                                            lg={bigThumbs ? 12 : 4}
                                            style={{
                                                padding: GUTTER / 2,
                                            }}
                                        >
                                            <Gallery document={document} bigThumbs={bigThumbs} />
                                        </Col>
                                    ))}
                                </Row>
                            </>
                        </Tile>
                    </TileRow>
                </>
            )}
        </>
    );
};
