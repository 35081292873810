import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Col, Row, Popover, Divider, Select, Tabs } from 'antd';
import { format, formatDistance } from 'date-fns';
import { de } from 'date-fns/locale';

// CONSTANTS
import { TaskStatus as TaskStatusLexicon, TaskSeverity as TaskSeverityLexicon, TaskSize as TaskSizeLexicon } from '../constants/tasks';

// COMPONENTS
import { ActivityIndicator } from '../components/ActivityIndicator';
import { Alert } from '../components/Alert';
import { TileRow } from '../components/TileRow';
import { TaskStatus } from '../components/TaskStatus';
import { TaskSize } from '../components/TaskSize';
import { TaskSeverity } from '../components/TaskSeverity';
import { Taskhistory } from './Task/Taskhistory';
import { Taskrelations } from './Task/Taskrelations';
import { UploadTaskAttachment } from './Task/UploadTaskAttachment';
import { TaskAttachments } from './Task/TaskAttachments';

// UTILS
import { invalidateSWR } from '../utils/invalidateSWR';

// SERVICES
import { apiPatch } from '../services/api';
import { socket } from '../services/socket';

// HOOKS
import { useAuthRequest } from '../hooks/useAuthRequest';

// TYPES
import { ApiGetTask } from '../types/api';
import { Spacer } from '../components/Spacer';
import colors from '../theme/colors';

type PatchableTaskType =
    | 'task_status'
    | 'task_assignee__user_id'
    | 'task_linked__document_id'
    | 'task_title'
    | 'task_content'
    | 'task_isClosed'
    | 'task_isArchived'
    | 'task_connection__task_id'
    | 'task_connection_title'
    | 'task_severity'
    | 'task_duedate'
    | 'task_size';

const { TabPane } = Tabs;

export const Task: React.FC = () => {
    // @ts-ignore
    let { task_id } = useParams() || 0;

    useEffect(() => {
        socket.on('SOCKET_MESSAGE_UPDATE_TASK', (data: any) => {
            invalidateSWR('/tasks');
            invalidateSWR('/task', { task_id: `${task_id}` });
        });
    }, []);

    const { data, error }: { data: ApiGetTask[]; error: any } = useAuthRequest(`/task?task_id=${task_id}`, 0);
    const { data: usersData, error: usersError } = useAuthRequest('/user');

    if (error) {
        return <Alert type="error" message="Es konnten keine Tasks geladen werden." />;
    }

    if (!data) {
        return <ActivityIndicator />;
    }

    const patchValue = (type: PatchableTaskType, value: string | number) => {
        const patchParams = { task_id };
        // @ts-ignore
        patchParams[type] = value;
        apiPatch('/task', patchParams, false, [`/task?task_id=${task_id}`])
            .then((res: any) => {
                invalidateSWR(`/task?task_id=${task_id}`);
                invalidateSWR(`/taskhistory?taskhistory__task_id=${task_id}`);
                console.log(res);
            })
            .catch((e: any) => console.log(e));
    };

    const taskStatusArray = Object.entries(TaskStatusLexicon);
    const taskSeverityArray = Object.entries(TaskSeverityLexicon);
    const taskSizeArray = Object.entries(TaskSizeLexicon);

    return (
        <>
            {data.length && (
                <>
                    <TileRow>
                        <Col span={24}>
                            <Typography.Title
                                level={2}
                                editable={{
                                    onChange: (value) => patchValue('task_title', value),
                                }}
                            >
                                {data[0].task_title}
                            </Typography.Title>
                        </Col>
                    </TileRow>

                    <TileRow>
                        <Col span={24}>
                            <div style={{ flex: 0, display: 'flex', flexDirection: 'row' }}>
                                <TaskSeverity task_severity={data[0].task_severity} />
                                <Spacer size={1} horizontal />
                                <TaskSize task_size={data[0].task_size} />
                                <Spacer size={1} horizontal />
                                <TaskStatus task_status={data[0].task_status} />
                                <Spacer size={1} horizontal />
                                {data[0].task_assignee__user_id && (
                                    <div>
                                        <Typography.Text>{data[0].assignee_name || data[0].task_assignee__user_id}</Typography.Text>
                                    </div>
                                )}
                            </div>
                        </Col>
                    </TileRow>

                    <TileRow>
                        <Col span={15}>
                            <Row>
                                <Col span={24}>
                                    <Typography.Paragraph
                                        editable={{
                                            onChange: (value) => patchValue('task_content', value),
                                            autoSize: { minRows: 3 },
                                        }}
                                    >
                                        {data[0].task_content}
                                    </Typography.Paragraph>
                                </Col>
                            </Row>
                            <Row>
                                <Tabs defaultActiveKey="1" type="card" style={{ marginBottom: 32, width: '100%' }}>
                                    <TabPane tab="Historie" key="1">
                                        <Taskhistory task_id={task_id} />
                                    </TabPane>
                                    <TabPane tab="Anhänge" key="2">
                                        <TaskAttachments task_id={task_id} />
                                    </TabPane>
                                    <TabPane tab="Verknüpfte Tasks" key="3">
                                        <Taskrelations task_id={task_id} />
                                    </TabPane>
                                </Tabs>
                            </Row>
                        </Col>
                        <Col span={8} offset={1}>
                            <Row>
                                <Col span={24}>
                                    <Typography.Title level={2}>Task anpassen</Typography.Title>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={8}>
                                    <Typography.Text strong>Author:</Typography.Text>
                                </Col>
                                <Col span={16}>
                                    <Typography.Text>{data[0].author_name}</Typography.Text>
                                </Col>
                            </Row>
                            <Spacer size={1} />
                            <Row>
                                <Col span={8}>
                                    <Typography.Text strong>Status:</Typography.Text>
                                </Col>
                                <Col span={16}>
                                    <Select
                                        defaultValue={TaskStatusLexicon[data[0].task_status]}
                                        onChange={(value: string) => patchValue('task_status', value)}
                                        style={{
                                            width: '100%',
                                            backgroundColor: colors.ambientGreyLight,
                                            borderRadius: 4,
                                            borderColor: colors.ambientGrey,
                                            borderWidth: 1,
                                            borderStyle: 'solid',
                                        }}
                                        bordered={false}
                                        showArrow={true}
                                        size="small"
                                    >
                                        {taskStatusArray.map((status, index) => (
                                            <Select.Option value={status[0]} key={status[0]}>
                                                {status[1]}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Col>
                            </Row>

                            <Spacer size={1} />
                            <Row>
                                <Col span={8}>
                                    <Typography.Text strong>Zugewiesen an:</Typography.Text>
                                </Col>
                                <Col span={16}>
                                    {usersError && <Alert type="error" message="Es konnte keine Liste von Benutzern vom Server geladen werden." />}
                                    {!usersError && !usersData && <ActivityIndicator />}
                                    {!usersError && usersData && (
                                        <Select
                                            defaultValue={data[0].assignee_name ? data[0].assignee_name : 'Nicht zugewiesen'}
                                            onChange={(value: string) => patchValue('task_assignee__user_id', value)}
                                            style={{
                                                width: '100%',
                                                backgroundColor: colors.ambientGreyLight,
                                                borderRadius: 4,
                                                borderColor: colors.ambientGrey,
                                                borderWidth: 1,
                                                borderStyle: 'solid',
                                            }}
                                            bordered={false}
                                            showArrow={true}
                                            size="small"
                                        >
                                            {usersData.map((user: any) => (
                                                <Select.Option title={user.user_name} value={user.user_id} key={user.user_id}>
                                                    {user.user_name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    )}
                                </Col>
                            </Row>
                            <Spacer size={1} />
                            <Row>
                                <Col span={8}>
                                    <Typography.Text strong>Priorität:</Typography.Text>
                                </Col>
                                <Col span={16}>
                                    <Select
                                        defaultValue={TaskSeverityLexicon[data[0].task_severity]}
                                        onChange={(value: string) => patchValue('task_severity', value)}
                                        style={{
                                            width: '100%',
                                            backgroundColor: colors.ambientGreyLight,
                                            borderRadius: 4,
                                            borderColor: colors.ambientGrey,
                                            borderWidth: 1,
                                            borderStyle: 'solid',
                                        }}
                                        bordered={false}
                                        showArrow={true}
                                        size="small"
                                    >
                                        {taskSeverityArray.map((severity) => (
                                            <Select.Option value={severity[0]} key={severity[0]}>
                                                {severity[1]}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Col>
                            </Row>
                            <Spacer size={1} />
                            <Row>
                                <Col span={8}>
                                    <Typography.Text strong>Größe:</Typography.Text>
                                </Col>
                                <Col span={16}>
                                    <Select
                                        defaultValue={TaskSizeLexicon[data[0].task_size]}
                                        onChange={(value: string) => patchValue('task_size', value)}
                                        style={{
                                            width: '100%',
                                            backgroundColor: colors.ambientGreyLight,
                                            borderRadius: 4,
                                            borderColor: colors.ambientGrey,
                                            borderWidth: 1,
                                            borderStyle: 'solid',
                                        }}
                                        bordered={false}
                                        showArrow={true}
                                        size="small"
                                    >
                                        {taskSizeArray.map((size) => (
                                            <Select.Option value={size[0]} key={size[0]}>
                                                {size[1]}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Col>
                            </Row>
                            <Spacer size={1} />
                            <Row>
                                <Col span={8}>
                                    <Typography.Text strong>Verlinktes Dokument:</Typography.Text>
                                </Col>
                                <Col span={16}>
                                    <Typography.Text>{data[0].document_originalname}</Typography.Text>
                                </Col>
                            </Row>
                            <Spacer size={1} />
                            <Row>
                                <Col span={24}>
                                    <Typography.Text disabled></Typography.Text>
                                </Col>
                            </Row>
                            <Spacer size={1} />
                            <Row>
                                <Col span={24}>
                                    <Popover trigger="hover" content={`${format(new Date(data[0].task_timestamp), 'dd.MM.yyyy - HH:mm:ss')} Uhr`}>
                                        <Typography.Text disabled>
                                            Erstellt von {data[0].author_name}{' '}
                                            {formatDistance(new Date(data[0].task_timestamp), new Date(Date.now()), {
                                                addSuffix: true,
                                                locale: de,
                                            })}
                                        </Typography.Text>
                                    </Popover>
                                </Col>
                            </Row>

                            {/* UPLOAD */}
                            <Row>
                                <UploadTaskAttachment task_id={task_id} />
                            </Row>
                        </Col>
                    </TileRow>

                    <Spacer size={4} />
                </>
            )}
        </>
    );
};
