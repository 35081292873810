import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Divider, Typography, Button } from 'antd';

// COMPONENTS
import { ActivityIndicator } from '../components/ActivityIndicator';
import { Alert } from '../components/Alert';
import { Tile } from '../components/Tile';
import { TileRow } from '../components/TileRow';
import { DocumentTitle } from '../components/DocumentTitle';
import { DocumentPages } from './Document/DocumentPages';
import { DocumentTags } from './Document/DocumentTags';
import { AddTodo } from '../components/AddTodo';
import { Documentstatus } from './Document/Documentstatus';

// THEME
import { GUTTER } from '../theme/constants';

// HOOKS
import { useAuthRequest } from '../hooks/useAuthRequest';

// TYPES
import { documentWithIDType } from '../types/api';
import { Spacer } from '../components/Spacer';

export const Document: React.FC = () => {
    const [ModalVisibility, setModalVisibility] = useState<boolean>(false);
    const [bigThumbs, setBigThumbs] = useState(false);
    // @ts-ignore
    let { document_id } = useParams();

    const { data: documentsData, error: documentsError }: { data: documentWithIDType[]; error: any } = useAuthRequest(
        `/document?document_id=${document_id}`,
        0,
    );

    if (documentsError) {
        return <Alert type="error" message="Es konnte keine Liste von Dokumenten geladen werden." />;
    }

    if (!documentsData) {
        return <ActivityIndicator />;
    }

    return (
        <>
            {documentsData.length &&
                documentsData.map((document: documentWithIDType) => (
                    <React.Fragment key={document.document_id}>
                        <TileRow>
                            <Tile span={24}>
                                <DocumentTitle document_id={document.document_id} />
                                <Typography.Title level={2}>{document.document_originalname}</Typography.Title>
                            </Tile>
                        </TileRow>

                        {/* TAGS / TODOS */}
                        <TileRow marginTop={GUTTER / 4}>
                            <Tile span={16} paddingHorizontal={GUTTER / 4}>
                                <Divider orientation="left" style={{ marginTop: 0 }}>
                                    Dokument vertaggen
                                </Divider>
                                <DocumentTags id={document.document_id} />
                            </Tile>
                            <Tile span={8} paddingHorizontal={GUTTER / 4}>
                                <Divider orientation="left" style={{ marginTop: 0 }}>
                                    Dokument zuweisen
                                </Divider>
                                {document.todo_created_at ? (
                                    <>
                                        <Link to={`/todo/${document.todo_id}`}>
                                            <Button style={{ width: '100%' }}>Todo bearbeiten / reaktivieren</Button>
                                        </Link>
                                    </>
                                ) : (
                                    <>
                                        <Button type="primary" onClick={() => setModalVisibility(true)} style={{ width: '100%' }}>
                                            Todo erzeugen
                                        </Button>
                                        <AddTodo visible={ModalVisibility} setVisible={setModalVisibility} document_id={document.document_id} />
                                    </>
                                )}
                            </Tile>
                        </TileRow>

                        <Spacer size={6} />

                        {/* STEPS */}
                        <TileRow>
                            <Tile>
                                <Documentstatus document_id={document.document_id} />
                            </Tile>
                        </TileRow>

                        {/* Imagepreview size */}
                        <TileRow>
                            <Tile>
                                <div style={{ textAlign: 'right' }}>
                                    <Button onClick={() => setBigThumbs(!bigThumbs)}>
                                        {bigThumbs ? 'Vorschaubilder verkleinern' : 'Vorschaubilder vergrößern'}
                                    </Button>
                                </div>
                            </Tile>
                        </TileRow>
                        <TileRow>
                            <Tile span={24}>
                                <DocumentPages
                                    document_id={document.document_id}
                                    document_pagecount={document.document_pagecount}
                                    finished_textract_pages={document.finished_textract_pages}
                                    pending_textract_pages={document.pending_textract_pages}
                                    bigThumbs={bigThumbs}
                                    documentstatus_step={document.documentstatus_step}
                                />
                                {/* Document Step beachten !? */}
                                {/* {document.document_step && document.document_step < 79 ? (
                                    <>
                                        <Alert
                                            message={`Mit Klick auf eine der Seiten wechselt der Status des Dokumentes auf "In Arbeit"`}
                                            type="info"
                                        />
                                        <DocumentPages document={document} setStep={75} />
                                    </>
                                ) : (
                                    <DocumentPages document={document} />
                                )} */}
                            </Tile>
                        </TileRow>
                    </React.Fragment>
                ))}
        </>
    );
};
