import React from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { Menu } from 'antd';
import { user } from '../utils/user';
import { MyIcons } from '../theme/MyIcons';
import colors from '../theme/colors';

export const MainMenue: React.FC = () => {
    let location = useLocation();
    // @ts-ignore
    const userscope = user().info?.scope || [];
    // @ts-ignore
    const usershortid = user().info?.shortid || '';

    return (
        <Menu theme="dark" mode="horizontal" defaultSelectedKeys={[location.pathname]} style={{ lineHeight: '64px' }}>
            <Menu.Item key="/">
                <Link to="/">
                    <MyIcons
                        name="DocumentSelect"
                        color={colors.ambientWhite}
                        style={{
                            justifyContent: 'center',
                            height: '64px',
                        }}
                        text="Übersicht"
                    />
                </Link>
            </Menu.Item>
            <Menu.Item key="/documents">
                <Link to="/documents">
                    <MyIcons
                        name="Mailbox"
                        color={colors.ambientWhite}
                        style={{
                            justifyContent: 'center',
                            height: '64px',
                        }}
                        text="Dokumente"
                    />
                </Link>
            </Menu.Item>
            <Menu.Item key="/tasks">
                <Link to="/tasks">
                    <MyIcons
                        name="TodoList"
                        color={colors.ambientWhite}
                        style={{
                            justifyContent: 'center',
                            height: '64px',
                        }}
                        text="Tasks"
                    />
                </Link>
            </Menu.Item>
            {userscope.includes('hocr:admin') && (
                <>
                    <Menu.Item key="/todos">
                        <Link to="/todos">
                            <MyIcons
                                name="TodoList"
                                color={colors.ambientWhite}
                                style={{
                                    justifyContent: 'center',
                                    height: '64px',
                                }}
                                text="Todos"
                            />
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="/customer-and-broker">
                        <Link to="/customer-and-broker">
                            <MyIcons
                                name="CustomerBroker"
                                color={colors.ambientWhite}
                                style={{
                                    justifyContent: 'center',
                                    height: '64px',
                                }}
                                text="Customer &amp; Broker"
                            />
                        </Link>
                    </Menu.Item>
                </>
            )}
            {/* {usershortid === 'UD0000' && (
                <>
                    <Menu.Item key="/tasks">
                        <Link to="/tasks">
                            <MyIcons
                                name="TodoList"
                                color={colors.ambientWhite}
                                style={{
                                    justifyContent: 'center',
                                    height: '64px',
                                }}
                                text="Tasks"
                            />
                        </Link>
                    </Menu.Item>
                </>
            )} */}
            {process.env.REACT_APP_ENV === 'DEV' && userscope.includes('hocr:superadmin') && (
                <Menu.Item key="/calendar">
                    <Link to="/calendar">
                        <MyIcons
                            name="TimerFast"
                            color={colors.ambientWhite}
                            style={{
                                justifyContent: 'center',
                                height: '64px',
                            }}
                            text="Kalender und Ressourcen"
                        />
                    </Link>
                </Menu.Item>
            )}
            {userscope.includes('hocr:admin') && (
                <Menu.Item key="/usermanagement">
                    <Link to="/usermanagement">
                        <MyIcons
                            name="UserManagement"
                            color={colors.ambientWhite}
                            style={{
                                justifyContent: 'center',
                                height: '64px',
                            }}
                            text="User Management"
                        />
                    </Link>
                </Menu.Item>
            )}
        </Menu>
    );
};
