import React from 'react';
import { Divider, Typography } from 'antd';

// THEME
import colors from '../../theme/colors';

// Types
import { TaskSeverity } from '../../types/api';
import { GUTTER } from '../../theme/constants';

interface Props {
    severity: TaskSeverity;
}

export const Severity: React.FC<Props> = ({ severity }) => {
    let bgcolor = colors.primaryBlue;
    switch (severity) {
        case 'low':
            bgcolor = colors.secondaryBlue;
            break;
        case 'high':
            bgcolor = colors.error;
            break;
    }

    return (
        <>
            <div style={{ backgroundColor: bgcolor, width: 20, height: 20, borderRadius: 3, marginRight: GUTTER }} />
        </>
    );
};
