import React from 'react';
import { Button } from 'antd';
import useAuthentication from '../hooks/useAuthentication';
import { user } from '../utils/user';
import { MyIcons } from '../theme/MyIcons';
import { GUTTER } from '../theme/constants';

export const SignOutButton: React.FC = () => {
    // @ts-ignore
    const username = user().info?.name || '';
    const { handleSingOut } = useAuthentication();
    return (
        <Button ghost onClick={() => handleSingOut()} style={{ display: 'flex' }}>
            <MyIcons name="UserProfile" color="white" style={{ marginRight: GUTTER }} /> {username} ausloggen
        </Button>
    );
};
