import React, { useEffect } from 'react';
import { Row, Col } from 'antd';

// COMPONENTS
import { Alert } from '../../components/Alert';
import { TextractStatusBar } from './TextractStatusBar';

// THEME
import { GUTTER } from '../../theme/constants';
import colors from '../../theme/colors';
import { MyIcons } from '../../theme/MyIcons';

// SERVICES
import { socket } from '../../services/socket';
import { invalidateSWR } from '../../utils/invalidateSWR';

// TYPES
import { TextractStatusType } from '../../types/textract';
import { Link } from 'react-router-dom';
import { apiPost } from '../../services/api';

interface Props {
    document_id: string;
    document_pagecount: number;
    finished_textract_pages: Nullable<string>;
    pending_textract_pages: Nullable<string>;
    bigThumbs: Nullable<boolean>;
    documentstatus_step: Nullable<number>;
}

export const DocumentPages: React.FC<Props> = ({
    document_id,
    document_pagecount,
    finished_textract_pages,
    pending_textract_pages,
    bigThumbs = false,
    documentstatus_step = 25,
}) => {
    // LISTEN ON SOCKET MESSAGE TO RELOAD DATA
    useEffect(() => {
        socket.on('SOCKET_MESSAGE_TEXTRACT_PENDING', (data: any) => {
            console.log('GOT SOCKET MESSAGE: TEXTRACT PENDING', document_id);
            invalidateSWR(`/document?document_id=${document_id}`);
        });
        socket.on('SOCKET_MESSAGE_TEXTRACT_FINISHED', (data: any) => {
            console.log('GOT SOCKET MESSAGE: TEXTRACT FINISHED', document_id);
            invalidateSWR(`/document?document_id=${document_id}`);
        });
    }, [document_id]);

    if (![document_id, document_pagecount].every(Boolean)) {
        return <Alert title="Keine Seiten gefunden" message="Es fehlen wichtige Informationen, um Dokumentenseiten anzeigen zu können." />;
    }

    // Generate Array with finished or pending Textract pages
    const textract_finished_stringset = finished_textract_pages ? new Set(finished_textract_pages.split(',')) : [];
    const textract_pending_stringset = pending_textract_pages ? new Set(pending_textract_pages.split(',')) : [];

    const textract_finished = Array.from(textract_finished_stringset).map((el) => parseInt(el, 10));
    const textract_pending = Array.from(textract_pending_stringset).map((el) => parseInt(el, 10));

    const getTextractStatus = (index: number): TextractStatusType => {
        if (textract_finished.includes(index)) return 'finished';
        if (textract_pending.includes(index)) return 'pending';
        else return null;
    };

    const updateStatusToTodo = () => {
        apiPost('/documentstatus', { document_id, documentstatus_step: 75 })
            .then((res) => {
                console.log(res);
                invalidateSWR('/documentstatus', { document_id });
            })
            .catch((err) => console.error(err));
    };

    return (
        <>
            {documentstatus_step && documentstatus_step < 75 && finished_textract_pages && (
                <Row gutter={GUTTER}>
                    <Col span={24} style={{ marginBottom: GUTTER * 2 }}>
                        <Alert
                            type="info"
                            message={`Der Status des Dokumentes wird auf „in Arbeit“ gesetzt, sobald in die Texterkennungs-Ansicht gewechselt wird.`}
                        />
                    </Col>
                </Row>
            )}
            <Row gutter={GUTTER}>
                {[...Array(document_pagecount)].map((page, index) => (
                    <Col
                        key={`${document_id}__${index + 1}`}
                        span={4}
                        xs={24}
                        sm={bigThumbs ? 24 : 8}
                        lg={bigThumbs ? 24 : 4}
                        style={{
                            padding: GUTTER / 2,
                        }}
                    >
                        <div
                            style={{
                                borderStyle: 'solid',
                                borderWidth: 2,
                                borderBottomWidth: 0,
                                borderColor: colors.primaryBlue,
                                position: 'relative',
                            }}
                        >
                            <div
                                style={{
                                    position: 'absolute',
                                    right: 3,
                                    bottom: 3,
                                    paddingLeft: '3px',
                                    paddingRight: '3px',
                                    backgroundColor: 'rgba(0,0,0,0.3)',
                                    borderRadius: 4,
                                }}
                            >
                                <a
                                    href={`${process.env.REACT_APP_IMAGESERVER}/?url=${process.env.REACT_APP_S3_BUCKET_URL}/pdf/${document_id}.pdf&w=1600&page=${index}`}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    <MyIcons
                                        name="SimpleLoupe"
                                        color={colors.ambientWhite}
                                        size={24}
                                        style={{
                                            height: '24px',
                                        }}
                                    />
                                </a>
                            </div>
                            <div
                                style={{
                                    position: 'absolute',
                                    left: 0,
                                    top: 0,
                                    paddingLeft: '3px',
                                    paddingRight: '3px',
                                    backgroundColor: colors.primaryBlue,
                                    color: colors.ambientWhite,
                                }}
                            >
                                {index + 1}/{document_pagecount}
                            </div>

                            {getTextractStatus(index + 1) === 'finished' ? (
                                <Link
                                    target="_blank"
                                    to={{
                                        pathname: '/textract',
                                        search: `?document_id=${document_id}&page=${index}`,
                                    }}
                                    onClick={documentstatus_step && documentstatus_step < 75 ? () => updateStatusToTodo() : undefined}
                                >
                                    <img
                                        src={`${process.env.REACT_APP_IMAGESERVER}/?url=${
                                            process.env.REACT_APP_S3_BUCKET_URL
                                        }/pdf/${document_id}.pdf&w=${bigThumbs ? 1600 : 350}&page=${index}`}
                                        alt={`${document_id}`}
                                        style={{
                                            height: 'auto',
                                            width: '100%',
                                        }}
                                    />
                                </Link>
                            ) : (
                                <img
                                    src={`${process.env.REACT_APP_IMAGESERVER}/?url=${process.env.REACT_APP_S3_BUCKET_URL}/pdf/${document_id}.pdf&w=${
                                        bigThumbs ? 1600 : 350
                                    }&page=${index}`}
                                    alt={`${document_id}`}
                                    style={{
                                        height: 'auto',
                                        width: '100%',
                                    }}
                                />
                            )}
                        </div>
                        <div
                            style={{
                                height: 1,
                                width: '100%',
                                backgroundColor: colors.ambientGrey,
                                borderStyle: 'solid',
                                borderWidth: 0,
                                borderLeftWidth: 2,
                                borderRightWidth: 2,
                                borderColor: colors.primaryBlue,
                            }}
                        />
                        <TextractStatusBar
                            document_id={document_id}
                            table_s3_key={'Schnitzelbrötchen'} // TODO: FIX ME -> page.textract.tables
                            page_number={index + 1}
                            status={getTextractStatus(index + 1)}
                            // status={
                            //     page.textract.getDocumentAnalysis.status === 'ok'
                            //         ? 'finished'
                            //         : page.textract.startDocumentAnalysis.status === 'ok'
                            //         ? 'pending'
                            //         : null
                            // }
                            table_count={1} // TODO: FIX ME
                            // table_count={page.textract.table_count}
                        />
                    </Col>
                ))}
            </Row>
        </>
    );
};
