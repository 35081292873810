import React, { useState, useEffect } from 'react';
// @ts-ignore
import { Select } from 'antd';
import { ActivityIndicator } from '../../components/ActivityIndicator';
import { Alert } from '../../components/Alert';

// HOOKS
import { useAuthRequest } from '../../hooks/useAuthRequest';

// UTILS
import { invalidateSWR } from '../../utils/invalidateSWR';

// SERVICES
import { apiPost, apiDelete } from '../../services/api';
import { socket } from '../../services/socket';

// TYPES
import { ObjectWithKeys } from '../../types/objectWithKeys';
import { TagType } from '../../types/api';

interface Props {
    id: string;
}

interface DocumentTagType {
    fk_tag_name: string;
}

type handleChangeType = 'deselect' | 'select';

const { Option } = Select;

export const DocumentTags: React.FC<Props> = ({ id }) => {
    const [error, setError] = useState<string | undefined>(undefined);
    const params: ObjectWithKeys = {
        document_id: id || 'null',
    };
    const { data: documentTagData, error: documentTagError }: { data: DocumentTagType[]; error: any } = useAuthRequest('/document/tag', 0, params);
    const { data: tagData, error: tagError }: { data: TagType[]; error: any } = useAuthRequest('/tag');

    // SOCKET_MESSAGE_UPDATE_DOCUMENTS_TAGS
    useEffect(() => {
        socket.on('SOCKET_MESSAGE_UPDATE_DOCUMENTS_TAGS', (data: any) => {
            invalidateSWR('/document/tag', params);
        });
    }, [params]);

    const handleSearch = (length: number) => {
        if (error && length > 3) {
            setError(undefined);
        }
    };

    const handleChange = (tag: string, type: handleChangeType) => {
        if (tag.replace(/\s/g, '').length < 4) {
            setError(undefined);
        }

        const allTagNames = tagData.map((tag) => tag.tag_name);
        const preExisting = allTagNames.includes(tag.replace(/\s/g, ''));

        const params = {
            document_id: id || 'null',
            tag_name: tag.replace(/\s/g, '') || 'null',
        };

        if (type === 'select') {
            apiPost('/document/tag', params)
                .then((res) => {
                    console.log('Dokument und Tag verknüpft', res);
                })
                .catch((err) => console.error(err));
        } else {
            if (preExisting) {
                apiDelete('/document/tag', params)
                    .then((res) => {
                        console.log('Verknüpfung zwischen Dokument und Tag aufgelöst', res);
                    })
                    .catch((err) => console.error(err));
            } else {
                console.log('Tag entfernt, der noch nicht in der Datenbank bestand.');
            }
        }
    };

    if (documentTagError || tagError) {
        return <Alert type="error" message="Für das Dokument konnten keine Tags geladen werden." />;
    }

    if (!documentTagData || !tagData) {
        return <ActivityIndicator />;
    }

    return (
        <>
            {error && <Alert type="error" message="Ein Tag muss mindestens 3 Zeichen lang sein und darf keine Leerzeichen enthalten." />}
            <Select
                mode="tags"
                placeholder="Wähle Tags aus"
                defaultValue={documentTagData.length ? documentTagData.map((documentTags) => documentTags.fk_tag_name) : undefined}
                onSelect={(tagname: string) => handleChange(tagname, 'select')}
                onDeselect={(tagname: string) => handleChange(tagname, 'deselect')}
                onSearch={(term: string) => handleSearch(term.replace(/\s/g, '').length)}
                // onChange={onTagsChange}
                style={{ width: '100%' }}
            >
                {tagData.map((tag: TagType) => (
                    <Option value={tag.tag_name} key={tag.tag_name}>
                        {tag.tag_name}
                    </Option>
                ))}
            </Select>
        </>
    );
};
