import React from 'react';
import { Typography, Form, Input, Button } from 'antd';
import { useHistory } from 'react-router-dom';
import useAuthentication from '../hooks/useAuthentication';
import useAuthStatus from '../hooks/useAuthStatus';

const { Title } = Typography;

const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 12 },
};
const tailLayout = {
    wrapperCol: { offset: 6, span: 12 },
};

export const Signin: React.FC = () => {
    const { handleSignin } = useAuthentication();
    const isAuthenticated = useAuthStatus();
    let history = useHistory();

    if (isAuthenticated) {
        history.push('/');
    }

    const onFinish = (values: any) => {
        if (values.username !== '' && values.password !== '') {
            handleSignin({
                name: values.username,
                password: values.password,
            });
        }
    };

    const onFinishFailed = (error: any) => {
        console.error(error);
    };

    return (
        <>
            <Title style={{ textAlign: 'center', marginTop: '100px', marginBottom: '30px' }}>Einloggen </Title>
            <Form {...layout} name="basic" initialValues={{ remember: true }} onFinish={onFinish} onFinishFailed={onFinishFailed}>
                <Form.Item label="Benutzer" name="username" rules={[{ required: true, message: 'Bitte geben Sie einen Benutzernamen an!' }]}>
                    <Input />
                </Form.Item>

                <Form.Item label="Passwort" name="password" rules={[{ required: true, message: 'Bitte geben Sie ein Passwort an!' }]}>
                    <Input.Password />
                </Form.Item>

                <Form.Item {...tailLayout} style={{ textAlign: 'center' }}>
                    <Button type="primary" htmlType="submit">
                        Absenden
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
};
