import React, { useState } from 'react';
import { Upload, message } from 'antd';
import { InboxOutlined } from '@ant-design/icons';

// COMPONENTS
import { TileRow } from '../../components/TileRow';

// THEME

// HOOKS

// UTILS
import { user } from '../../utils/user';
import colors from '../../theme/colors';

// TYPES

// DECONSTRUCt
const { Dragger } = Upload;

interface Props {
    task_id: string;
}

export const UploadTaskAttachment: React.FC<Props> = ({ task_id }) => {
    const token = user().token || '';

    if (token === '') return null;

    const params = {
        task_id,
    };

    return (
        <>
            <TileRow>
                <Dragger
                    name="taskattachment"
                    multiple={true}
                    action={`${process.env.REACT_APP_API_ENDPOINT}/task_attachment`}
                    accept={
                        'application/pdf,image/png,image/jpeg,application/msexcel,application/msword,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/mspowerpoint,'
                    }
                    headers={{ Authorization: token }}
                    data={params}
                    onChange={(info: any) => {
                        const { status } = info.file;
                        if (status !== 'uploading') {
                            console.log(info.file, info.fileList);
                        }
                        if (status === 'done') {
                            message.success(`${info.file.name} - Die Datei wurde erfolgreich hochgeladen.`);
                        } else if (status === 'error') {
                            message.error(`${info.file.name} - Fehler beim Upload der Datei.`);
                        }
                    }}
                >
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined style={{ color: colors.primaryBlue }} />
                    </p>
                    <p className="ant-upload-text">PDF, JPEG, PNG, EXCEL, WORD.</p>
                    <p className="ant-upload-hint">Einzelne Datei hochladen</p>
                </Dragger>
            </TileRow>
        </>
    );
};
