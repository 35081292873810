import React from 'react';

interface Props {
    size?: number;
    strokeWidth?: number;
    color?: string;
}

export const CheckboxUnchecked: React.FC<Props> = ({ size = 24, strokeWidth = 1.5, color }) => (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
        <path
            d="M11 20.504C16.2489 20.504 20.504 16.2489 20.504 11.0001C20.504 5.75116 16.2489 1.49609 11 1.49609C5.7511 1.49609 1.49603 5.75116 1.49603 11.0001C1.49603 16.2489 5.7511 20.504 11 20.504Z"
            fillRule="evenodd"
            clipRule="evenodd"
            stroke={color}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
export const CheckboxChecked: React.FC<Props> = ({ size = 24, strokeWidth = 1.5, color }) => (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
        <path d="M19.4671 6.68604L12 14.1261L8.96173 11.0879" stroke={color} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round" />
        <path
            d="M20.2875 10.1165C21.1954 14.1092 19.1291 18.182 15.3708 19.8072C11.6126 21.4324 7.22978 20.1485 4.94251 16.7523C2.65524 13.3561 3.11308 8.81214 6.03189 5.9405C8.95069 3.06886 13.5015 2.6851 16.86 5.02739"
            stroke={color}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
