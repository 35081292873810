import React from 'react';

interface Props {
    size?: number;
    strokeWidth?: number;
    color?: string;
}

export const Shirt: React.FC<Props> = ({ size = 24, strokeWidth = 1.5, color }) => (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
        <path
            stroke={color}
            strokeWidth={strokeWidth}
            d="M17.99 11v8.546c.01.899-.588 1.69-1.454 1.927a19.747 19.747 0 0 1-9.097 0 1.974 1.974 0 0 1-1.454-1.927V11"
        />
        <path
            stroke={color}
            strokeWidth={strokeWidth}
            d="M18.006 13l3.227-.76a.999.999 0 0 0 .733-1.248l-1.214-4.248a3.995 3.995 0 0 0-2.144-2.52l-1.797-.847a4.014 4.014 0 0 0-1.704-.38H8.893c-.59 0-1.171.13-1.704.38l-1.797.846a4.002 4.002 0 0 0-2.144 2.521l-1.213 4.248a.995.995 0 0 0 .099.78.993.993 0 0 0 .633.468l3.227.76"
        />
        <path stroke={color} strokeWidth={strokeWidth} d="M15.502 3.016a2.982 2.982 0 0 1-2.982 2.981h-1.04a2.98 2.98 0 0 1-2.981-2.981" />
    </svg>
);

export const TaskSeverityHighest: React.FC<Props> = ({ size = 24, strokeWidth = 1.5, color }) => (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
        <path stroke={color} strokeWidth={strokeWidth} d="M16 10.5l-4-4-4 4m9 7l-5-5-5 5" />
    </svg>
);

export const TaskSeverityHigh: React.FC<Props> = ({ size = 24, strokeWidth = 1.5, color }) => (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
        <path stroke={color} strokeWidth={strokeWidth} d="M17 14l-5-5-5 5" />
    </svg>
);

export const TaskSeverityLowest: React.FC<Props> = ({ size = 24, strokeWidth = 1.5, color }) => (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
        <path stroke={color} strokeWidth={strokeWidth} d="M8 13.5l4 4 4-4m-9-7l5 5 5-5" />
    </svg>
);

export const TaskSeverityLow: React.FC<Props> = ({ size = 24, strokeWidth = 1.5, color }) => (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
        <path stroke={color} strokeWidth={strokeWidth} d="M7 10l5 5 5-5" />
    </svg>
);
