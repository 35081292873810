import React, { useState } from 'react';
import { Typography, Row, Button, Popconfirm } from 'antd';
import { Link } from 'react-router-dom';

// COMPONENTS
import { ActivityIndicator } from '../../components/ActivityIndicator';
import { Alert } from '../../components/Alert';
import { Tile } from '../../components/Tile';
import { TileRow } from '../../components/TileRow';

// THEME
import { GUTTER } from '../../theme/constants';

// HOOKS
import { useAuthRequest } from '../../hooks/useAuthRequest';

// TYPES
import { ApiGetTaskrelation } from '../../types/api';
import colors from '../../theme/colors';
import { apiDelete } from '../../services/api';
import { invalidateSWR } from '../../utils/invalidateSWR';

import { LinkTasks } from './LinkTasks';

interface Props {
    task_id: number;
}

const { Text } = Typography;

export const Taskrelations: React.FC<Props> = ({ task_id }) => {
    const [ModalVisibility, setModalVisibility] = useState<boolean>(false);
    const { data, error }: { data: ApiGetTaskrelation[]; error: any } = useAuthRequest(`/task_task?task_id=${task_id}`, 0);

    if (error) {
        return <Alert type="error" message="Es konnten keine verknüpften Tasks geladen werden." />;
    }

    if (!data) {
        return <ActivityIndicator />;
    }

    return (
        <>
            <TileRow>
                <Button type="primary" onClick={() => setModalVisibility(true)}>
                    Neue Verknüpfung hinzufügen
                </Button>
                <LinkTasks visible={ModalVisibility} setVisibility={setModalVisibility} task_id={task_id} />
            </TileRow>
            <TileRow>
                <Tile>
                    {data.map((val: ApiGetTaskrelation) => {
                        const title = val.task_task__task_id1 === task_id ? val.task2_title : val.task1_title;
                        const linkId = val.task_task__task_id1 === task_id ? val.task_task__task_id2 : val.task_task__task_id1;

                        return (
                            <Row key={val.task_task_id} gutter={GUTTER}>
                                <Link to={`/task/${linkId}`}>
                                    <Text underline style={{ color: colors.primaryBlue }}>
                                        VARA-{linkId}
                                    </Text>
                                </Link>
                                <Text style={{ color: colors.primaryBlue, paddingLeft: 10 }}>({title})</Text>
                                <Popconfirm
                                    placement="top"
                                    title="Soll Verknüpfung aufgehoben werden?"
                                    onConfirm={() => {
                                        apiDelete(
                                            '/task_task',
                                            {
                                                task_task_id: val.task_task_id,
                                            },
                                            true,
                                        )
                                            .then((res) => {
                                                invalidateSWR(`/task_task?task_id=${task_id}`);
                                                console.log(res);
                                            })
                                            .catch((err) => console.error(err));
                                    }}
                                    okText="Ja"
                                    cancelText="Nein"
                                >
                                    <Text style={{ color: colors.secondaryBlue, paddingLeft: 30, cursor: 'pointer' }}>Verknüpfung löschen</Text>
                                </Popconfirm>
                            </Row>
                        );
                    })}
                </Tile>
            </TileRow>
        </>
    );
};
