import React from 'react';
import { Typography } from 'antd';
// Only for MyIcons Package
// Copy paste from FIGMA
// replace:
// stroke="#323232"  ==>  stroke={color}
// stroke-width="1.5"  ==> strokeWidth={strokeWidth}

import { UserProfile, UserProfile2 as UserManagement } from './User/UserProfile';
import { Home } from './Interface/Home';
import { TodoList } from './Interface/TodoList';
import { DocumentSelect } from './Interface/DocumentSelect';
import { CheckboxUnchecked, CheckboxChecked } from './Interface/Checkbox';
import { WarningCheckmark } from './Interface/WarningCheckmark';
import { TimerFast } from './Interface/TimerFast';
import { CircleArrowUp, CircleArrowRight, CircleArrowDown, CircleArrowLeft } from './Interface/CircleArrow';
import { UserAccountManager, UserAdmin, UserRead } from './User/User';
import { UserManagementAdd, UserManagementChangeScope, UserManagementEdit, UserManagementRemove } from './User/UserManagement';
import { Edit, Zoom, SimpleLoupe, Mailbox, CustomerBroker } from './InterfaceEssential';
import { FileText } from './Files/Text';
import { DocumentHasTag, DocumentHasTodo } from './Files/DocumentHas';
import { DocumentActionDelete } from './Files/DocumentAction';
import { Shirt, TaskSeverityHighest, TaskSeverityHigh, TaskSeverityLowest, TaskSeverityLow } from './Tasks/Tasks';
import colors from '../colors';

export type IconType =
    | 'UserProfile'
    | 'UserManagement'
    | 'Home'
    | 'TodoList'
    | 'DocumentSelect'
    | 'CheckboxChecked'
    | 'CheckboxUnchecked'
    | 'UserAccountManager'
    | 'UserAdmin'
    | 'UserRead'
    | 'UserManagementAdd'
    | 'UserManagementChangeScope'
    | 'UserManagementEdit'
    | 'UserManagementRemove'
    | 'Edit'
    | 'WarningCheckmark'
    | 'TimerFast'
    | 'CircleArrowUp'
    | 'CircleArrowRight'
    | 'CircleArrowDown'
    | 'CircleArrowLeft'
    | 'FileText'
    | 'DocumentHasTag'
    | 'DocumentHasTodo'
    | 'DocumentActionDelete'
    | 'Zoom'
    | 'SimpleLoupe'
    | 'Mailbox'
    | 'CustomerBroker'
    | 'Shirt'
    | 'TaskSeverityHighest'
    | 'TaskSeverityHigh'
    | 'TaskSeverityLow'
    | 'TaskSeverityLowest';

interface Props {
    name: IconType;
    size?: number;
    width?: number;
    color?: string;
    style?: React.CSSProperties | undefined;
    text?: string;
    textBeforeIcon?: boolean;
}

interface TextProps {
    size?: number;
    color?: string;
    text: string;
    beforeIcon: boolean;
}

const Text: React.FC<TextProps> = ({ size = 24, color = colors.primaryBlue, text, beforeIcon = false }) => (
    <Typography.Text
        style={{
            lineHeight: `${size}px`,
            color,
            marginLeft: !beforeIcon ? `${size / 3}px` : '0px',
            marginRight: beforeIcon ? `${size / 3}px` : '0px',
        }}
    >
        {text}
    </Typography.Text>
);

export const MyIcons: React.FC<Props> = ({
    name = 'QuestionSquare',
    size = 24,
    width = 1.5,
    color = colors.primaryBlue,
    style,
    text,
    textBeforeIcon = false,
}) => {
    const iconWrapper = {
        height: `${size}px`,
        display: 'flex',
        alignItems: 'center',
    };
    switch (name) {
        case 'UserProfile':
            return (
                <div style={Object.assign(iconWrapper, style)}>
                    {text && textBeforeIcon && <Text size={size} color={color} text={text} beforeIcon={textBeforeIcon} />}
                    <UserProfile size={size} strokeWidth={width} color={color} />
                    {text && !textBeforeIcon && <Text size={size} color={color} text={text} beforeIcon={textBeforeIcon} />}
                </div>
            );
        case 'UserManagement':
            return (
                <div style={Object.assign(iconWrapper, style)}>
                    {text && textBeforeIcon && <Text size={size} color={color} text={text} beforeIcon={textBeforeIcon} />}
                    <UserManagement size={size} strokeWidth={width} color={color} />
                    {text && !textBeforeIcon && <Text size={size} color={color} text={text} beforeIcon={textBeforeIcon} />}
                </div>
            );
        case 'Home':
            return (
                <div style={Object.assign(iconWrapper, style)}>
                    {text && textBeforeIcon && <Text size={size} color={color} text={text} beforeIcon={textBeforeIcon} />}
                    <Home size={size} strokeWidth={width} color={color} />
                    {text && !textBeforeIcon && <Text size={size} color={color} text={text} beforeIcon={textBeforeIcon} />}
                </div>
            );
        case 'TodoList':
            return (
                <div style={Object.assign(iconWrapper, style)}>
                    {text && textBeforeIcon && <Text size={size} color={color} text={text} beforeIcon={textBeforeIcon} />}
                    <TodoList size={size} strokeWidth={width} color={color} />
                    {text && !textBeforeIcon && <Text size={size} color={color} text={text} beforeIcon={textBeforeIcon} />}
                </div>
            );
        case 'DocumentSelect':
            return (
                <div style={Object.assign(iconWrapper, style)}>
                    {text && textBeforeIcon && <Text size={size} color={color} text={text} beforeIcon={textBeforeIcon} />}
                    <DocumentSelect size={size} strokeWidth={width} color={color} />
                    {text && !textBeforeIcon && <Text size={size} color={color} text={text} beforeIcon={textBeforeIcon} />}
                </div>
            );
        case 'CheckboxChecked':
            return (
                <div style={Object.assign(iconWrapper, style)}>
                    {text && textBeforeIcon && <Text size={size} color={color} text={text} beforeIcon={textBeforeIcon} />}
                    <CheckboxChecked size={size} strokeWidth={width} color={color} />
                    {text && !textBeforeIcon && <Text size={size} color={color} text={text} beforeIcon={textBeforeIcon} />}
                </div>
            );
        case 'CheckboxUnchecked':
            return (
                <div style={Object.assign(iconWrapper, style)}>
                    {text && textBeforeIcon && <Text size={size} color={color} text={text} beforeIcon={textBeforeIcon} />}
                    <CheckboxUnchecked size={size} strokeWidth={width} color={color} />
                    {text && !textBeforeIcon && <Text size={size} color={color} text={text} beforeIcon={textBeforeIcon} />}
                </div>
            );
        case 'UserAccountManager':
            return (
                <div style={Object.assign(iconWrapper, style)}>
                    {text && textBeforeIcon && <Text size={size} color={color} text={text} beforeIcon={textBeforeIcon} />}
                    <UserAccountManager size={size} strokeWidth={width} color={color} />
                    {text && !textBeforeIcon && <Text size={size} color={color} text={text} beforeIcon={textBeforeIcon} />}
                </div>
            );
        case 'UserAdmin':
            return (
                <div style={Object.assign(iconWrapper, style)}>
                    {text && textBeforeIcon && <Text size={size} color={color} text={text} beforeIcon={textBeforeIcon} />}
                    <UserAdmin size={size} strokeWidth={width} color={color} />
                    {text && !textBeforeIcon && <Text size={size} color={color} text={text} beforeIcon={textBeforeIcon} />}
                </div>
            );
        case 'UserRead':
            return (
                <div style={Object.assign(iconWrapper, style)}>
                    {text && textBeforeIcon && <Text size={size} color={color} text={text} beforeIcon={textBeforeIcon} />}
                    <UserRead size={size} strokeWidth={width} color={color} />
                    {text && !textBeforeIcon && <Text size={size} color={color} text={text} beforeIcon={textBeforeIcon} />}
                </div>
            );
        case 'UserManagementAdd':
            return (
                <div style={Object.assign(iconWrapper, style)}>
                    {text && textBeforeIcon && <Text size={size} color={color} text={text} beforeIcon={textBeforeIcon} />}
                    <UserManagementAdd size={size} strokeWidth={width} color={color} />
                    {text && !textBeforeIcon && <Text size={size} color={color} text={text} beforeIcon={textBeforeIcon} />}
                </div>
            );
        case 'UserManagementChangeScope':
            return (
                <div style={Object.assign(iconWrapper, style)}>
                    {text && textBeforeIcon && <Text size={size} color={color} text={text} beforeIcon={textBeforeIcon} />}
                    <UserManagementChangeScope size={size} strokeWidth={width} color={color} />
                    {text && !textBeforeIcon && <Text size={size} color={color} text={text} beforeIcon={textBeforeIcon} />}
                </div>
            );
        case 'UserManagementEdit':
            return (
                <div style={Object.assign(iconWrapper, style)}>
                    {text && textBeforeIcon && <Text size={size} color={color} text={text} beforeIcon={textBeforeIcon} />}
                    <UserManagementEdit size={size} strokeWidth={width} color={color} />
                    {text && !textBeforeIcon && <Text size={size} color={color} text={text} beforeIcon={textBeforeIcon} />}
                </div>
            );
        case 'UserManagementRemove':
            return (
                <div style={Object.assign(iconWrapper, style)}>
                    {text && textBeforeIcon && <Text size={size} color={color} text={text} beforeIcon={textBeforeIcon} />}
                    <UserManagementRemove size={size} strokeWidth={width} color={color} />
                    {text && !textBeforeIcon && <Text size={size} color={color} text={text} beforeIcon={textBeforeIcon} />}
                </div>
            );
        case 'Edit':
            return (
                <div style={Object.assign(iconWrapper, style)}>
                    {text && textBeforeIcon && <Text size={size} color={color} text={text} beforeIcon={textBeforeIcon} />}
                    <Edit size={size} strokeWidth={width} color={color} />
                    {text && !textBeforeIcon && <Text size={size} color={color} text={text} beforeIcon={textBeforeIcon} />}
                </div>
            );
        case 'WarningCheckmark':
            return (
                <div style={Object.assign(iconWrapper, style)}>
                    {text && textBeforeIcon && <Text size={size} color={color} text={text} beforeIcon={textBeforeIcon} />}
                    <WarningCheckmark size={size} strokeWidth={width} color={color} />
                    {text && !textBeforeIcon && <Text size={size} color={color} text={text} beforeIcon={textBeforeIcon} />}
                </div>
            );
        case 'TimerFast':
            return (
                <div style={Object.assign(iconWrapper, style)}>
                    {text && textBeforeIcon && <Text size={size} color={color} text={text} beforeIcon={textBeforeIcon} />}
                    <TimerFast size={size} strokeWidth={width} color={color} />
                    {text && !textBeforeIcon && <Text size={size} color={color} text={text} beforeIcon={textBeforeIcon} />}
                </div>
            );
        case 'CircleArrowUp':
            return (
                <div style={Object.assign(iconWrapper, style)}>
                    {text && textBeforeIcon && <Text size={size} color={color} text={text} beforeIcon={textBeforeIcon} />}
                    <CircleArrowUp size={size} strokeWidth={width} color={color} />
                    {text && !textBeforeIcon && <Text size={size} color={color} text={text} beforeIcon={textBeforeIcon} />}
                </div>
            );
        case 'CircleArrowRight':
            return (
                <div style={Object.assign(iconWrapper, style)}>
                    {text && textBeforeIcon && <Text size={size} color={color} text={text} beforeIcon={textBeforeIcon} />}
                    <CircleArrowRight size={size} strokeWidth={width} color={color} />
                    {text && !textBeforeIcon && <Text size={size} color={color} text={text} beforeIcon={textBeforeIcon} />}
                </div>
            );
        case 'CircleArrowDown':
            return (
                <div style={Object.assign(iconWrapper, style)}>
                    {text && textBeforeIcon && <Text size={size} color={color} text={text} beforeIcon={textBeforeIcon} />}
                    <CircleArrowDown size={size} strokeWidth={width} color={color} />
                    {text && !textBeforeIcon && <Text size={size} color={color} text={text} beforeIcon={textBeforeIcon} />}
                </div>
            );
        case 'CircleArrowLeft':
            return (
                <div style={Object.assign(iconWrapper, style)}>
                    {text && textBeforeIcon && <Text size={size} color={color} text={text} beforeIcon={textBeforeIcon} />}
                    <CircleArrowLeft size={size} strokeWidth={width} color={color} />
                    {text && !textBeforeIcon && <Text size={size} color={color} text={text} beforeIcon={textBeforeIcon} />}
                </div>
            );
        case 'FileText':
            return (
                <div style={Object.assign(iconWrapper, style)}>
                    {text && textBeforeIcon && <Text size={size} color={color} text={text} beforeIcon={textBeforeIcon} />}
                    <FileText size={size} strokeWidth={width} color={color} />
                    {text && !textBeforeIcon && <Text size={size} color={color} text={text} beforeIcon={textBeforeIcon} />}
                </div>
            );
        case 'DocumentHasTag':
            return (
                <div style={Object.assign(iconWrapper, style)}>
                    {text && textBeforeIcon && <Text size={size} color={color} text={text} beforeIcon={textBeforeIcon} />}
                    <DocumentHasTag size={size} strokeWidth={width} color={color} />
                    {text && !textBeforeIcon && <Text size={size} color={color} text={text} beforeIcon={textBeforeIcon} />}
                </div>
            );
        case 'DocumentHasTodo':
            return (
                <div style={Object.assign(iconWrapper, style)}>
                    {text && textBeforeIcon && <Text size={size} color={color} text={text} beforeIcon={textBeforeIcon} />}
                    <DocumentHasTodo size={size} strokeWidth={width} color={color} />
                    {text && !textBeforeIcon && <Text size={size} color={color} text={text} beforeIcon={textBeforeIcon} />}
                </div>
            );
        case 'DocumentActionDelete':
            return (
                <div style={Object.assign(iconWrapper, style)}>
                    {text && textBeforeIcon && <Text size={size} color={color} text={text} beforeIcon={textBeforeIcon} />}
                    <DocumentActionDelete size={size} strokeWidth={width} color={color} />
                    {text && !textBeforeIcon && <Text size={size} color={color} text={text} beforeIcon={textBeforeIcon} />}
                </div>
            );
        case 'Zoom':
            return (
                <div style={Object.assign(iconWrapper, style)}>
                    {text && textBeforeIcon && <Text size={size} color={color} text={text} beforeIcon={textBeforeIcon} />}
                    <Zoom size={size} strokeWidth={width} color={color} />
                    {text && !textBeforeIcon && <Text size={size} color={color} text={text} beforeIcon={textBeforeIcon} />}
                </div>
            );
        case 'SimpleLoupe':
            return (
                <div style={Object.assign(iconWrapper, style)}>
                    {text && textBeforeIcon && <Text size={size} color={color} text={text} beforeIcon={textBeforeIcon} />}
                    <SimpleLoupe size={size} strokeWidth={width} color={color} />
                    {text && !textBeforeIcon && <Text size={size} color={color} text={text} beforeIcon={textBeforeIcon} />}
                </div>
            );
        case 'Mailbox':
            return (
                <div style={Object.assign(iconWrapper, style)}>
                    {text && textBeforeIcon && <Text size={size} color={color} text={text} beforeIcon={textBeforeIcon} />}
                    <Mailbox size={size} strokeWidth={width} color={color} />
                    {text && !textBeforeIcon && <Text size={size} color={color} text={text} beforeIcon={textBeforeIcon} />}
                </div>
            );
        case 'CustomerBroker':
            return (
                <div style={Object.assign(iconWrapper, style)}>
                    {text && textBeforeIcon && <Text size={size} color={color} text={text} beforeIcon={textBeforeIcon} />}
                    <CustomerBroker size={size} strokeWidth={width} color={color} />
                    {text && !textBeforeIcon && <Text size={size} color={color} text={text} beforeIcon={textBeforeIcon} />}
                </div>
            );
        case 'Shirt':
            return (
                <div style={Object.assign(iconWrapper, style)}>
                    {text && textBeforeIcon && <Text size={size} color={color} text={text} beforeIcon={textBeforeIcon} />}
                    <Shirt size={size} strokeWidth={width} color={color} />
                    {text && !textBeforeIcon && <Text size={size} color={color} text={text} beforeIcon={textBeforeIcon} />}
                </div>
            );
        case 'TaskSeverityHighest':
            return (
                <div style={Object.assign(iconWrapper, style)}>
                    {text && textBeforeIcon && <Text size={size} color={color} text={text} beforeIcon={textBeforeIcon} />}
                    <TaskSeverityHighest size={size} strokeWidth={width} color={color} />
                    {text && !textBeforeIcon && <Text size={size} color={color} text={text} beforeIcon={textBeforeIcon} />}
                </div>
            );
        case 'TaskSeverityHigh':
            return (
                <div style={Object.assign(iconWrapper, style)}>
                    {text && textBeforeIcon && <Text size={size} color={color} text={text} beforeIcon={textBeforeIcon} />}
                    <TaskSeverityHigh size={size} strokeWidth={width} color={color} />
                    {text && !textBeforeIcon && <Text size={size} color={color} text={text} beforeIcon={textBeforeIcon} />}
                </div>
            );
        case 'TaskSeverityLowest':
            return (
                <div style={Object.assign(iconWrapper, style)}>
                    {text && textBeforeIcon && <Text size={size} color={color} text={text} beforeIcon={textBeforeIcon} />}
                    <TaskSeverityLowest size={size} strokeWidth={width} color={color} />
                    {text && !textBeforeIcon && <Text size={size} color={color} text={text} beforeIcon={textBeforeIcon} />}
                </div>
            );
        case 'TaskSeverityLow':
            return (
                <div style={Object.assign(iconWrapper, style)}>
                    {text && textBeforeIcon && <Text size={size} color={color} text={text} beforeIcon={textBeforeIcon} />}
                    <TaskSeverityLow size={size} strokeWidth={width} color={color} />
                    {text && !textBeforeIcon && <Text size={size} color={color} text={text} beforeIcon={textBeforeIcon} />}
                </div>
            );
        default:
            return null;
    }
};
