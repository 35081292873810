import * as React from 'react';
import { Link } from 'react-router-dom';

// THEME
import colors from '../../theme/colors';

interface Props {
    document_id: string;
    document_originalname: string;
    bigThumbs: boolean;
}

export const Image: React.FC<Props> = ({ document_id, document_originalname, bigThumbs }) => {
    return (
        <Link
            to={`/document/${document_id}`}
            style={{
                alignSelf: 'center',
                backgroundColor: colors.ambientWhite,
                height: '100%',
                display: 'flex',
            }}
        >
            <img
                src={`${process.env.REACT_APP_IMAGESERVER}/?url=${process.env.REACT_APP_S3_BUCKET_URL}/pdf/${document_id}.pdf&w=${
                    bigThumbs ? 1200 : 350
                }`}
                alt={`${document_originalname}`}
                style={{
                    height: 'auto',
                    width: '100%',
                    alignSelf: 'center',
                }}
            />
        </Link>
    );
};
