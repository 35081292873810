import React, { useState, useEffect } from 'react';
import { Divider, Select, Input, Col, Row, Typography } from 'antd';
import { PlusOutlined, EditOutlined } from '@ant-design/icons';

// HOOKS
import { useAuthRequest } from '../../hooks/useAuthRequest';

// COMPONENTS
import { ActivityIndicator } from '../../components/ActivityIndicator';
import { Alert } from '../../components/Alert';

// UTILS
import { invalidateSWR } from '../../utils/invalidateSWR';
import { capitalize } from '../../utils/strings';

// SERVICE
import { apiPost, apiPatch } from '../../services/api';
import { socket } from '../../services/socket';

// TYPES
import { BrokerType, CustomerType } from '../../types/api';
import { Spacer } from '../../components/Spacer';
interface Mixedname extends BrokerType, CustomerType {}

interface Props {
    name: 'customer' | 'broker';
}

const { Option } = Select;

interface EditName {
    key: Nullable<string>;
    oldName: Nullable<string>;
    newName: Nullable<string>;
}

export const NameUpdater: React.FC<Props> = ({ name }) => {
    const Name = capitalize(name);
    const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [editValue, setEditValue] = useState<EditName>({
        key: '',
        oldName: '',
        newName: null,
    });
    const [newEntry, setNewEntry] = useState<string | null>(null);
    const [editEntry, setEditEntry] = useState<string | null>(null);
    const { data, error }: { data: Mixedname[]; error: any } = useAuthRequest(`/${name}`);

    useEffect(() => {
        socket.on('SOCKET_MESSAGE_INSERT_CUSTOMER', (data: any) => {
            invalidateSWR('/customer');
        });
        socket.on('SOCKET_MESSAGE_INSERT_BROKER', (data: any) => {
            invalidateSWR('/broker');
        });
    }, []);

    if (error) {
        return <Alert type="error" message={`Es konnte keine Liste mit ${Name} geladen werden.`} />;
    }

    if (errorMessage) {
        return (
            <Alert
                type="error"
                message={errorMessage}
                closable
                onClose={() => {
                    setErrorMessage(undefined);
                }}
            />
        );
    }

    if (successMessage) {
        return <Alert type="success" message={successMessage} closable onClose={() => setSuccessMessage(undefined)} />;
    }

    if (!data) {
        return <ActivityIndicator />;
    }

    const reset = () => {
        invalidateSWR(`/${name}`);
        setNewEntry('');
        setEditEntry('');
        setEditValue({ key: '', oldName: '', newName: null });
    };

    const handleNewName = () => {
        if (!newEntry) {
            setErrorMessage(`Bitte geben Sie einen neuen ${Name} an.`);
        }

        if (newEntry && newEntry.trim().length >= 3) {
            setErrorMessage(undefined);
            const allDbNames = data.map((mappedName) => {
                return name === 'customer' ? mappedName.customer_name : mappedName.broker_name;
            });
            const preExisting = allDbNames.includes(newEntry.trim());

            if (preExisting) {
                setErrorMessage(`${Name} bereits vorhanden`);
            } else {
                const params = {
                    name: (newEntry && newEntry.trim()) || 'null',
                };

                apiPost(`/${name}`, params)
                    .then((res) => {
                        setSuccessMessage(`Neuer ${Name} (${newEntry}) erfolgreich hinzugefügt.`);
                        reset();
                    })
                    .catch((err) => {
                        setErrorMessage(`${name} konnte nicht hinzugefügt werden: ${err}`);
                    });
            }
        } else {
            setErrorMessage(`Ein ${Name} muss mindestens aus 3 Buchstaben bestehen.`);
        }
    };

    const handleEditName = () => {
        if (!editEntry) {
            setErrorMessage(`Bitte geben Sie einen neuen ${Name} an.`);
        }

        if (editEntry && editEntry.trim().length >= 3) {
            setErrorMessage(undefined);
            const allDbNames = data.map((mappedName) => {
                return name === 'customer' ? mappedName.customer_name : mappedName.broker_name;
            });
            const preExisting = allDbNames.includes(editEntry.trim());

            if (preExisting) {
                setErrorMessage(`${Name} bereits vorhanden`);
            } else {
                const params = {
                    key: editValue.key || '',
                    oldName: editValue.oldName || '',
                    newName: `${editEntry.trim()}`,
                };

                apiPatch(`/${name}`, params)
                    .then((res) => {
                        if (name === 'customer' ? res[0].customer_name === `${editEntry.trim()}` : res[0].broker_name === `${editEntry.trim()}`) {
                            setSuccessMessage(`${Name} (${editValue.oldName}) wurde erfolgreich in ${editEntry} umbenannt.`);
                            reset();
                        } else {
                            setErrorMessage(`Der ${Name} konnte leider nicht umbenannt werden`);
                        }
                    })
                    .catch((err) => {
                        setErrorMessage(`${name} konnte nicht geändert werden: ${err}`);
                    });
            }
        } else {
            setErrorMessage(`Ein ${Name} muss mindestens aus 3 Buchstaben bestehen!`);
        }
    };

    return (
        <>
            <Row>
                <Col span={24}>
                    <Typography.Title level={5}>{Name}</Typography.Title>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Select
                        style={{ display: 'flex' }}
                        placeholder={`${Name} hinzufügen`}
                        showSearch
                        onSearch={(term: string) => setNewEntry(term.trim())}
                        onSelect={(term, option) => {
                            setEditValue({
                                ...editValue,
                                key: `${option.key}`,
                                oldName: `${option.value}`,
                                newName: null,
                            });
                            console.log(term, option);
                        }}
                        dropdownRender={(menu) => (
                            <div>
                                {menu}
                                <Divider style={{ margin: '4px 0' }} />
                                <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                    <Input style={{ flex: 'auto' }} value={newEntry || ''} onChange={(event) => setNewEntry(event?.target.value)} />
                                    <div
                                        style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                        onClick={() => {
                                            handleNewName();
                                        }}
                                    >
                                        <PlusOutlined /> Hinzufügen
                                    </div>
                                </div>
                            </div>
                        )}
                    >
                        {data.map((item) => (
                            <Option
                                key={name === 'customer' ? item.customer_id : item.broker_id}
                                value={name === 'customer' ? item.customer_name : item.broker_name}
                            >
                                {name === 'customer' ? item.customer_name : item.broker_name}
                            </Option>
                        ))}
                    </Select>
                </Col>
                <Spacer size={1} />
                <Col span={24}>
                    <div style={{ display: 'flex', flexWrap: 'nowrap' }}>
                        <Input
                            placeholder={`${Name} bearbeiten`}
                            style={{ flex: 'auto' }}
                            value={editEntry ? editEntry : editValue.oldName ?? `${editValue.oldName}`}
                            onChange={(event) => setEditEntry(event?.target.value)}
                            disabled={!editValue.key}
                        />
                        <div
                            style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                            onClick={() => {
                                handleEditName();
                            }}
                        >
                            <EditOutlined /> Namen ändern
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    );
};
