import React, { useState } from 'react';
import { Form, Input, Button, Switch, Modal } from 'antd';

// TYPES
import { Dispatcher } from '../../types/dispatcher';
import { GUTTER } from '../../theme/constants';

// SERVICES
import { apiPost } from '../../services/api';

interface Props {
    visible: boolean;
    setVisible: Dispatcher<boolean>;
}

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 14 },
};
const tailLayout = {
    wrapperCol: { span: 24 },
};

export const NewUser: React.FC<Props> = ({ visible, setVisible }) => {
    const [form] = Form.useForm();
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    const [isKeyAccountManager, setIsKeyAccountManager] = useState<boolean>(false);

    const onFinish = (values: any) => {
        apiPost('/user', {
            user_name: values.name,
            user_password: values.password,
            user_scope_isAdmin: isAdmin ? 'yes' : 'no',
            user_scope_isKeyAccountManager: isKeyAccountManager ? 'yes' : 'no',
        })
            .then((res) => {
                console.log(res);
                form.resetFields();
                setVisible(false);
            })
            .catch((err) => console.log(err));
    };

    const onFinishFailed = (error: any) => {
        console.log(error);
    };
    return (
        <>
            <Modal
                visible={visible}
                footer={null}
                onCancel={() => {
                    setVisible(false);
                }}
                width={700}
            >
                <Form
                    {...layout}
                    name="basic"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    style={{ marginTop: GUTTER * 3 }}
                    form={form}
                >
                    <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Bitte geben Sie einen Namen ein.' }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item label="Passwort" name="password" rules={[{ required: true, message: 'Bitte geben Sie ein Passwort an!' }]}>
                        <Input.Password />
                    </Form.Item>

                    <Form.Item label="Key Account Manager">
                        <Switch checked={isKeyAccountManager} onChange={() => setIsKeyAccountManager(!isKeyAccountManager)} />
                    </Form.Item>

                    <Form.Item label="Administrator">
                        <Switch checked={isAdmin} onChange={() => setIsAdmin(!isAdmin)} />
                    </Form.Item>

                    <Form.Item {...tailLayout} style={{ textAlign: 'center' }}>
                        <Button type="primary" htmlType="submit">
                            Neuen Benutzer anlegen
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};
