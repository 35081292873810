import React, { useState } from 'react';
import { Typography, Button } from 'antd';
import useSWR from 'swr';

// COMPONENTS
import { TileRow } from '../components/TileRow';
import { Tile } from '../components/Tile';
import { ActivityIndicator } from '../components/ActivityIndicator';
import { Alert } from '../components/Alert';
import { EditUser } from './UserManagement/EditUser';
import { NewUser } from './UserManagement/NewUser';

// UTILS
import { user } from '../utils/user';
import { swrAuthorizedFetcher } from '../utils/swrFetcher';
import { g } from '../utils/globals';

// THEME
import { MyIcons, IconType } from '../theme/MyIcons';

// TYPES
import { UserType, UserScope } from '../types/user';

// CONSTANTS
import { scopeNames, scopeIcons } from '../constants/user';

export const UserManagement: React.FC = () => {
    const [editModalVisible, setEditModalVisible] = useState<boolean>(false);
    const [editNewVisible, setNewModalVisible] = useState<boolean>(false);
    const [selectedUser, setSelectedUser] = useState<UserType | null>();
    // @ts-ignore
    const userinfo = user().info;
    // @ts-ignore
    const username = userinfo?.name || null;
    // @ts-ignore
    const userscope = userinfo?.scope || [];

    // Get all user
    const url = `${g.API}/user`;
    const token = user().token || '';

    const { data: swrData, error: swrError }: any = useSWR([url, token], (url, token) => swrAuthorizedFetcher(url, token));

    if (swrError) {
        return <Alert type="info" message="Leider konnten keine Benutzerdaten aus der Datenbank gelesen werden." />;
    }

    if (!swrError && !swrData) {
        return <ActivityIndicator />;
    }

    return (
        <>
            <TileRow>
                <Tile xs={24} md={12}>
                    <Typography.Title level={2}>Neuen Benutzer erstellen</Typography.Title>
                    <Button onClick={() => setNewModalVisible(true)} type="primary">
                        Neuen Benutzer erstellen
                    </Button>
                    <NewUser visible={editNewVisible} setVisible={setNewModalVisible} />
                </Tile>
                {username && (
                    <Tile xs={24} md={12}>
                        <Typography.Title level={2}>Eingeloggt als Benutzer: {username}</Typography.Title>
                        {userscope.length > 0 &&
                            userscope.map((scope: UserScope) => {
                                // @ts-ignore
                                const iconName: IconType = scopeIcons[scope];
                                return <MyIcons name={iconName} text={scopeNames[scope]} key={scope} />;
                            })}
                    </Tile>
                )}
            </TileRow>

            {userscope.length > 0 && userscope.includes('hocr:admin') && (
                <TileRow>
                    <Tile>
                        <Typography.Title level={2}>Benutzer bearbeiten</Typography.Title>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            {swrData.map((user: UserType) => (
                                <div key={user.user_id}>
                                    <Button
                                        onClick={() => {
                                            setEditModalVisible(true);
                                            setSelectedUser(user);
                                        }}
                                        style={{
                                            paddingLeft: '0',
                                            paddingRight: '0',
                                        }}
                                        type="link"
                                    >
                                        <MyIcons name="Edit" text={user.user_name} size={16} />
                                    </Button>
                                </div>
                            ))}
                        </div>
                        {selectedUser && <EditUser visible={editModalVisible} setVisible={setEditModalVisible} user={selectedUser} />}
                    </Tile>
                </TileRow>
            )}
        </>
    );
};
