import axios from 'axios';
import { invalidateSWR, invalidateWithParamsSWR } from '../utils/invalidateSWR';

// TYPES
import { ObjectWithKeysArr } from '../types/objectWithKeys';

export const api = axios.create({
    baseURL: `${process.env.REACT_APP_API_ENDPOINT}`,
});

// PATCH applies a partial update to the resource
export const apiPatch = async (path: string, body: ObjectWithKeysArr, revalidate: boolean = true, revalidatePath: string[] = []) => {
    try {
        const res = await api.patch(path, body);
        if (revalidate) {
            invalidateSWR(path);
        }
        if (revalidatePath !== []) {
            revalidatePath.map((path) => invalidateWithParamsSWR(path));
        }
        return res.data;
    } catch (err) {
        console.error(err);
        return err;
    }
};

// PUT applies a full update to the resource
export const apiPut = async (path: string, body: ObjectWithKeysArr, revalidate: boolean = true, revalidatePath: string[] = []) => {
    try {
        const res = await api.put(path, body);
        if (revalidate) {
            invalidateSWR(path);
        }
        if (revalidatePath !== []) {
            revalidatePath.map((path) => invalidateWithParamsSWR(path));
        }
        return res.data;
    } catch (err) {
        console.error(err);
        return err;
    }
};

export const apiPost = async (path: string, body: ObjectWithKeysArr, revalidate: boolean = true, revalidatePath: string[] = []) => {
    try {
        const res = await api.post(path, body);
        if (revalidate) {
            invalidateSWR(path);
        }
        if (revalidatePath !== []) {
            revalidatePath.map((path) => invalidateWithParamsSWR(path));
        }
        return res.data;
    } catch (err) {
        console.error(err);
        return err;
    }
};

export const apiDelete = async (path: string, body: ObjectWithKeysArr, revalidate: boolean = true, revalidatePath: string[] = []) => {
    try {
        const res = await api.delete(path, { data: { ...body } });
        if (revalidate) {
            invalidateSWR(path);
        }
        if (revalidatePath !== []) {
            revalidatePath.map((path) => invalidateWithParamsSWR(path));
        }
        return res.data;
    } catch (err) {
        console.error(err);
        return err;
    }
};
