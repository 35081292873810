import React from 'react';
import { Alert as AndtAlert } from 'antd';

interface Props {
    title?: string;
    message?: string;
    type?: 'success' | 'info' | 'warning' | 'error';
    closable?: boolean;
    onClose?: any;
    style?: React.CSSProperties | undefined;
}

const fallbackTitle = {
    success: 'Erfolg',
    info: 'Hinweis',
    warning: 'Warnung',
    error: 'Fehler',
};

export const Alert: React.FC<Props> = ({
    title,
    message = 'Es ist ein Fehler aufgetreten',
    type = 'error',
    closable = false,
    onClose = () => {},
    style = { position: 'relative' },
}) => (
    <AndtAlert
        message={title ? title : fallbackTitle[type]}
        description={message}
        type={type}
        showIcon
        closable={closable}
        style={style}
        onClose={onClose}
    />
);
