import React from 'react';
import { Steps, Popover, Alert, Typography } from 'antd';
import { format } from 'date-fns';

// HOOKS
import { useAuthRequest } from '../../hooks/useAuthRequest';

// UTILS
import { documentStepNameByValue } from '../../utils/documentSteps';

// TYPES
import { DocumentstatusType, UserType } from '../../types/api';
import { ActivityIndicator } from '../../components/ActivityIndicator';
import { invalidateSWR } from '../../utils/invalidateSWR';
import { apiPost } from '../../services/api';

interface Props {
    document_id: string;
}

const { Step } = Steps;

export const Documentstatus: React.FC<Props> = ({ document_id }) => {
    const { data, error }: { data: DocumentstatusType[]; error: any } = useAuthRequest(`/documentstatus`, 0, {
        document_id,
    });
    const { data: usersData, error: usersError }: { data: UserType[]; error: any } = useAuthRequest('/user');

    if (error || usersError) {
        return <Alert type="error" message="Es konnte keine Information zum Dokumentenstatus geladen werden." />;
    }

    if (!data || !usersData) {
        return <ActivityIndicator />;
    }

    const getUsernameById = (id: string) => usersData.filter((user) => user.user_id === id)[0]?.user_name || 'unbekannt';

    const getStatus = (val: number) =>
        data
            .filter((status) => status.documentstatus_step === val)
            .map((status) => ({
                step: val,
                user_id: status.documentstatus__user_id,
                time: status.documentstatus_timestamp,
            }));

    // const statoosArray = [getStatus(0), getStatus(25), getStatus(50), getStatus(75), getStatus(100)];
    const statoosArray = [getStatus(25), getStatus(50), getStatus(75), getStatus(100)];

    const possibleSteps = [25, 50, 75, 100];

    // Evaluate which dot has to be finished, process or wait
    const getStepsStatus = (step: number) => {
        const latestStepUpdate = data[0].documentstatus_step;
        if (latestStepUpdate > step) return 'finish';
        if (latestStepUpdate === step) return 'process';
        if (latestStepUpdate < step) return 'wait';
    };

    //@ts-ignore
    const customDot = (dot, { status, index }) => {
        return (
            <Popover
                content={
                    <>
                        {statoosArray[index].length > 0 ? (
                            <Typography.Text>
                                {getUsernameById(statoosArray[index][0].user_id)} ({format(new Date(statoosArray[index][0].time), 'dd.MM.yy - HH:mm')}{' '}
                                Uhr)
                            </Typography.Text>
                        ) : (
                            <Typography.Text>-</Typography.Text>
                        )}
                    </>
                }
            >
                {dot}
            </Popover>
        );
    };

    const updateStatus = (index: number) => {
        const step = possibleSteps[index] || null;
        if (step) {
            apiPost('/documentstatus', { document_id, documentstatus_step: step })
                .then((res) => {
                    console.log(res);
                    invalidateSWR('/documentstatus', { document_id });
                })
                .catch((err) => console.error(err));
        } else {
            console.error('Es wurde kein entsprechender Status-Schritt gefunden. Es wurde keine Datenbankabfrage gestartet.');
        }
    };

    return (
        <>
            <Steps size="small" current={data[0].documentstatus_step || 25} onChange={(index: number) => updateStatus(index)} progressDot={customDot}>
                {/* <Step title={documentStepNameByValue[0]} status={getStepsStatus(0)} /> */}
                <Step title={documentStepNameByValue[25]} status={getStepsStatus(25)} />
                <Step title={documentStepNameByValue[50]} status={getStepsStatus(50)} />
                <Step title={documentStepNameByValue[75]} status={getStepsStatus(75)} />
                <Step title={documentStepNameByValue[100]} status={getStepsStatus(100)} />
            </Steps>
        </>
    );
};
