export const swrFetcher = (...args: any[]) =>
    // @ts-ignore
    fetch(...args).then((res: any) => {
        return res.json();
    });

export const swrAuthorizedFetcher = (url: string, token: string) =>
    fetch(url, { headers: { Authorization: token } }).then((res: any) => {
        if (res.status === 403) {
            throw new Error('Nicht angemeldet!');
        }
        return res.json();
    });
