import { ScopeArrayDictonary } from '../types/user';

export const scopeNames: ScopeArrayDictonary<string> = {
    'hocr:admin': 'Administrator',
    'hocr:read': 'Nutzer',
    'hocr:keyaccount': 'Key Account Manager',
};

export const scopeIcons: ScopeArrayDictonary<string> = {
    'hocr:admin': 'UserAdmin',
    'hocr:read': 'UserRead',
    'hocr:keyaccount': 'UserAccountManager',
};
