export default [
    // {
    //     id: 0,
    //     user_id: 0,
    //     event_type: 'personal-trainer',
    //     available: true,
    //     title: "Trainer Prüfung",
    //     allDay: true,
    //     start: new Date(2020, 10,),
    //     end: new Date(2020, 10, 26)
    // },
    // {
    //     id: 1,
    //     user_id: 0,
    //     event_type: 'personal-trainer',
    //     available: true,
    //     title: "Rovena: Personal Training",
    //     start: new Date(2020, 9, 10,),
    //     end: new Date(2020, 19, 10,)
    // },
    {
        id: 2,
        user_id: 0,
        event_type: 'personal-trainer',
        available: true,
        title: "Werksstudent1",
        start: new Date(new Date().setHours(new Date().getHours() - 3)),
        end: new Date(new Date().setHours(new Date().getHours() + 3))
    },
    {
        id: 4,
        user_id: 1,
        event_type: 'personal-trainer',
        available: true,
        title: "Werksstudent1",
        start: new Date(new Date().setHours(new Date().getHours() + 7)),
        end: new Date(new Date().setHours(new Date().getHours() + 10))
    },
];