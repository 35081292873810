import React from 'react';

interface Props {
    size?: number;
    strokeWidth?: number;
    color?: string;
}

export const UserProfile: React.FC<Props> = ({ size = 24, strokeWidth = 1.5, color }) => (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
        <path
            d="M17.763 18.91L17.413 18.031C17.236 17.585 16.961 17.185 16.609 16.859L16.545 16.8C15.991 16.286 15.263 16 14.507 16H9.49299C8.73699 16 8.00899 16.286 7.45399 16.799L7.38999 16.859C7.03799 17.185 6.76399 17.585 6.58599 18.031L6.23599 18.91"
            stroke={color}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M14.4749 7.44713C15.8417 8.81397 15.8417 11.03 14.4749 12.3969C13.1081 13.7637 10.892 13.7637 9.52515 12.3969C8.15831 11.03 8.15831 8.81397 9.52515 7.44713C10.892 6.08029 13.1081 6.08029 14.4749 7.44713"
            stroke={color}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M18.364 5.63604C21.8787 9.15076 21.8787 14.8492 18.364 18.3639C14.8493 21.8787 9.1508 21.8787 5.6361 18.3639C2.12138 14.8492 2.12138 9.15074 5.6361 5.63604C9.15082 2.12132 14.8493 2.12132 18.364 5.63604"
            stroke={color}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const UserProfile2: React.FC<Props> = ({ size = 24, strokeWidth = 1.5, color }) => (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
        <path
            d="M9.91422 10.1788C10.6953 10.9599 10.6953 12.2262 9.91422 13.0072C9.13317 13.7883 7.86684 13.7883 7.08579 13.0072C6.30474 12.2262 6.30474 10.9599 7.08579 10.1788C7.86684 9.39776 9.13317 9.39776 9.91422 10.1788"
            stroke={color}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path d="M15 11H19" stroke={color} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M18 14.5H15" stroke={color} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round" />
        <path
            d="M12 18.041C11.83 17.614 11.567 17.23 11.229 16.917V16.917C10.658 16.388 9.908 16.093 9.129 16.093H7.871C7.092 16.093 6.342 16.387 5.771 16.917V16.917C5.433 17.229 5.17 17.613 5 18.041"
            stroke={color}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13 7H11C10.448 7 10 6.552 10 6V3C10 2.448 10.448 2 11 2H13C13.552 2 14 2.448 14 3V6C14 6.552 13.552 7 13 7Z"
            stroke={color}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M10 5H4.041C2.914 5 2 5.914 2 7.041V19C2 20.105 2.895 21 4 21H20C21.105 21 22 20.105 22 19V7C22 5.895 21.105 5 20 5H14"
            stroke={color}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
