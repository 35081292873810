import React, { useState } from 'react';
import moment from 'moment';
import { Modal, Form, Input, Button, Switch, DatePicker, Select, Typography } from 'antd';
import locale from 'antd/es/date-picker/locale/de_DE';

// COMPONENTS
import { ActivityIndicator } from './ActivityIndicator';
import { Alert } from './Alert';

// SERVICES
import { apiPost, apiPatch } from '../services/api';

// HOOKS
import { useAuthRequest } from '../hooks/useAuthRequest';

// TYPES
import { Dispatcher } from '../types/dispatcher';
import { GUTTER } from '../theme/constants';
import { DocumentStatusStepType } from '../types/api';

interface Props {
    visible: boolean;
    setVisible: Dispatcher<boolean>;
    document_id?: string;
}

const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 12 },
};
const tailLayout = {
    wrapperCol: { offset: 6, span: 12 },
};

const disabledDate = (current: any) => {
    // Can not select days before today and today
    return current && current < moment().subtract(1, 'days').endOf('day');
};

export const AddTodo: React.FC<Props> = ({ visible, setVisible, document_id = 'null' }) => {
    const [highSevirity, setHighSevirity] = useState<boolean>(false);
    const [hasDueDate, setHasDueDate] = useState<boolean>(false);
    const [dueDate, setDueDate] = useState<string | undefined>(undefined);
    const [assigned, setAssigned] = useState<string>('');
    const [error, setError] = useState<any>(undefined);

    const [form] = Form.useForm();
    const { data: usersData, error: usersError } = useAuthRequest('/user');

    const currentHour = moment().format('HH');
    // @ts-ignore
    const presetTime = currentHour < 8 ? '12:00' : currentHour <= 14 ? '18:00' : '10:00';
    // @ts-ignore
    const presetDay = currentHour <= 14 ? moment().format('YYYY-MM-DD HH:mm') : moment().add(1, 'days').format('YYYY-MM-DD HH:mm');

    if (!dueDate) {
        setDueDate(`${moment(presetDay, 'YYYY-MM-DD HH:mm')}`);
    }

    const onFinish = async (values: any) => {
        const title = values.title;
        const params = {
            title,
            has_high_sevirity: highSevirity ? 'yes' : 'no',
            due_date: hasDueDate && dueDate ? dueDate : 'no',
            user_id: assigned,
            document_id,
        };
        const patchParams: { document_id: string; step: DocumentStatusStepType } = {
            document_id,
            step: 50,
        };
        apiPost('/todo', params)
            .then((res) => {
                console.log(res);
                form.resetFields();
                apiPatch('/document/step', patchParams, false, [`/document?document_id=${document_id}`]).then((res) => {
                    console.log(res);
                });
                setVisible(false);
            })
            .catch((err) => console.error(err));
    };

    const onFinishFailed = (errorInfo: any) => {
        form.resetFields();
        setError(errorInfo);
    };

    function handleChange(value: string) {
        setAssigned(value);
    }

    return (
        <Modal
            visible={visible}
            footer={null}
            onCancel={() => {
                setVisible(false);
                form.resetFields();
            }}
        >
            <Typography.Title level={2}>Neues Todo hinzufügen</Typography.Title>
            {error && (
                <Alert
                    title="Es ist ein Fehler aufgetreten"
                    message={error.errorFields.map((err: any) => err.errors[0])}
                    style={{ marginBottom: GUTTER * 2 }}
                />
            )}
            <Form {...layout} name="basic" onFinish={onFinish} onFinishFailed={onFinishFailed} form={form} onFieldsChange={() => setError(false)}>
                {/* Titel */}
                <Form.Item label="Titel" name="title" rules={[{ required: true, message: 'Bitte geben Sie einen Todo Titel an!' }]}>
                    <Input />
                </Form.Item>

                {/* Document ID */}
                {document_id !== 'null' && (
                    <Form.Item label="Dokument">
                        <Typography.Text>{document_id}</Typography.Text>
                    </Form.Item>
                )}

                {/* Todo zuweisen */}
                <Form.Item label="Todo zuweisen">
                    {usersError && <Alert type="error" message="Es konnte keine Liste von Benutzern vom Server geladen werden." />}
                    {!usersError && !usersData && <ActivityIndicator />}
                    {!usersError && usersData && (
                        <Select defaultValue="--" onChange={handleChange}>
                            <Select.Option value="--">-- Allgemeines Todo --</Select.Option>
                            {usersData.map((user: any) => (
                                <Select.Option title={user.user_name} value={user.user_id} key={user.user_id}>
                                    {user.user_name}
                                </Select.Option>
                            ))}
                        </Select>
                    )}
                </Form.Item>

                {/* Als wichtig markieren */}
                <Form.Item label="Priorität">
                    <Switch checked={highSevirity} onChange={() => setHighSevirity(!highSevirity)} />
                </Form.Item>

                {/* Fälligkeitsdatum nutzen*/}
                <Form.Item label="Fälligkeitsdatum">
                    <Switch checked={hasDueDate} onChange={() => setHasDueDate(!hasDueDate)} />
                </Form.Item>

                {/* Fälligkeitsdatum */}
                {hasDueDate && (
                    <Form.Item label="Fälligkeitsdatum">
                        <DatePicker
                            locale={locale}
                            format="YYYY-MM-DD HH:mm"
                            disabledDate={disabledDate}
                            defaultValue={moment(presetDay, 'YYYY-MM-DD HH:mm')}
                            showTime={{ defaultValue: moment(presetTime, 'HH:mm') }}
                            showNow={false}
                            onChange={(date, dateString) => setDueDate(dateString)}
                        />
                    </Form.Item>
                )}

                {document_id !== 'null' && (
                    <Alert
                        message={`Mit dem Anlegen des Todos wechselt der Status des Dokumentes auf "Todo"`}
                        type="info"
                        style={{ marginBottom: GUTTER * 2 }}
                    />
                )}

                <Form.Item {...tailLayout} style={{ textAlign: 'center' }}>
                    <Button type="primary" htmlType="submit">
                        Absenden
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};
