import * as React from 'react';
import { Divider, Popover, Popconfirm } from 'antd';

// THEME
import colors from '../../theme/colors';
import { GUTTER } from '../../theme/constants';
import { MyIcons } from '../../theme/MyIcons';

// UTILS
import { user } from '../../utils/user';
import { invalidateSWR } from '../../utils/invalidateSWR';

// SERVICES
import { apiDelete } from '../../services/api';

interface Props {
    document_id: string;
    tags?: string[];
    todos?: string[];
}

export const Icons: React.FC<Props> = ({ document_id, tags = [], todos = [] }) => {
    // @ts-ignore
    const userinfo = user().info;
    // @ts-ignore
    const userscope = userinfo?.scope || [];

    return (
        <>
            <Divider style={{ marginTop: GUTTER / 2, marginBottom: GUTTER, borderTopColor: colors.ambientWhite }} />
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flex: 1,
                }}
            >
                <div style={{ flex: 1 }}>
                    {tags[0] !== null ? (
                        <Popover trigger="hover" content={<span>{tags.join(', ')}</span>}>
                            <div style={{ cursor: 'pointer' }}>
                                <MyIcons
                                    name="DocumentHasTag"
                                    color={colors.primaryBlue}
                                    size={32}
                                    style={{ display: 'flex', justifyContent: 'center' }}
                                />
                            </div>
                        </Popover>
                    ) : (
                        <MyIcons name="DocumentHasTag" color={colors.ambientGrey} size={32} style={{ display: 'flex', justifyContent: 'center' }} />
                    )}
                </div>
                <div style={{ flex: 1 }}>
                    {todos.length && todos[0] !== null ? (
                        <Popover trigger="hover" content={<span>{todos.join(', ')}</span>}>
                            <div style={{ cursor: 'pointer' }}>
                                <MyIcons
                                    name="DocumentHasTodo"
                                    color={colors.primaryBlue}
                                    size={32}
                                    style={{ display: 'flex', justifyContent: 'center' }}
                                />
                            </div>
                        </Popover>
                    ) : (
                        <MyIcons name="DocumentHasTodo" color={colors.ambientGrey} size={32} style={{ display: 'flex', justifyContent: 'center' }} />
                    )}
                </div>
                {userscope.length > 0 && userscope.includes('hocr:admin') && (
                    <div style={{ flex: 1 }}>
                        <Popconfirm
                            placement="top"
                            title="Soll das Dokument gelöscht werden?"
                            onConfirm={() => {
                                // TODO: tag löschen
                                apiDelete(
                                    '/document',
                                    {
                                        document_id: document_id,
                                    },
                                    true,
                                    [`/document`],
                                )
                                    .then((res) => {
                                        invalidateSWR('/overview');
                                        console.log(res);
                                    })
                                    .catch((err) => console.error(err));
                            }}
                            okText="Ja"
                            cancelText="Nein"
                        >
                            <div style={{ cursor: 'pointer' }}>
                                <MyIcons
                                    name="DocumentActionDelete"
                                    color={colors.materialUIRed900}
                                    size={32}
                                    style={{ display: 'flex', justifyContent: 'center' }}
                                />
                            </div>
                        </Popconfirm>
                    </div>
                )}
            </div>
        </>
    );
};
