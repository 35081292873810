import React, { useState, useEffect, Fragment } from 'react';
import { useLocation } from 'react-router-dom'; // useHistory,
import { Divider, Typography, Col, Row, Pagination, Select, Button } from 'antd';

// COMPONENTS
import { ActivityIndicator } from '../components/ActivityIndicator';
import { Alert } from '../components/Alert';
import { Tile } from '../components/Tile';
import { TileRow } from '../components/TileRow';
import { Gallery } from '../components/Gallery';

// HOOKS
import { useAuthRequest } from '../hooks/useAuthRequest';

// TYPES
import { documentsType, CustomerType, BrokerType } from '../types/api';

// UTILS
import { urlParam } from '../utils/urlParam';
import { GUTTER } from '../theme/constants';
import { invalidateSWR } from '../utils/invalidateSWR';
import { periodSplitter } from '../utils/periodSplitter';

// SERVICES
import { socket } from '../services/socket';
import { Spacer } from '../components/Spacer';

// DECONSTRUCT
const { Option } = Select;

const PAGE_SIZE = 6 * 10;

interface FilterParamsType {
    costumer_id?: Nullable<string>;
    broker_id?: Nullable<string>;
    periodType?: Nullable<string>;
    periodQuarter?: Nullable<1 | 2 | 3 | 4>;
    periodYear?: Nullable<number>;
    document_status?: Nullable<number>;
    limit?: Nullable<number>;
    offset?: Nullable<number>;
}

export const Documents: React.FC = () => {
    const urlParams = useLocation().search;
    const initQueryParams = {
        costumer_id: null,
        broker_id: null,
        periodType: null,
        periodQuarter: null,
        periodYear: null,
        document_status: null,
        limit: PAGE_SIZE,
        offset: 0,
    };
    const [queryParams, setQueryParams] = useState<FilterParamsType>(initQueryParams);
    const [bigThumbs, setBigThumbs] = useState(false);
    const [currentPagePagination, setCurrentPagePagination] = useState(1);
    const [currentPagePaginationSize, setCurrentPagePaginationSize] = useState(PAGE_SIZE);

    // Check for query params, if any of them exists:
    // Update local state object
    useEffect(() => {
        const has_costumer_id = urlParam('costumer_id', urlParams);
        const has_broker_id = urlParam('broker_id', urlParams);
        const has_period = urlParam('period', urlParams);
        const has_document_status = urlParam('document_status', urlParams);
        const has_limit = urlParam('limit', urlParams);
        const has_offset = urlParam('offset', urlParams);

        const hasQueryParams: FilterParamsType = {};

        if (has_costumer_id) {
            hasQueryParams.costumer_id = has_costumer_id;
        }
        if (has_broker_id) {
            hasQueryParams.broker_id = has_broker_id;
        }
        if (has_period) {
            hasQueryParams.periodType = has_period.split('-')[0] === 'pre' ? 'pre' : 'post';
            hasQueryParams.periodQuarter = periodSplitter(has_period.split('-')[1]);
            hasQueryParams.periodYear = parseInt(has_period.split('-')[2], 10);
        }
        if (has_document_status) {
            hasQueryParams.document_status = parseInt(has_document_status, 10);
        }
        if (has_limit) {
            hasQueryParams.limit = parseInt(has_limit, 10);
        }
        if (has_offset) {
            hasQueryParams.offset = parseInt(has_offset, 10);
        }

        if (Object.keys(hasQueryParams).length !== 0) {
            setQueryParams({ ...queryParams, ...hasQueryParams });
        }
    }, [queryParams, urlParams]);

    // let history = useHistory();

    // function handleClick() {
    //     history.push('/home');
    //     // history.push('/dresses?color=blue')
    //     // Set Param (doublettes will be deleted)
    //     // URLSearchParams.set()
    // }

    const { data, error }: { data: documentsType[]; error: any } = useAuthRequest(`/documents/promise`, 0, {
        ...(queryParams.costumer_id && { costumer_id: queryParams.costumer_id }),
        ...(queryParams.broker_id && { broker_id: queryParams.broker_id }),
        ...(queryParams.periodType &&
            queryParams.periodQuarter &&
            queryParams.periodYear && { period: `${queryParams.periodType}-${queryParams.periodQuarter}-${queryParams.periodYear}` }),
        ...(queryParams.document_status && { document_status: queryParams.document_status }),
        ...(queryParams.limit && { limit: queryParams.limit }),
        ...(queryParams.offset && { offset: queryParams.offset }),
    });

    useEffect(() => {
        socket.on('SOCKET_MESSAGE_UPDATE_DOCUMENTS_SCREEN', (data: any) => {
            // @ts-ignore
            invalidateSWR('/documents/promise', queryParams);
        });
        // @ts-ignore
        invalidateSWR('/documents/promise', queryParams);
        console.log('INVALIDATE', queryParams);
    }, [queryParams]);

    const { data: customerData, error: customerError }: { data: CustomerType[]; error: any } = useAuthRequest('/customer');
    const { data: brokerData, error: brokerError }: { data: BrokerType[]; error: any } = useAuthRequest('/broker');

    if (error || customerError || brokerError) {
        return <Alert type="error" message="Es konnte keine Liste von Dokumenten geladen werden." />;
    }

    if (!data || !customerData || !brokerData) {
        return <ActivityIndicator />;
    }

    const periodYearsArray = Array.from({ length: 21 }, (_, i) => i + 2019);

    const getCustomerNameById = (id: string) => customerData.filter((costumer) => costumer.customer_id === id)[0].customer_name;
    const getBrokerNameById = (id: string) => brokerData.filter((broker) => broker.broker_id === id)[0].broker_name;
    // const getQuarter = (quarter: number) => {
    //     switch (quarter) {
    //         case 1:
    //             return 'Q1';
    //             break;

    //         case 2:
    //             return 'Q2';
    //             break;

    //         case 3:
    //             return 'Q3';
    //             break;

    //         case 4:
    //             return 'FY';
    //             break;

    //         default:
    //             return 'k.A.';
    //             break;
    //     }
    // };

    return (
        <>
            <TileRow>
                <Tile span={24}>
                    <Typography.Title level={2}>Dokumente</Typography.Title>
                    <Divider />
                    <Row style={{ marginBottom: GUTTER }}>
                        <Col span={8} style={{ paddingRight: GUTTER }}>
                            <Select
                                showSearch
                                allowClear
                                onClear={() => setQueryParams(initQueryParams)}
                                style={{ width: '100%' }}
                                placeholder={queryParams.costumer_id ? getCustomerNameById(queryParams.costumer_id) : 'Kunde'}
                                //@ts-ignore
                                onChange={(value, option) => setQueryParams({ ...queryParams, costumer_id: option.key })}
                            >
                                {customerData.map((customer) => (
                                    <Option value={customer.customer_name} key={customer.customer_id}>
                                        {customer.customer_name}
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                        <Col span={8} style={{ paddingRight: GUTTER }}>
                            <Select
                                showSearch
                                allowClear
                                onClear={() => setQueryParams(initQueryParams)}
                                style={{ width: '100%' }}
                                placeholder={queryParams.broker_id ? getBrokerNameById(queryParams.broker_id) : 'Broker'}
                                //@ts-ignore
                                onChange={(value, option) => setQueryParams({ ...queryParams, broker_id: option.key })}
                            >
                                {brokerData.map((broker) => (
                                    <Option value={broker.broker_name} key={broker.broker_id}>
                                        {broker.broker_name}
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                        <Col span={8} style={{ paddingLeft: GUTTER }}>
                            <Row>
                                <Col span={8} style={{ paddingRight: GUTTER }}>
                                    <Select
                                        showSearch
                                        style={{ width: '100%' }}
                                        defaultValue={queryParams.periodType ? queryParams.periodType : 'pre'}
                                        onChange={(value: string) => setQueryParams({ ...queryParams, periodType: value })}
                                    >
                                        <Option value="pre" key="pre">
                                            PRE
                                        </Option>
                                        <Option value="post" key="post">
                                            POST
                                        </Option>
                                    </Select>
                                </Col>

                                <Col span={8} style={{ paddingRight: GUTTER }}>
                                    <Select
                                        showSearch
                                        style={{ width: '100%' }}
                                        defaultValue={queryParams.periodQuarter ? queryParams.periodQuarter : 4}
                                        onChange={(value: 1 | 2 | 3 | 4) => setQueryParams({ ...queryParams, periodQuarter: value })}
                                    >
                                        <Option value={1} key="Q1">
                                            Q1
                                        </Option>
                                        <Option value={2} key="Q2">
                                            Q2
                                        </Option>
                                        <Option value={3} key="Q3">
                                            Q3
                                        </Option>
                                        <Option value={4} key="FY">
                                            FY
                                        </Option>
                                    </Select>
                                </Col>

                                <Col span={8}>
                                    <Select
                                        showSearch
                                        style={{ width: '100%' }}
                                        defaultValue={queryParams.periodYear ? queryParams.periodYear : 2021}
                                        onChange={(value: number) => setQueryParams({ ...queryParams, periodYear: value })}
                                    >
                                        {periodYearsArray.map((year) => (
                                            <Option value={year} key={`${year}`}>
                                                {year}
                                            </Option>
                                        ))}
                                    </Select>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Divider />
                </Tile>
            </TileRow>
            <TileRow>
                <Tile span={24}>
                    {data.length && (
                        <>
                            <Row>
                                <Tile>
                                    {data[0]?.agg_count && <Typography.Title level={2}>{data[0]?.agg_count} Dokumente gefunden</Typography.Title>}
                                    {data[0]?.document_id && (
                                        <div style={{ textAlign: 'right' }}>
                                            <Button onClick={() => setBigThumbs(!bigThumbs)}>
                                                {bigThumbs ? 'Vorschaubilder verkleinern' : 'Vorschaubilder vergrößern'}
                                            </Button>
                                        </div>
                                    )}
                                </Tile>
                            </Row>
                            <Row>
                                {data[0] &&
                                    data[0].document_id &&
                                    data.map((document: documentsType) => (
                                        <Fragment key={`${document.document_id}`}>
                                            <Col
                                                span={4}
                                                xs={24}
                                                sm={bigThumbs ? 24 : 8}
                                                lg={bigThumbs ? 12 : 4}
                                                style={{
                                                    padding: GUTTER / 2,
                                                }}
                                            >
                                                <Gallery document={document} bigThumbs={bigThumbs} buildArray />
                                            </Col>
                                        </Fragment>
                                    ))}
                            </Row>
                            {data[0].agg_count && data[0].agg_count > 0 && (
                                <>
                                    <Spacer size={2} />
                                    <Row>
                                        <Col span={24} style={{ textAlign: 'center' }}>
                                            <Pagination
                                                current={currentPagePagination}
                                                onChange={(page, pageSize) => {
                                                    setCurrentPagePagination(page);
                                                    setQueryParams({
                                                        ...queryParams,
                                                        limit: pageSize,
                                                        offset: (data[0].agg_count || 0) > 0 ? (page - 1) * currentPagePaginationSize : 0,
                                                    });
                                                }}
                                                onShowSizeChange={(current, size) => {
                                                    console.log(current, size);
                                                    setCurrentPagePaginationSize(size);
                                                    setCurrentPagePagination(current);
                                                    setQueryParams({
                                                        ...queryParams,
                                                        limit: size,
                                                        offset: (data[0].agg_count || 0) > 0 ? current * size : 0,
                                                    });
                                                    console.log({
                                                        ...queryParams,
                                                        limit: size,
                                                        offset: (data[0].agg_count || 0) > 0 ? current * size : 0,
                                                    });
                                                    console.log('ON SIZE CHANGE', size, queryParams);
                                                }}
                                                total={data[0].agg_count}
                                                defaultPageSize={PAGE_SIZE}
                                                pageSize={currentPagePaginationSize}
                                                pageSizeOptions={[
                                                    `${PAGE_SIZE / 2}`,
                                                    `${PAGE_SIZE}`,
                                                    `${PAGE_SIZE * 2}`,
                                                    `${PAGE_SIZE * 3}`,
                                                    `${PAGE_SIZE * 4}`,
                                                ]}
                                                style={{
                                                    flex: 1,
                                                    width: '100%',
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <Spacer size={2} />
                                </>
                            )}
                        </>
                    )}
                </Tile>
            </TileRow>
        </>
    );
};
