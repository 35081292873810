import React from 'react';
import { documentStepNameByValue } from '../../utils/documentSteps';
import { ColorButton } from '../../components/ColorButton';
import colors from '../../theme/colors';

interface Props {
    step: number | null;
}

interface LexiconText {
    description: string;
}

export interface Lexicon {
    [key: number]: LexiconText;
}

export const DocumentStep: React.FC<Props> = ({ step }) => {
    // 0 would be ignored and you wouldn't see any progress, therefore we increase 0 value to 10
    if (step === 0) {
        step = 25;
    }
    // @ts-ignore
    const description = step && documentStepNameByValue.hasOwnProperty(step) ? documentStepNameByValue[step] : 'unbekannt';
    let bgcolor;

    switch (step) {
        case 0:
            bgcolor = '#e4636a';
            break;
        case 25:
            bgcolor = colors.primaryBlue;
            break;
        case 50:
            bgcolor = '#ffca4c';
            break;
        case 75:
            bgcolor = '#fff954';
            break;
        case 100:
            bgcolor = '#83d17a';
            break;
        default:
            bgcolor = '#7bd2b5';
    }
    return step ? (
        <div style={{ width: '100%', textAlign: 'center' }}>
            <ColorButton text={description} bgcolor={bgcolor} />
        </div>
    ) : null;
};
