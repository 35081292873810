import React from 'react';

interface Props {
    size?: number;
    strokeWidth?: number;
    color?: string;
}

export const UserRead: React.FC<Props> = ({ size = 24, strokeWidth = 1.5, color }) => (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
        <path
            d="M17.763 18.91L17.413 18.031C17.236 17.585 16.961 17.185 16.609 16.859L16.545 16.8C15.991 16.286 15.263 16 14.507 16H9.49299C8.73699 16 8.00899 16.286 7.45399 16.799L7.38999 16.859C7.03799 17.185 6.76399 17.585 6.58599 18.031L6.23599 18.91"
            stroke={color}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M14.4749 7.44713C15.8417 8.81397 15.8417 11.03 14.4749 12.3969C13.1081 13.7637 10.892 13.7637 9.52515 12.3969C8.15831 11.03 8.15831 8.81397 9.52515 7.44713C10.892 6.08029 13.1081 6.08029 14.4749 7.44713"
            stroke={color}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M18.364 5.63604C21.8787 9.15076 21.8787 14.8492 18.364 18.3639C14.8493 21.8787 9.1508 21.8787 5.6361 18.3639C2.12138 14.8492 2.12138 9.15074 5.6361 5.63604C9.15082 2.12132 14.8493 2.12132 18.364 5.63604"
            stroke={color}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
export const UserAccountManager: React.FC<Props> = ({ size = 24, strokeWidth = 1.5, color }) => (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19 21H5C3.895 21 3 20.105 3 19V5C3 3.895 3.895 3 5 3H19C20.105 3 21 3.895 21 5V19C21 20.105 20.105 21 19 21Z"
            stroke={color}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M14.4749 7.44713C15.8418 8.81397 15.8418 11.03 14.4749 12.3969C13.1081 13.7637 10.892 13.7637 9.52516 12.3969C8.15832 11.03 8.15832 8.81397 9.52516 7.44713C10.892 6.08029 13.1081 6.08029 14.4749 7.44713"
            stroke={color}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M17.413 18.031C17.236 17.585 16.961 17.185 16.609 16.859V16.859C16.014 16.307 15.236 16 14.424 16C13.07 16 10.93 16 9.576 16C8.764 16 7.986 16.307 7.39 16.859V16.859C7.038 17.185 6.764 17.585 6.586 18.031"
            stroke={color}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
export const UserAdmin: React.FC<Props> = ({ size = 24, strokeWidth = 1.5, color }) => (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
        <path
            d="M12 6.99789C13.2275 6.99789 14.2229 7.99331 14.2229 9.22082C14.2229 10.4483 13.2275 11.4437 12 11.4437C10.7725 11.4437 9.77708 10.4493 9.77708 9.22082C9.77708 7.99231 10.7725 6.99789 12 6.99789"
            stroke={color}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.0033 11.2417C20.0033 15.6115 16.845 19.7072 12.5202 20.9317C12.1821 21.0278 11.8179 21.0278 11.4798 20.9317C7.15498 19.7082 3.99667 15.6115 3.99667 11.2417V7.212C3.99667 6.39966 4.48787 5.66735 5.24018 5.36022L10.1062 3.3694C11.3207 2.87219 12.6813 2.87219 13.8948 3.3694L18.7608 5.36022C19.5121 5.66735 20.0033 6.39966 20.0033 7.212V11.2417Z"
            stroke={color}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M16.0017 15.0012C15.8856 14.7081 15.7045 14.446 15.4734 14.2319V14.2319C15.0823 13.8698 14.5721 13.6677 14.0388 13.6677C13.1495 13.6677 10.8515 13.6677 9.96215 13.6677C9.42893 13.6677 8.91772 13.8698 8.52655 14.2319V14.2319C8.29546 14.446 8.11538 14.7081 7.99833 15.0012"
            stroke={color}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
