export interface User {
    token?: string;
    name?: string;
    password?: string;
}

export interface UserState {
    isLoading: boolean;
    user?: User;
}

export enum UserActionTypes {
    LOADING = 'loading',
    SUCCESS = 'success',
    FAIL = 'fail',
}

export type UserAction = { type: UserActionTypes.LOADING; } | { type: UserActionTypes.SUCCESS; payload: User } | { type: UserActionTypes.FAIL };

export type UserDispatch = (action: UserAction) => void;
