import * as jwt from 'jsonwebtoken';

export const user = () => {
    const token = localStorage.getItem('token');

    const getInfoFromToken = () => {
        if (!token) return null;
        try {
            const userToken = jwt.decode(token);
            return userToken;
        } catch (err) {
            console.error(err);
            return null;
        }
    };

    const info = getInfoFromToken();

    return { token, info };
};
