import { UserState, UserAction, UserActionTypes } from '../types/userAuth';

export function userReducer(state: UserState, action: UserAction): UserState {
    switch (action.type) {
        case UserActionTypes.LOADING: {
            return { isLoading: true };
        }
        case UserActionTypes.SUCCESS: {
            return { isLoading: false, user: action.payload };
        }
        case UserActionTypes.FAIL: {
            return { isLoading: false };
        }
        default: {
            throw new Error('Invalid action type');
        }
    }
}
