import React from 'react';

interface Props {
    size?: number;
    strokeWidth?: number;
    color?: string;
}

export const DocumentActionDelete: React.FC<Props> = ({ size = 24, strokeWidth = 1.5, color }) => (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
        <path
            stroke={color}
            strokeWidth={strokeWidth}
            d="M19 11V7.828a2 2 0 0 0-.586-1.414l-2.828-2.828A2 2 0 0 0 14.172 3H7a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h6"
        />
        <path stroke={color} strokeWidth={strokeWidth} d="M19 8h-4a1 1 0 0 1-1-1V3" />
        <path
            clipRule="evenodd"
            stroke={color}
            strokeWidth={strokeWidth}
            d="M22 17.5c0 1.937-1.563 3.5-3.5 3.5S15 19.437 15 17.5s1.563-3.5 3.5-3.5 3.5 1.563 3.5 3.5z"
        />
        <path stroke={color} strokeWidth={strokeWidth} d="M16.026 19.974l4.948-4.948" />
    </svg>
);
