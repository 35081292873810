import React from 'react';
import { Row, Col, Typography } from 'antd';

// COMPONENTS
import { TileRow } from '../components/TileRow';
import { Tile } from '../components/Tile';
import { NameUpdater } from './CustomerAndBroker/NameUpdater';
import { GUTTER } from '../theme/constants';

export const CustomerAndBroker: React.FC = () => {
    return (
        <TileRow>
            <Tile span={24}>
                <Typography.Title level={2}>Customer und Broker</Typography.Title>
                <Typography.Paragraph>
                    Fügen Sie hier neue Customer oder Broker hinzu. Bestehende Customer/Broker können zudem eingesehen werden:
                </Typography.Paragraph>
                <Row gutter={GUTTER}>
                    <Col span={12}>
                        <NameUpdater name="broker" />
                    </Col>
                    <Col span={12}>
                        <NameUpdater name="customer" />
                    </Col>
                </Row>
            </Tile>
        </TileRow>
    );
};
