import React, { useState } from 'react';
import { Modal, Form, Input, Button, Select, Typography } from 'antd';

// COMPONENTS
import { ActivityIndicator } from '../../components/ActivityIndicator';
import { Alert } from '../../components/Alert';

// SERVICES
import { apiPost } from '../../services/api';

// HOOKS
import { useAuthRequest } from '../../hooks/useAuthRequest';
import { useWindowSize } from '../../hooks/useWindowSize';

// TYPES
import { Dispatcher } from '../../types/dispatcher';
import { GUTTER } from '../../theme/constants';
import { Task } from '../../types/api';
import { invalidateSWR } from '../../utils/invalidateSWR';

interface Props {
    visible: boolean;
    setVisibility: Dispatcher<boolean>;
    task_id: number;
}

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 24 },
};
const tailLayout = {
    wrapperCol: { offset: 8, span: 8 },
};

export const LinkTasks: React.FC<Props> = ({ visible, setVisibility, task_id }) => {
    const size = useWindowSize();
    let windowWidth = 0;
    if (size && size.width) {
        windowWidth = size.width;
    }
    const [error, setError] = useState<any>(undefined);
    const [formData, setFormData] = useState({
        task_id2: '',
    });

    const [form] = Form.useForm();
    const { data: dataTasks, error: errorTasks }: { data: Task[]; error: any } = useAuthRequest('/tasks');

    const onFinish = async () => {
        const params = {
            task_id1: task_id,
            task_id2: formData.task_id2,
        };

        if (formData.task_id2 && formData.task_id2 !== '--') {
            apiPost('/task_task', params)
                .then((res) => {
                    console.log(res);
                    setFormData({ ...formData, task_id2: '' });
                    form.resetFields();
                    setVisibility(false);
                    invalidateSWR(`/task_task?task_id=${task_id}`);
                })
                .catch((err) => console.error(err));
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        form.resetFields();
        setError(errorInfo);
    };

    return (
        <Modal
            visible={visible}
            footer={null}
            onCancel={() => {
                setVisibility(false);
                form.resetFields();
            }}
            width={windowWidth <= 1200 ? '85%' : 1000}
        >
            <Typography.Title level={2}>Task Verknüpfen</Typography.Title>
            {error && (
                <Alert
                    title="Es ist ein Fehler aufgetreten"
                    message={error.errorFields.map((err: any) => err.errors[0])}
                    style={{ marginBottom: GUTTER * 2 }}
                />
            )}
            <Form {...layout} name="basic" onFinish={onFinish} onFinishFailed={onFinishFailed} form={form} onFieldsChange={() => setError(false)}>
                <Form.Item label="Task zuweisen">
                    {errorTasks && <Alert type="error" message="Es konnte keine Liste von Tasks vom Server geladen werden." />}
                    {!errorTasks && !dataTasks && <ActivityIndicator />}
                    {!errorTasks && dataTasks && (
                        <Select
                            defaultValue="--"
                            onChange={(value: string) => setFormData({ ...formData, task_id2: value })}
                            showSearch
                            filterOption={(input: any, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                            <Select.Option value="--">-- Task verknüpfen --</Select.Option>
                            {dataTasks.map((task: Task) => {
                                if (task.task_id === task_id) {
                                    return null;
                                }
                                return (
                                    <Select.Option title={`${task.task_title}`} value={task.task_id} key={task.task_id}>
                                        {`VARA-${task.task_id} - ${task.task_title}`}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    )}
                </Form.Item>

                {/* TASK ERSTELLEN */}
                <Form.Item {...tailLayout} style={{ textAlign: 'center' }}>
                    <Button type="primary" htmlType="submit">
                        Task verknüpfen
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};
