import React from 'react';
import { Button } from 'antd';
import { user } from '../utils/user';

export const UserShortIdButton: React.FC = () => {
    // @ts-ignore
    const usershortid = user().info?.shortid || 'U00000';

    return (
        <Button
            ghost
            onClick={() => {
                navigator.clipboard.writeText(usershortid);
            }}
            style={{ display: 'flex' }}
        >
            {usershortid}
        </Button>
    );
};
