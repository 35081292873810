import React, { useState } from 'react';
import { Button, Alert } from 'antd';

// COMPONENTS
import { TileRow } from '../components/TileRow';
import { Tile } from '../components/Tile';
// import { Todos as TodosComponent } from './TodoScreen/Todos';
import { AddTodo } from '../components/AddTodo';
import { ActivityIndicator } from '../components/ActivityIndicator';

// SERVIDES
import { api, apiPost } from '../services/api';

// HOOKS
import { useAuthRequest } from '../hooks/useAuthRequest';

// TYPES
import { TodoType } from '../types/api';
import { Todo } from './TodoScreen/Todo';
import { invalidateSWR } from '../utils/invalidateSWR';

export const Todos: React.FC = () => {
    const [ModalVisibility, setModalVisibility] = useState<boolean>(false);
    const [filterPersonal, setFilterPersonal] = useState<boolean>(false);

    const { data, error }: { data: TodoType[]; error: any } = useAuthRequest('/todos', 0, {
        ...(filterPersonal && { filterPersonal: 'yes' }),
    });

    if (error) {
        return <Alert type="error" message="Es konnte keine Liste von Dokumenten geladen werden." />;
    }

    if (!data) {
        return <ActivityIndicator />;
    }

    const markComplete = async (todo_id: string, document_id: Nullable<string>) => {
        console.log('markComplete', todo_id, filterPersonal, document_id);
        await api.patch('/todo', {
            todo_id,
            type: 'close',
        });
        if (document_id) {
            apiPost('/documentstatus', { document_id, documentstatus_step: 100 })
                .then((res) => {
                    console.log(res);
                    invalidateSWR('/documentstatus', { document_id });
                })
                .catch((err) => console.error(err));
        }
        invalidateSWR('/todos', {
            ...(filterPersonal && { filterPersonal: 'yes' }),
        });
    };

    const markOpen = async (todo_id: string, document_id: Nullable<string>) => {
        console.log('markOpen', todo_id, filterPersonal, document_id);
        await api.patch('/todo', {
            todo_id,
            type: 'reopen',
        });
        if (document_id) {
            apiPost('/documentstatus', { document_id, documentstatus_step: 50 })
                .then((res) => {
                    console.log(res);
                    invalidateSWR('/documentstatus', { document_id });
                })
                .catch((err) => console.error(err));
        }
        invalidateSWR('/todos', {
            ...(filterPersonal && { filterPersonal: 'yes' }),
        });
    };

    return (
        <>
            <TileRow>
                <Tile>
                    <div style={{ display: 'flex' }}>
                        <div style={{ flex: 1 }}>
                            <AddTodo visible={ModalVisibility} setVisible={setModalVisibility} />
                            <Button onClick={() => setModalVisibility(true)} type="primary">
                                Neues Todo hinzufügen
                            </Button>
                        </div>
                        <div style={{ flex: 1, textAlign: 'right' }}>
                            <Button onClick={() => setFilterPersonal(!filterPersonal)}>
                                {filterPersonal ? 'Alle Todos anzeigen' : 'Nur persönliche Todos anzeigen'}
                            </Button>
                        </div>
                    </div>
                </Tile>
                <Tile>
                    {data.map((todo: TodoType) => (
                        <Todo todo={todo} markComplete={markComplete} markOpen={markOpen} key={todo.todo_id} />
                    ))}
                </Tile>
            </TileRow>
        </>
    );
};
