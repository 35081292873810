import React from 'react';

// CONSTANTS
import { TaskSize as TaskSizeConstant } from '../constants/tasks';

// THEME
import colors from '../theme/colors';
import { IconType, MyIcons } from '../theme/MyIcons';

// TYPES
import { TaskSeverity as TaskSeverityType } from '../types/api';

interface Props {
    task_severity: TaskSeverityType;
}

export const TaskSeverity: React.FC<Props> = ({ task_severity }) => {
    let color = colors.primaryBlue;
    let iconName: IconType = 'TaskSeverityHigh';
    switch (task_severity) {
        case 'normal':
            color = colors.primaryBlue;
            iconName = 'TaskSeverityHigh';
            break;
        case 'high':
            color = colors.materialUIRed900;
            iconName = 'TaskSeverityHighest';
            break;
        case 'low':
            color = colors.secondaryBlue;
            iconName = 'TaskSeverityLowest';
            break;
    }

    return (
        <div style={{ width: 24, height: 24, position: 'relative', display: 'flex' }}>
            <MyIcons name={iconName} color={color} />
        </div>
    );
};
