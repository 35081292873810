import { TaskStatusArrayDictonary, TaskSeverityArrayDictonary, TaskSizeArrayDictonary } from '../types/task';

export const TaskStatus: TaskStatusArrayDictonary<string> = {
    todo: 'Todo',
    inprogress: 'In Bearbeitung',
    inquiry: 'Rückfrage',
    done: 'Abgeschlossen',
};

export const TaskSeverity: TaskSeverityArrayDictonary<string> = {
    // lowest: 'Niedrigste',
    low: 'Niedrig',
    normal: 'Normal',
    high: 'Hoch',
    // highest: 'Höchste',
};

export const TaskSize: TaskSizeArrayDictonary<string> = {
    // xs: 'XS',
    s: 'S - Klein',
    m: 'M - Mittel',
    l: 'L - Groß',
    // xl: 'XL',
};
