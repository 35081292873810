import React from 'react';
import { Row } from 'antd';
import { GUTTER, TILE_WRAPPER } from '../theme/constants';

interface Props {
    gutter?: number;
    padding?: number;
    marginTop?: number;
    maxWidth?: number;
}

export const TileRow: React.FC<Props> = ({ gutter = GUTTER, padding = GUTTER, marginTop = GUTTER, maxWidth = 1280, children }) => (
    <div
        style={{
            paddingLeft: padding / 2,
            paddingRight: padding / 2,
            marginTop: marginTop,
        }}
    >
        <Row
            gutter={gutter}
            style={
                {
                    maxWidth: `${1280}px`,
                    margin: 'auto',
                    ...TILE_WRAPPER,
                } as React.CSSProperties
            }
        >
            {children}
        </Row>
    </div>
);
