import React from 'react';
import { Col } from 'antd';
import colors from '../theme/colors';
import { GUTTER, TILE } from '../theme/constants';

interface Responsive {
    span?: number;
    pull?: number;
    push?: number;
    offset?: number;
    order?: number;
}

interface Props {
    span?: number;
    xs?: number | Responsive | undefined;
    sm?: number | Responsive | undefined;
    md?: number | Responsive | undefined;
    lg?: number | Responsive | undefined;
    xl?: number | Responsive | undefined;
    paddingHorizontal?: number | undefined;
}

export const Tile: React.FC<Props> = ({
    span = 24,
    xs = undefined,
    sm = undefined,
    md = undefined,
    lg = undefined,
    xl = undefined,
    children,
    paddingHorizontal = undefined,
}) => (
    <Col
        span={span}
        xs={xs}
        sm={sm}
        md={md}
        lg={lg}
        xl={xl}
        style={
            {
                ...TILE,
            } as React.CSSProperties
        }
    >
        <div
            style={{
                background: colors.ambientWhite,
                paddingLeft: GUTTER * 2,
                paddingRight: GUTTER * 2,
                paddingTop: paddingHorizontal ? paddingHorizontal : GUTTER * 2,
                paddingBottom: paddingHorizontal ? paddingHorizontal : GUTTER * 2,
                flex: 1,
            }}
        >
            {children}
        </div>
    </Col>
);
