import * as React from 'react';
import { Typography, Popover } from 'antd';
import { format, formatDistance } from 'date-fns';
import { de } from 'date-fns/locale';

interface Props {
    timestamp: string;
}

export const UploadDate: React.FC<Props> = ({ timestamp }) => {
    return (
        <Popover trigger="hover" content={`${format(new Date(timestamp), 'dd.MM.yyyy - HH:mm:ss')} Uhr`}>
            <Typography.Paragraph
                style={{
                    fontSize: '11px',
                    lineHeight: '12px',
                    paddingTop: '10px',
                    textAlign: 'center',
                }}
            >
                {formatDistance(new Date(timestamp), new Date(Date.now()), {
                    addSuffix: true,
                    locale: de,
                })}
            </Typography.Paragraph>
        </Popover>
    );
};
