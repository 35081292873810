import React, { useState, useEffect } from 'react';

interface Props {
    waitBeforeShow: number;
    path?: string;
    errorMessage?: string;
    children: React.ReactNode;
}

// @ts-ignore
const Delayed: React.FC<Props> = ({ waitBeforeShow, children }) => {
    const [isShown, setIsShown] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setIsShown(true);
        }, waitBeforeShow);
    }, [waitBeforeShow]);

    // return children;
    return isShown ? children : null;
};

export default Delayed;
