import React, { useState } from 'react';
import { Slider, Popover, Row, Col, Typography, Button } from 'antd';
import { useKey } from 'react-use';

// COMPONENTS
import { ActivityIndicator } from '../../components/ActivityIndicator';
import { Alert } from '../../components/Alert';

// HOOKS
import { useAuthRequest } from '../../hooks/useAuthRequest';

// TYPES
import colors from '../../theme/colors';

// SERVICES
import { socket } from '../../services/socket';
import { api } from '../../services/api';
import { user } from '../../utils/user';

// UTILS
import { invalidateSWR } from '../../utils/invalidateSWR';
import { DocumentpageType } from '../../types/api';
import { GUTTER } from '../../theme/constants';
import { Link } from 'react-router-dom';

interface Props {
    document_id: string;
    document_page_number: number;
}

interface WordProps {
    text: string;
    confidence: number;
    width: number;
    height: number;
    left: number;
    top: number;
    relWidth: number;
    relHeight: number;
    opacity: number;
    confidenceAlertThreshold: number;
    wordID: string;
    wasSelected: boolean;
    addToSelected: any;
    removeFromSelected: any;
}

type TextType = 'PRINTED' | 'HANDWRITTEN';

interface JsonResponse {
    BlockType: string;
    Confidence: number;
    Text: string;
    Id: string;
    TextType: TextType;
    Left: number;
    Top: number;
    Width: number;
    Height: number;
}

const langKey = 'de';

const PopoverTitle: React.FC<{ confidence: number }> = ({ confidence }) => <div>{`${Math.round(confidence)}%`}</div>;

const Word: React.FC<WordProps> = ({
    text,
    width,
    height,
    left,
    top,
    relWidth,
    relHeight,
    opacity,
    confidence,
    confidenceAlertThreshold,
    wordID,
    wasSelected,
    addToSelected,
    removeFromSelected,
}) => {
    // @ts-ignore
    const user_shortid = user().info?.shortid || 'unbekannt';

    return (
        <Popover content={text} title={<PopoverTitle confidence={confidence} />} trigger="hover">
            <div
                style={{
                    position: 'absolute',
                    borderWidth: '2px',
                    borderStyle: 'solid',
                    borderColor: wasSelected ? colors.primaryOrange : colors.primaryBlue,
                    backgroundColor: confidence >= confidenceAlertThreshold ? 'rgba(0,0,0,.15)' : 'rgba(220,40,20,.45)',
                    opacity: opacity / 100,
                    width: Math.round(width * relWidth),
                    height: Math.round(height * relHeight),
                    left: Math.round(left * relWidth),
                    top: Math.round(top * relHeight),
                    cursor: 'pointer',
                }}
                onClick={(el) => {
                    console.log('SEND HOCR: ', { shortid: user_shortid, language: langKey, message: text, documentpage_value_id: wordID });
                    socket.emit('SOCKET_SEND_HOCR_VALUE', { shortid: user_shortid, language: langKey, message: text });
                    wasSelected ? removeFromSelected(wordID) : addToSelected(wordID);
                }}
            />
        </Popover>
    );
};

export const TextractOverlay: React.FC<Props> = ({ document_id, document_page_number }) => {
    const textractJson = `${process.env.REACT_APP_S3_BUCKET_URL}/results/${document_id}__${document_page_number + 1}.json`;
    const imageUrl = `${process.env.REACT_APP_IMAGESERVER}/?url=${process.env.REACT_APP_S3_BUCKET_URL}/pdf/${document_id}.pdf&w=1600&page=${document_page_number}`;
    const params = {
        document_id: document_id || '',
        documentpage_page_number: document_page_number + 1,
    };
    const { data, error }: { data: DocumentpageType[]; error: any } = useAuthRequest('/documentpage', 0, params);

    const [imageWidth, setImageWidth] = useState(0);
    const [imageHeight, setImageHeight] = useState(0);
    const [selectedValues, setSelectedValues] = useState<string[]>([]);

    // Get Imagesize dynamically
    let img = new Image();
    img.src = imageUrl;
    img.onload = () => {
        setImageHeight(img.height);
        setImageWidth(img.width);
    };

    const [jsonResponse, setJsonRespone] = useState<JsonResponse[] | []>([]);
    const [opacity, setOpacity] = useState(20);
    const [scalefactor, setScalefactor] = useState(0.8);
    const [confidenceAlertThreshold, setConfidenceAlertThreshold] = useState<number>(75.0);
    const [menuBackgroundColor, setMenuBackgroundColor] = useState('rgba(0,0,0,.2)');

    useKey('Z', () => setScalefactor((v) => (v < 0.5 ? 0.3 : v - 0.2)));
    useKey('z', () => setScalefactor((v) => (v > 2.9 ? 3 : v + 0.2)));
    useKey('f', () => setConfidenceAlertThreshold((v) => (v < 2 ? 100 : v - 5)));
    useKey('F', () => setConfidenceAlertThreshold((v) => (v > 100 ? 1 : v + 5)));
    useKey('o', () => setOpacity((v) => (v < 2 ? 100 : v - 20)));
    useKey('O', () => setOpacity((v) => (v > 100 ? 1 : v + 20)));

    const scaledImageWidth = imageWidth * scalefactor;
    const scaledImageHeight = imageHeight * scalefactor;

    React.useEffect(() => {
        fetch(textractJson, {
            method: 'GET',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
            },
        })
            .then((resp) => resp.json())
            .then((data) => {
                setJsonRespone(data);
            });
    }, [textractJson]);

    React.useEffect(() => {
        if (data) {
            if (data[0]?.documentpage_selectedvalues) {
                setSelectedValues(JSON.parse(data[0].documentpage_selectedvalues));
            }
        }
    }, [data]);

    if (!jsonResponse) {
        return <ActivityIndicator />;
    }

    if (error) {
        console.log(data);
        return <Alert type="error" message="Die angeforderte Dokumentenseite konnte leider nicht geladen werden." />;
    }

    if (!data && !error) {
        return <ActivityIndicator />;
    }

    const patchSelectedValues = async (values: string[]) => {
        await api.patch('/documentpage/selectedvalues', {
            document_id,
            documentpage_page_number: document_page_number + 1,
            documentpage_selectedvalues: values,
        });
        invalidateSWR('/documentpage');
    };

    return (
        <div
            style={{
                position: 'relative',
                width: scaledImageWidth,
                height: scaledImageHeight,
                backgroundImage: `url(${imageUrl})`,
                backgroundSize: 'contain',
                margin: 'auto',
            }}
        >
            <div
                style={{
                    position: 'absolute',
                    backgroundColor: 'white',
                    width: scaledImageWidth,
                    height: scaledImageHeight,
                    opacity: 0,
                }}
            />
            {/* @ts-ignore */}
            {jsonResponse.map((word: JsonResponse) => (
                <Word
                    key={`${word.Left}${word.Top}`}
                    text={word.Text}
                    confidence={word.Confidence}
                    width={word.Width}
                    height={word.Height}
                    left={word.Left}
                    top={word.Top}
                    relWidth={scaledImageWidth}
                    relHeight={scaledImageHeight}
                    opacity={opacity}
                    confidenceAlertThreshold={confidenceAlertThreshold}
                    wordID={word.Id}
                    wasSelected={selectedValues.includes(word.Id)}
                    addToSelected={(id: string) => {
                        patchSelectedValues([...selectedValues, id]);
                        setSelectedValues([...selectedValues, id]);
                    }}
                    removeFromSelected={(id: string) => {
                        patchSelectedValues([...selectedValues].filter((item) => item !== id));
                        setSelectedValues([...selectedValues].filter((item) => item !== id));
                    }}
                />
            ))}
            <div
                style={{
                    position: 'fixed',
                    top: 10,
                    left: 10,
                    padding: 20,
                    width: 400,
                    backgroundColor: menuBackgroundColor,
                    cursor: 'pointer',
                    borderRadius: 8,
                    transition: 'background-color 250ms ease-out',
                }}
                onMouseEnter={() => setMenuBackgroundColor('rgba(0,0,0,.8)')}
                onMouseLeave={() => setMenuBackgroundColor('rgba(0,0,0,.2)')}
            >
                <Row>
                    <Col span={6} style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography.Text
                            style={{
                                color: 'black',
                                borderColor: colors.primaryBlue,
                                borderWidth: 1,
                                borderStyle: 'solid',
                                backgroundColor: colors.ambientWhite,
                                paddingLeft: 5,
                                paddingRight: 5,
                                paddingTop: 2,
                                paddingBottom: 2,
                                borderRadius: 3,
                                flex: 1,
                                textAlign: 'center',
                            }}
                        >
                            Overlay (o)
                        </Typography.Text>
                    </Col>
                    <Col span={18}>
                        <Slider min={1} max={100} onChange={(val: any) => setOpacity(val)} value={typeof opacity === 'number' ? opacity : 0} />
                    </Col>
                </Row>
                <Row>
                    <Col span={6} style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography.Text
                            style={{
                                color: 'black',
                                borderColor: colors.primaryBlue,
                                borderWidth: 1,
                                borderStyle: 'solid',
                                backgroundColor: colors.ambientWhite,
                                paddingTop: 2,
                                paddingBottom: 2,
                                borderRadius: 3,
                                flex: 1,
                                textAlign: 'center',
                            }}
                        >
                            Fehler (f)
                        </Typography.Text>
                    </Col>
                    <Col span={18}>
                        <Slider
                            min={1}
                            max={100}
                            onChange={(val: any) => setConfidenceAlertThreshold(val)}
                            value={typeof confidenceAlertThreshold === 'number' ? confidenceAlertThreshold : 0}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={6} style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography.Text
                            style={{
                                color: 'black',
                                borderColor: colors.primaryBlue,
                                borderWidth: 1,
                                borderStyle: 'solid',
                                backgroundColor: colors.ambientWhite,
                                paddingTop: 2,
                                paddingBottom: 2,
                                borderRadius: 3,
                                flex: 1,
                                textAlign: 'center',
                            }}
                        >
                            Zoom (z)
                        </Typography.Text>
                    </Col>
                    <Col span={18}>
                        <Slider
                            min={0.3}
                            max={3.0}
                            onChange={(val: any) => setScalefactor(val)}
                            value={typeof scalefactor === 'number' ? scalefactor : 1.0}
                            step={0.1}
                        />
                    </Col>
                </Row>
                {data[0].documentpage_tablecount > 0 && (
                    <Row>
                        <Col span={24} style={{ marginTop: GUTTER }}>
                            <Link
                                to={{
                                    pathname: '/textract-table',
                                    search: `?table_key=resultstables/${data[0].documentpage__document_id}__${
                                        data[0].documentpage_page_number
                                    }.json&table_number=${1}`,
                                }}
                            >
                                <Button>Erkannte Tabelle öffnen</Button>
                            </Link>
                        </Col>
                    </Row>
                )}
            </div>
        </div>
    );
};
