import React, { useState, useEffect } from 'react';
import { Divider, Typography, Row, Button } from 'antd';
import { Link } from 'react-router-dom';

// COMPONENTS
import { ActivityIndicator } from '../components/ActivityIndicator';
import { Alert } from '../components/Alert';
import { Tile } from '../components/Tile';
import { TileRow } from '../components/TileRow';
import { AddTask } from '../components/AddTask';
import { TaskStatus } from '../components/TaskStatus';
import { TaskSize } from '../components/TaskSize';
import { TaskSeverity } from '../components/TaskSeverity';
import { Severity } from './Tasks/Severity';

// THEME
import { GUTTER } from '../theme/constants';

// HOOKS
import { useAuthRequest } from '../hooks/useAuthRequest';

// SERVICES
import { socket } from '../services/socket';

// UTILS
import { invalidateSWR } from '../utils/invalidateSWR';

// TYPES
import { ApiGetTasks } from '../types/api';
import { Spacer } from '../components/Spacer';

export const Tasks: React.FC = () => {
    const [ModalVisibility, setModalVisibility] = useState<boolean>(false);
    const { data, error }: { data: ApiGetTasks[]; error: any } = useAuthRequest('/tasks', 0);

    useEffect(() => {
        socket.on('SOCKET_MESSAGE_UPDATE_TASKS', (data: any) => {
            // @ts-ignore
            invalidateSWR('/tasks');
        });
    }, []);

    if (error) {
        return <Alert type="error" message="Es konnten keine Tasks geladen werden." />;
    }

    if (!data) {
        return <ActivityIndicator />;
    }

    return (
        <>
            <TileRow>
                <Tile span={24}>
                    <Typography.Title level={2}>Tasks</Typography.Title>
                    <Button type="primary" onClick={() => setModalVisibility(true)}>
                        Neuer Task
                    </Button>
                    <AddTask visible={ModalVisibility} setVisibility={setModalVisibility} />
                </Tile>
            </TileRow>

            <TileRow>
                <Tile span={24}>
                    {data.length &&
                        data.map((task: ApiGetTasks) => (
                            <Row key={task.task_id} gutter={GUTTER}>
                                <div style={{ display: 'flex', flex: 1 }}>
                                    <div style={{ flex: 0 }}>
                                        <Severity severity={task.task_severity} />
                                    </div>
                                    <div style={{ flex: 1 }}>
                                        <Link to={`/task/${task.task_id}`}>
                                            <Typography.Text>
                                                <Typography.Text>VARA-{task.task_id}</Typography.Text>{' '}
                                                <Typography.Text strong style={{ paddingLeft: 10 }}>
                                                    {task.task_title}
                                                </Typography.Text>{' '}
                                                -{' '}
                                                <Typography.Text disabled ellipsis>
                                                    {task.task_content}
                                                </Typography.Text>
                                            </Typography.Text>
                                        </Link>
                                    </div>
                                    <div style={{ flex: 0, display: 'flex', flexDirection: 'row' }}>
                                        {task.task_assignee__user_id && (
                                            <div>
                                                <Typography.Text>{task.assignee_name || task.task_assignee__user_id}</Typography.Text>
                                            </div>
                                        )}
                                        {/* <TaskStatus task_status={task.task_status} /> */}
                                        <Spacer size={1} horizontal />
                                        <TaskSeverity task_severity={task.task_severity} />
                                        <Spacer size={1} horizontal />
                                        <TaskSize task_size={task.task_size} />
                                        <Spacer size={1} horizontal />
                                        <TaskStatus task_status={task.task_status} />

                                        {/* {task.task_linked__document_id && task.task_assignee__user_id && <Typography.Text> | </Typography.Text>}
                                        {task.task_linked__document_id && (
                                            <Link to={`/document/${task.task_linked__document_id}`}>
                                                <div style={{ width: 24 }}>D</div>
                                            </Link>
                                        )} */}
                                    </div>
                                </div>

                                <Divider style={{ marginTop: GUTTER / 2, marginBottom: GUTTER }} />
                            </Row>
                        ))}
                </Tile>
            </TileRow>
        </>
    );
};
